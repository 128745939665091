import React  from "react";
import { useParams } from 'react-router-dom';
import UserForm from "./components/UserForm";

const EditUser = () => {
	const { userId } = useParams();

	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
      		<UserForm recordId={userId} mode="edit"/>
	      </div>
	    </div>
    );
};

export default EditUser;