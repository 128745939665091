import cookie from 'js-cookie';

export const TOKEN = 'access_token';

export const REFRESH_TOKEN = 'refresh_token';
export const APP_URL = process.env.APP_URL || 'http://localhost:3000';

export default function AuthHeader(headers=null) {
	
	return {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + cookie.get(TOKEN),
		...headers || {} 
	}
  }