import React from "react";
import AccessRightTable from "./components/AccessRightTable";

import { connect } from "react-redux";

const AccessRights = () => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">

	      	<AccessRightTable/>
	      	
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRights);