import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import { TeamOutlined } from '@ant-design/icons';
import Users from "./index";
import ViewUser from "./view";
import EditUser from "./edit";

const userRoutes = [	
	generateCRUDRoutes({
		mainTitle: "Users",
		basePath: "users",
		mainComponent: <Users />,
	    layout: "/console",	   
	    icon: <TeamOutlined className="h-6 w-6" />,
	    subTitle:"User",
	    viewComponent: <ViewUser />,
	    createComponent: <EditUser />,
	    editComponent: <EditUser />,
	})
]

export default userRoutes;