import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    moduleList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    moduleDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    getModuleList: (state, action) => {		
        state.moduleList.requesting = true;
        state.moduleList.error = null;
        state.moduleList.items = [];
        state.moduleList.success = false;
        state.moduleList.filters = action.payload;
    },
    getModuleListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.moduleList.requesting = false;        
        state.moduleList.items = data;
        state.moduleList.success = true; 
        state.moduleList.pagination.current_page = current_page;
        state.moduleList.pagination.per_page = per_page;
        state.moduleList.pagination.total = total;
    },
    getModuleListFail: (state, action) => {        
        state.moduleList.requesting = false;
        state.moduleList.error = action.payload;
    },
    getModuleDetails: (state) => {
        state.moduleDetails.requesting = true;
        state.moduleDetails.error = null;
        state.moduleDetails.data = null;
        state.moduleDetails.success = false; 
    },
    getModuleDetailsSuccess: (state, action) => {
        state.moduleDetails.requesting = false;
        state.moduleDetails.data = action.payload;
        state.moduleDetails.success = true; 
    },
    getModuleDetailsFail: (state, action) => {        
        state.moduleDetails.requesting = false;
        state.moduleDetails.error = action.payload;
    },
    doModuleCreate: (state) => {
        state.moduleDetails.requesting = true;
        state.moduleDetails.error = null;
        state.moduleDetails.data = null;
        state.moduleDetails.success = false; 
    },
    doModuleUpdate: (state) => {
        state.moduleDetails.requesting = true;
        state.moduleDetails.error = null;
        state.moduleDetails.data = null;
        state.moduleDetails.success = false; 
    },
    doModuleDelete: (state) => {
        state.moduleDetails.requesting = true;
        state.moduleDetails.error = null;
        state.moduleDetails.data = null;
        state.moduleDetails.success = false; 
    },
  },
})

export const { 
    getModuleList, getModuleListSuccess, getModuleListFail,
    getModuleDetails, getModuleDetailsSuccess, getModuleDetailsFail,
    doModuleCreate, doModuleUpdate, doModuleDelete
} = moduleSlice.actions

export default moduleSlice.reducer