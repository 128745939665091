import { takeLatest } from 'redux-saga/effects';
import {
  getFarmProfileListSuccess, getFarmProfileListFail,
  getFarmProfileDetailsSuccess, getFarmProfileDetailsFail
} from './farmProfileState';
import createCrudService from '../../services/crudservice';
import { 
	doCrudCall, SAVING_MSG, UPDATING_MSG, DELETING_MSG, LOADING_MSG,
	createRecordSuccess, updateRecordSuccess, deleteRecordSuccess
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/services/farm-profiles');
const redirectData = {route:'/console/farmer-information', mode: 'list', recordId:0 };
const myCrudService = createCrudService('api/v1/services/my/farm-profiles');
const myRedirectData = {route:'/panel/my-farmer-information#farm_profile', mode: 'list', recordId:0 };
const myFarmProfileService = createCrudService('api/v1/services/my');
const modelName = 'Farm Profile';
const modelId = 'id';

function* getFarmProfileList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getFarmProfileListSuccess, 
		getFarmProfileListFail, 
		null, null, LOADING_MSG);
}

function* getFarmProfileDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doFarmProfileCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail,
		createRecordSuccess(modelName),
		redirectData, 
		SAVING_MSG);
}

function* doFarmProfileUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail,
		updateRecordSuccess(modelName),
		redirectData,
		UPDATING_MSG);	
}

function* doFarmProfileDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail,
		deleteRecordSuccess(modelName),
		{...redirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* getMyFarmProfileDetails(data) {
	yield doCrudCall(
		() => myFarmProfileService.doGetRequest('farm-profile', null), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doMyFarmProfileCreate(data){
	yield doCrudCall(
		() => myCrudService.doCreate(data.payload), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail,
		updateRecordSuccess(modelName),
		myRedirectData,
		SAVING_MSG);	
}

function* doMyFarmProfileUpdate(data){
	yield doCrudCall(
		() => myCrudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmProfileDetailsSuccess, 
		getFarmProfileDetailsFail,
		updateRecordSuccess(modelName),
		myRedirectData,
		UPDATING_MSG);	
}

function* farmProfileSaga() {
  yield takeLatest('farmProfile/getFarmProfileList', getFarmProfileList);
  yield takeLatest('farmProfile/getFarmProfileDetails', getFarmProfileDetails);
  yield takeLatest('farmProfile/doFarmProfileCreate', doFarmProfileCreate);
  yield takeLatest('farmProfile/doFarmProfileUpdate', doFarmProfileUpdate);
  yield takeLatest('farmProfile/doFarmProfileDelete', doFarmProfileDelete);
  yield takeLatest('farmProfile/getMyFarmProfileDetails', getMyFarmProfileDetails);
  yield takeLatest('farmProfile/doMyFarmProfileCreate', doMyFarmProfileCreate);
  yield takeLatest('farmProfile/doMyFarmProfileUpdate', doMyFarmProfileUpdate); 
}

export default farmProfileSaga;
