import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeModal: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.activeModal = action.payload;
    },
    hideModal: (state) => {
      state.activeModal = null;
    },    
  },
})

export const { 
  showModal, hideModal
} = modalSlice.actions

export default modalSlice.reducer