import { createSlice } from '@reduxjs/toolkit'
import authservice from '../../services/authservice';
import defaultAvatar from "assets/img/avatars/user.png";

const initialState = {
    user: authservice.getUserInfo(),
    accessToken: authservice.getAccessToken(),
    isLoggedIn: authservice.withAccessToken(),
    avatar: defaultAvatar,
    loading: false,
    error: null,
    navigatePath: '',
    modules: [],
    scannedQR: null,
    qrUser: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
        state.loading = true;
        state.error = null;
    },
    loginSuccess: (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.accessToken = action.payload;
        state.error = null;
    },
    loginFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    logoutRequest: (state) => {
        state.loading = true;
        state.error = null;
    },
    logoutSuccess: (state) => {
        state.loading = false;
        state.isLoggedIn = false;
        state.user = null;
        state.accessToken = null;
        state.error = null;
    },
    logoutFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    getAuthUser: (state) => {
        state.loading = true;
        state.error = null;
    },
    getAuthUserSuccess: (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
    },
    getAuthUserFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    getAuthUserModule: (state) => {
        state.loading = true;
        state.error = null;
    },
    getAuthUserModuleSuccess: (state, action) => {
        state.loading = false;
        state.modules = action.payload;
        state.error = null;
    },
    getAuthUserModuleFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    navigateTo: (state, action) => {
        state.navigatePath = action.payload;
    },
    doCheckQRData: (state, action) => {
        state.loading = true;
        state.error = null;
        console.log(action.payload);
        state.scannedQR = action.payload;
    },
    doCheckQRDataSuccess: (state, action) => {
        state.loading = false;
        state.qrUser = action.payload;
        state.error = null;
    },
    doCheckQRDataFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    doConfirmRegistration: (state) => {
        state.loading = true;
        state.error = null;
    },
    doConfirmRegistrationSuccess: (state, action) => {
        state.loading = false;
        state.qrUser = action.payload;
        state.error = null;
    },
    doConfirmRegistrationFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
    doCancelRegistration: (state) => {
        state.loading = true;
        state.error = null;
        state.qrUser = null;
    },
    getUserAvatar: (state) => {
        state.loading = true;
        state.error = null;
    },
    getUserAvatarSuccess: (state, action) => {
        state.loading = false;
        state.avatar = action.payload;
        state.error = null;
    },
    getUserAvatarFail: (state, action) => {
        state.loading = false;
        state.error = action.payload;
    },
  },
})

export const { 
    loginRequest, loginSuccess, loginFail,
    logoutRequest, logoutSuccess, logoutFail,
    getAuthUser, getAuthUserSuccess, getAuthUserFail,
    getAuthUserModule, getAuthUserModuleSuccess, getAuthUserModuleFail,
    navigateTo,
    doCheckQRData, doCheckQRDataSuccess, doCheckQRDataFail,
    doConfirmRegistration, doConfirmRegistrationSuccess, doConfirmRegistrationFail,
    doCancelRegistration,
    getUserAvatar, getUserAvataruccess, getUserAvatarFail,
} = authSlice.actions

export default authSlice.reducer