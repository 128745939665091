import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getMyFarmParcelList, doMyFarmParcelDelete } from "../../../../redux/farmparcel/farmParcelState";
import { handleTableChange } from "../../../../utils/index";
import CrudButtons from "components/button/crudbuttons";

const MyFarmParcelsTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
  	const columns = [
		{
	        title: `Farm Name / Location`,
	        dataIndex: 'name',
	        key: 'name',
	        width: '27%',
	        sorter: true,
	        render:(name, record) => <span>{`${name} / ${record.location_barangay}, ${record.location_municipality}`}</span>
	    },
	    {
	        title: `Area`,
	        dataIndex: 'total_farm_area',
	        key: 'total_farm_area',
	        width: '10%',
	        render:(total_farm_area) => <span>{total_farm_area}</span>
	    },
		{
	        title: `Ownership Document No.`,
	        dataIndex: 'ownership_document_no',
	        key: 'ownership_document_no',
	        width: '15%',
	        render:(ownership_document_no) => <span>{ownership_document_no}</span>
	    },
		{
	        title: `Within Acestral Domain`,
	        dataIndex: 'within_ancestral_domain',
	        key: 'within_ancestral_domain',
	        width: '10%',
	        render:(within_ancestral_domain) => <span>{within_ancestral_domain === 1 ? 'Y' : 'N'}</span>
	    },
		{
	        title: `Agrarian Reform Beneficiary`,
	        dataIndex: 'agrarian_reform_beneficiary',
	        key: 'agrarian_reform_beneficiary',
	        width: '10%',
	        render:(agrarian_reform_beneficiary) => <span>{agrarian_reform_beneficiary === 1 ? 'Y' : 'N'}</span>
	    },	    
		{
	        title: `Ownership Type`,
	        dataIndex: 'ownership_type_id',
	        key: 'ownership_type_id',
	        width: '15%',
	        render:(ownership_type_id, record) => <span>{record?.ownership_type?.name} {record?.ownership_type_others && ` - ${record?.ownership_type_others}`}</span>
	    },
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/panel/my-farm-parcels/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '13%',
	        render:(id) => <CrudOption baseRoute="/panel/my-farm-parcels" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page, section: 'Farm Parcels'});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>

			<CrudButtons baseRoute="/panel/my-farmer-information" 
				mode="view" 
				recordId="0" 
				backUrl="/panel/services"
				cancelUrl="/panel/services"
				cancelLabel="Back"
				withEdit={false}/>

	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.farmParcel.recordList?.items,
	pagination: state.farmParcel.recordList?.pagination,
	filters: state.farmParcel.recordList?.filters,
  	requesting: state.farmParcel.recordList?.requesting,
  	error: state.farmParcel.recordList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getMyFarmParcelList(filters)),
  doDelete: (id) => dispatch(doMyFarmParcelDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmParcelsTable);