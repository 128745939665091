import React  from "react";
import { useParams } from 'react-router-dom';
import ParameterForm from './components/ParameterForm';

const ViewModule = () => {
	const { moduleId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<ParameterForm recordId={moduleId} mode="view"/>
	      </div>
	    </div>
    );
};

export default ViewModule;