import { takeLatest } from 'redux-saga/effects';
import {
  getFarmAnimalListSuccess, getFarmAnimalListFail,
  getFarmAnimalDetailsSuccess, getFarmAnimalDetailsFail
} from './farmAnimalState';
import createCrudService from '../../services/crudservice';
import { 
	doCrudCall, SAVING_MSG, UPDATING_MSG, DELETING_MSG, LOADING_MSG,
	createRecordSuccess, updateRecordSuccess, deleteRecordSuccess
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/services/farm-animals');
const redirectData = {route:'/console/farm-animals', mode: 'list', recordId:0 };
const myCrudService = createCrudService('api/v1/services/my/farm-animals');
const myRedirectData = {route:'/panel/my-registered-animals', mode: 'list', recordId:0 };
const modelName = 'Farm Animals';
const modelId = 'id';

function* getFarmAnimalList(data) {	
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getFarmAnimalListSuccess, 
		getFarmAnimalListFail,
		null, null, LOADING_MSG);
}

function* getFarmAnimalDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		null, null, LOADING_MSG);		
}

function* doFarmAnimalCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		createRecordSuccess(modelName),
		redirectData,
		SAVING_MSG);
}

function* doFarmAnimalUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		updateRecordSuccess(modelName),
		redirectData,
		UPDATING_MSG);	
}

function* doFarmAnimalDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		deleteRecordSuccess(modelName),
		{...redirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* getMyFarmAnimalList(data){
	yield doCrudCall(
			() => myCrudService.getList(data.payload), 
			getFarmAnimalListSuccess, 
			getFarmAnimalListFail);
}

function* getMyFarmAnimalDetails(data) {
	yield doCrudCall(
		() => myCrudService.getDetails(data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail);		
}

function* doMyFarmAnimalCreate(data){
	yield doCrudCall(
		() => myCrudService.doCreate(data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		createRecordSuccess(modelName),
		myRedirectData,
		SAVING_MSG);
}

function* doMyFarmAnimalUpdate(data){
	yield doCrudCall(
		() => myCrudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		updateRecordSuccess(modelName),
		myRedirectData,
		UPDATING_MSG);	
}

function* doMyFarmAnimalDelete(data){
	yield doCrudCall(
		() => myCrudService.doDelete(data.payload.recordId), 
		getFarmAnimalDetailsSuccess, 
		getFarmAnimalDetailsFail,
		deleteRecordSuccess(modelName),
		{...myRedirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* farmAnimalSaga() {
  yield takeLatest('farmAnimal/getFarmAnimalList', getFarmAnimalList);
  yield takeLatest('farmAnimal/getFarmAnimalDetails', getFarmAnimalDetails);
  yield takeLatest('farmAnimal/doFarmAnimalCreate', doFarmAnimalCreate);
  yield takeLatest('farmAnimal/doFarmAnimalUpdate', doFarmAnimalUpdate);
  yield takeLatest('farmAnimal/doFarmAnimalDelete', doFarmAnimalDelete);  
  yield takeLatest('farmAnimal/getMyFarmAnimalList', getMyFarmAnimalList);  
  yield takeLatest('farmAnimal/getMyFarmAnimalDetails', getMyFarmAnimalDetails);
  yield takeLatest('farmAnimal/doMyFarmAnimalCreate', doMyFarmAnimalCreate);
  yield takeLatest('farmAnimal/doMyFarmAnimalUpdate', doMyFarmAnimalUpdate);
  yield takeLatest('farmAnimal/doMyFarmAnimalDelete', doMyFarmAnimalDelete);  
}

export default farmAnimalSaga;
