import { takeLatest } from 'redux-saga/effects';
import {
  getUserDetailsListSuccess, getUserDetailsListFail,
  getUserDetailsDetailsSuccess, getUserDetailsDetailsFail
} from './userDetailsState';
import createCrudService from '../../services/crudservice';
import { 
	doCrudCall, SAVING_MSG, UPDATING_MSG, DELETING_MSG, LOADING_MSG,
	createRecordSuccess, updateRecordSuccess, deleteRecordSuccess
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/services/user-details');
const redirectData = {route:'/console/farmerInfos', mode: 'list', recordId:0 };
const modelName = 'Personal Information';
const modelId = 'id';

const userDetailsCrudService = createCrudService('api/v1/services/my/user-details');
const myCrudService = createCrudService('api/v1/services/my');
const myRedirectData = {route:'/panel/my-farmer-information', mode: 'list', recordId:0 };

function* getUserDetailsList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getUserDetailsListSuccess, 
		getUserDetailsListFail, 
		null, null, LOADING_MSG);
}

function* getUserDetailsDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doUserDetailsCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail,
		createRecordSuccess(modelName),
		redirectData, 
		SAVING_MSG);
}

function* doUserDetailsUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail,
		updateRecordSuccess(modelName),
		data.payload['redirect'] === false ? null : redirectData,
		UPDATING_MSG);	
}

function* doUserDetailsDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail,
		deleteRecordSuccess(modelName),
		{...redirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* getMyUserDetailsDetails(data) {
	yield doCrudCall(
		() => myCrudService.doGetRequest('personal-profile', null), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail, 
		null, myRedirectData, LOADING_MSG);		
}

function* doMyUserDetailsUpdate(data){
	yield doCrudCall(
		() => userDetailsCrudService.doUpdate(data.payload[modelId], data.payload), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail,
		updateRecordSuccess(modelName),
		myRedirectData,
		UPDATING_MSG);	
}

function* doMyUserDetailsDelete(data){
	yield doCrudCall(
		() => userDetailsCrudService.doDelete(data.payload.recordId), 
		getUserDetailsDetailsSuccess, 
		getUserDetailsDetailsFail,
		deleteRecordSuccess(modelName),
		{...myRedirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* userDetailsSaga() {
  	yield takeLatest('userDetails/getUserDetailsList', getUserDetailsList);
  	yield takeLatest('userDetails/getUserDetailsDetails', getUserDetailsDetails);
  	yield takeLatest('userDetails/doUserDetailsCreate', doUserDetailsCreate);
  	yield takeLatest('userDetails/doUserDetailsUpdate', doUserDetailsUpdate);
  	yield takeLatest('userDetails/doUserDetailsDelete', doUserDetailsDelete); 
  	yield takeLatest('userDetails/getMyUserDetailsDetails', getMyUserDetailsDetails);
	yield takeLatest('userDetails/doMyUserDetailsUpdate', doMyUserDetailsUpdate);
	yield takeLatest('userDetails/doMyUserDetailsDelete', doMyUserDetailsDelete);   
}

export default userDetailsSaga;
