import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import { SafetyCertificateOutlined } from '@ant-design/icons';
import AccessRights from "./index";
import ViewAccessRight from "./view";
import EditAccessRight from "./edit";

const accessRightsRoutes = [	
	generateCRUDRoutes({
		mainTitle: "Access Rights",
		basePath: "access-rights",
		mainComponent: <AccessRights />,
	    layout: "/console",	   
	    icon: <SafetyCertificateOutlined className="h-6 w-6" />,
	    subTitle:"Access Right",
	    viewComponent: <ViewAccessRight />,
	    createComponent: <EditAccessRight />,
	    editComponent: <EditAccessRight />,
	})
]

export default accessRightsRoutes;