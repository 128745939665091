export const theme = {
    token: {
        colorPrimary: '#365314', // Custom primary color
        colorPrimaryBorder: '#365314',
        borderRadius: 8,         // Global border radius
        fontSize: 14,            // Base font size
    },
    components: {        
        Switch: {
            handleBg: '#ececec',
            trackHeight: 30,
            trackMinWidth: 64,
            innerMaxMargin: 30,
            handleSize: 26,
        },
        Tabs: {
            horizontalMargin: "0 0 0 20px",
        },
        Radio: {
            dotSize: 10,
            radioSize: 24,
        }        
      },
};
