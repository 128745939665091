import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const API_NAME = process.env.APP_NAME || process.env.REACT_APP_APP_NAME;

const CrudHandler = () => {
  const navigate = useNavigate();
  const recordId = useSelector(state => state.crud.recordId);
  const mode = useSelector(state => state.crud.mode);
  const route = useSelector(state => state.crud.route);

  useEffect(() => {
    if(route) {
		if(mode === 'reload'){
			window.location.reload();
		}else{
			let targetPath = route;
			if(mode !== 'list'){
				targetPath = `${route}/${recordId}/${mode}`;	
			}		
	      	navigate(targetPath, { replace: true });	
		}
		
    }
  }, [navigate, route, mode, recordId]);
  
  return null;
};

export default CrudHandler;