import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    details: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const farmerInfoSlice = createSlice({
  name: 'farmerInfo',
  initialState,
  reducers: {
    getFarmerInfoList: (state, action) => {		
        state.recordList.requesting = true;
        state.recordList.error = null;
        state.recordList.items = [];
        state.recordList.success = false;
        state.recordList.filters = action.payload;
    },
    getFarmerInfoListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.recordList.requesting = false;        
        state.recordList.items = data;
        state.recordList.success = true; 
        state.recordList.pagination.current_page = current_page;
        state.recordList.pagination.per_page = per_page;
        state.recordList.pagination.total = total;
    },
    getFarmerInfoListFail: (state, action) => {        
        state.recordList.requesting = false;
        state.recordList.error = action.payload;
    },
    getFarmerInfoDetails: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getFarmerInfoDetailsSuccess: (state, action) => {
        state.details.requesting = false;
        state.details.data = action.payload;
        state.details.success = true; 
    },
    getFarmerInfoDetailsFail: (state, action) => {        
        state.details.requesting = false;
        state.details.error = action.payload;
    },
    doFarmerInfoCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doFarmerInfoUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doFarmerInfoDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getMyFarmerInfoDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },  
    doMyFarmerInfoCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doMyFarmerInfoUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
  },
})

export const { 
    getFarmerInfoList, getFarmerInfoListSuccess, getFarmerInfoListFail,
    getFarmerInfoDetails, getFarmerInfoDetailsSuccess, getFarmerInfoDetailsFail,
    doFarmerInfoCreate, doFarmerInfoUpdate, doFarmerInfoDelete,
    getMyFarmerInfoDetails, doMyFarmerInfoCreate, doMyFarmerInfoUpdate
} = farmerInfoSlice.actions

export default farmerInfoSlice.reducer