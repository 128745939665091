import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  	isLoading: false,
  	loadingText: 'Loading...',
  	pageTitle: "Home",
  	darkMode: false,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    showLoading: (state, action) => {
        state.isLoading = true;
        state.loadingText = action.payload ?? 'Loading...';
    },
    hideLoading: (state) => {
        state.isLoading = false;
        state.loadingText = 'Loading...';
    },
    switchThemeMode: (state, action) => {
        state.darkMode = action.payrpayload;
    },
  },
})

export const { 
    showLoading, hideLoading, switchThemeMode
} = layoutSlice.actions

export default layoutSlice.reducer