import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { getMyFarmAnimalList, doFarmAnimalDelete } from "../../../../redux/farmanimal/farmAnimalState";
import { handleTableChange } from "../../../../utils/index";
import CrudButtons from "components/button/crudbuttons";

const MyRegisteredAnimalsTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
  	const columns = [
		{
	        title: `Category`,
	        dataIndex: 'category',
	        key: 'category',
	        width: '10%',
	        sorter: true,
	        render:(category) => <span>{category?.name}</span>
	    },
		{
	        title: `Type`,
	        dataIndex: 'type',
	        key: 'type',
	        width: '11%',
	        sorter: true,
	        render:(type) => <span>{type?.name}</span>
	    },	    
		{
	        title: <div className="text-right">Headcount</div>,
	        dataIndex: 'headcount',
	        key: 'headcount',
	        width: '8%',
	        render:(headcount) => <div className="text-right">{headcount}</div>
	    },
		{
	        title: `Condition`,
	        dataIndex: 'condition',
	        key: 'condition',
	        width: '12%',
	        render:(condition) => <span>{condition?.name}</span>
	    },
		{
	        title: `Purpose`,
	        dataIndex: 'purpose',
	        key: 'purpose',
	        width: '12%',
	        render:(purpose) => <span>{purpose?.name}</span>
	    },	
		{
	        title: `Breed`,
	        dataIndex: 'breed',
	        key: 'breed',
	        width: '12%',
	        render:(breed) => <span>{breed}</span>
	    },    
		{
	        title: `Farm`,
	        dataIndex: 'farm',
	        key: 'farm',
	        width: '22%',
	        render:(farm) => <span>{`${farm.name} / ${farm.location_barangay}, ${farm.location_municipality}`}</span>
	    },
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/panel/my-registered-animals/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '13%',
	        render:(id) => <CrudOption baseRoute="/panel/my-registered-animals" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
		console.log('test');
	    doSearch({paginate: pagination.per_page});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden mb-3">	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
			<CrudButtons baseRoute="/panel/my-registered-animals" 
				mode="view" 
				backUrl="/panel/services"
				cancelLabel="Back"
				withEdit={false}/>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.farmAnimal.recordList?.items,
	pagination: state.farmAnimal.recordList?.pagination,
	filters: state.farmAnimal.recordList?.filters,
  	requesting: state.farmAnimal.recordList?.requesting,
  	error: state.farmAnimal.recordList?.error,	  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getMyFarmAnimalList(filters)),
  doDelete: (id) => dispatch(doFarmAnimalDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRegisteredAnimalsTable);