import { takeLatest } from 'redux-saga/effects';
import {
  getUserListSuccess, getUserListFail,
  getUserDetailsSuccess, getUserDetailsFail
} from './userState';
import createCrudService from '../../services/crudservice';
import { 
	doCrudCall, SAVING_MSG, UPDATING_MSG, DELETING_MSG, LOADING_MSG,
	createRecordSuccess, updateRecordSuccess, deleteRecordSuccess
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/users');
const redirectData = {route:'/console/users', mode: 'list', recordId:0 };
const modelName = 'Users';
const modelId = 'id';

function* getUserList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getUserListSuccess, 
		getUserListFail,
		null, null, LOADING_MSG);
}

function* getUserDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getUserDetailsSuccess, 
		getUserDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doUserCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getUserDetailsSuccess, 
		getUserDetailsFail,
		createRecordSuccess(modelName),
		redirectData,
		SAVING_MSG);
}

function* doUserUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getUserDetailsSuccess, 
		getUserDetailsFail,
		updateRecordSuccess(modelName),
		redirectData,
		UPDATING_MSG);	
}

function* doUserDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getUserDetailsSuccess, 
		getUserDetailsFail,
		deleteRecordSuccess(modelName),
		{...redirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* userSaga() {
  yield takeLatest('user/getUserList', getUserList);
  yield takeLatest('user/getUserDetails', getUserDetails);
  yield takeLatest('user/doUserCreate', doUserCreate);
  yield takeLatest('user/doUserUpdate', doUserUpdate);
  yield takeLatest('user/doUserDelete', doUserDelete);  
}

export default userSaga;
