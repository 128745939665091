import React from 'react';
import Button from "components/button";
import { connect } from "react-redux";
import { hideModal } from '../../redux/modal/modalState';

function CrudButtons(props) {
  	const { baseRoute, mode, recordId, doHideModal, backUrl, cancelUrl, 
		withBack=true, 
		withEdit=true, 
		isModal=false, 
		submitLabel, 
		backLabel='Back', 
		cancelLabel='Cancel', 		
		updateLabel='Update', 
		createLabel='Save',
		...rest } = props;
  
  	return (
		<div className="dark:bg-intsamis-900 text-right my-3" {...rest}> 			
 			{mode === 'view' ? 
			<>
				{withBack && 				
					<a className="crud-button" href={backUrl ?? baseRoute}>
						{backLabel}
					</a>
				}
				{withEdit && 
				<a className="crud-button" href={`${baseRoute}/${recordId}/edit`}>
					Edit
				</a>
				}      			
	    	</>
 			: 
			<>
				{isModal ?
					<Button type="button" className="mx-3 min-w-[100px] text-center" key="cancel" onClick={() => doHideModal()}>
						{cancelLabel}
					</Button>
					: <a className="crud-button" href={cancelUrl ?? baseRoute}>
						{cancelLabel}
					</a>
				}
	            <Button type="submit" className="mx-3 min-w-[100px] text-center ">
	      			{submitLabel != null ? submitLabel : (recordId > 0 ? updateLabel : createLabel)}
	    		</Button>
    		</>			 
 			}
        </div>
  	);
}

const mapStateToProps = (state) => ({	
});

const mapDispatchToProps = (dispatch) => ({
  doHideModal: (title) => dispatch(hideModal(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CrudButtons);