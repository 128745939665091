import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import ContactDetailsFields from "../../../console/farmer-information/components/ContactDetailsFields";
import { 
	getMyUserDetailsDetails, doMyUserDetailsUpdate 
} from "../../../../redux/userdetails/userDetailsState";
import { useForm } from "antd/lib/form/Form";
import CrudButtons from "components/button/crudbuttons";

const MyContactDetailsForm = ({getRecord, doUpdate, recordData, requesting, success, regions, provinces, municipalities, barangays, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {
		getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { contact_details, emergency_contact_person, address, ...restData } = recordData;
			const initialValues = {
				...Object.fromEntries(
					Object.entries(contact_details || {}).map(([key, value]) => [`contact_${key}`, value])
				),
				...Object.fromEntries(
					Object.entries(emergency_contact_person || {}).map(([key, value]) => [`emergency_${key}`, value])
				),
				...Object.fromEntries(
					Object.entries(address || {}).map(([key, value]) => [`address_${key}`, value])
				),				
				...restData,
				section: 'Contact Details'
			};
			form.resetFields();
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		try {  
			const selectedRegion = regions.find(region => region.code === formData.address_region_code);
			formData.address_region = selectedRegion.name;

			const selectedProvince = provinces.find(province => province.code === formData.address_province_code);
			formData.address_province = selectedProvince.name;

			const selectedMunicipality = municipalities.find(municipality => municipality.code === formData.address_municipality_code);
			formData.address_municipality = selectedMunicipality.name;

			const selectedBarangay = barangays.find(barangay => barangay.code === formData.address_barangay_code);
			formData.address_barangay = selectedBarangay.name;

			formData.redirect = false;			
			doUpdate(formData); 
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<ContactDetailsFields recordData={recordData} mode={mode} form={form}/>

				<CrudButtons baseRoute="/panel/my-farmer-information" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/services"
					cancelUrl="/panel/services"
					cancelLabel="Back"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.userDetails.details?.data,
	requesting: state.userDetails.details?.requesting,
	success: state.userDetails.details?.success,
	regions: state.psgcData.regions?.items,
	provinces: state.psgcData.provinces?.items,
	municipalities: state.psgcData.municipalities?.items,
	barangays: state.psgcData.barangays?.items,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyUserDetailsDetails(id)),
	doUpdate: (data) => dispatch(doMyUserDetailsUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyContactDetailsForm);