import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    userRoleList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    userRoleDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const userRoleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    getUserRoleList: (state, action) => {		
        state.userRoleList.requesting = true;
        state.userRoleList.error = null;
        state.userRoleList.items = [];
        state.userRoleList.success = false;
        state.userRoleList.filters = action.payload;
    },
    getUserRoleListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.userRoleList.requesting = false;        
        state.userRoleList.items = data;
        state.userRoleList.success = true; 
        state.userRoleList.pagination.current_page = current_page;
        state.userRoleList.pagination.per_page = per_page;
        state.userRoleList.pagination.total = total;
    },
    getUserRoleListFail: (state, action) => {        
        state.userRoleList.requesting = false;
        state.userRoleList.error = action.payload;
    },
    getUserRoleDetails: (state) => {
        state.userRoleDetails.requesting = true;
        state.userRoleDetails.error = null;
        state.userRoleDetails.data = null;
        state.userRoleDetails.success = false; 
    },
    getUserRoleDetailsSuccess: (state, action) => {
        state.userRoleDetails.requesting = false;
        state.userRoleDetails.data = action.payload;
        state.userRoleDetails.success = true; 
    },
    getUserRoleDetailsFail: (state, action) => {        
        state.userRoleDetails.requesting = false;
        state.userRoleDetails.error = action.payload;
    },
    doUserRoleCreate: (state) => {
        state.userRoleDetails.requesting = true;
        state.userRoleDetails.error = null;
        state.userRoleDetails.data = null;
        state.userRoleDetails.success = false; 
    },
    doUserRoleUpdate: (state) => {
        state.userRoleDetails.requesting = true;
        state.userRoleDetails.error = null;
        state.userRoleDetails.data = null;
        state.userRoleDetails.success = false; 
    },
    doUserRoleDelete: (state) => {
        state.userRoleDetails.requesting = true;
        state.userRoleDetails.error = null;
        state.userRoleDetails.data = null;
        state.userRoleDetails.success = false; 
    },
  },
})

export const { 
    getUserRoleList, getUserRoleListSuccess, getUserRoleListFail,
    getUserRoleDetails, getUserRoleDetailsSuccess, getUserRoleDetailsFail,
    doUserRoleCreate, doUserRoleUpdate, doUserRoleDelete
} = userRoleSlice.actions

export default userRoleSlice.reducer