import MiniCalendar from "components/calendar/MiniCalendar";
import PieChartCard from "views/console/default/components/PieChartCard";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataComplex } from "./variables/columnsData";
import { topFarmers, topFarmerColumn } from "./variables/tableDataFarmers";

import Widget from "components/widget/Widget";
import ComplexTable from "views/console/default/components/ComplexTable";
import DailyTraffic from "views/console/default/components/DailyTraffic";
import tableDataComplex from "./variables/tableDataComplex.json";
import FarmersList from "views/console/default/components/FarmersList";

const Dashboard = () => {
  return (
    <div>
      
      {/* Card widgets */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Registered Farmer"}
          subtitle={"1,234"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Registered Animals"}
          subtitle={"5,678"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earned Points"}
          subtitle={"91,011"}
        />        
      </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">

        <MiniCalendar />

        <DailyTraffic />

        <PieChartCard />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <FarmersList
          extra="mb-5"
          tableData={topFarmers}
          columnsData={topFarmerColumn}
        />

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
      </div>
      
    </div>
  );
};

export default Dashboard;
