import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import { ToolOutlined } from '@ant-design/icons';
import Parameters from "./index";
import ViewParameter from "./view";
import EditParameter from "./edit";

const parametersRoutes = [	
	generateCRUDRoutes({
		mainTitle: "Parameters",
		basePath: "parameters",
		mainComponent: <Parameters />,
	    layout: "/console",	   
	    icon: <ToolOutlined className="h-6 w-6" />,
	    subTitle:"Parameter",
	    viewComponent: <ViewParameter />,
	    createComponent: <EditParameter />,
	    editComponent: <EditParameter />,
	})
]

export default parametersRoutes;