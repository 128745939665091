import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Select } from 'antd';
import FormItem from "components/fields/FormItem";
import { 
	getRegions, getProvinces, getMunicipalities, getBarangays
} from "../../redux/psgcdata/psgcDataState";

const RegionProvinceBarangay = ({recordData, mode, form,
	getRegionList, getProvinceList, getMunicipalityList, getBarangayList,
	regions, provinces, municipalities, barangays}) => {
	
	const handleRegionChange = (value) => {
		form.setFieldsValue({
			...form.getFieldsValue(), 
			address_province_code: null, 
			address_municipality_code: null, 
			address_barangay_code: null
		});
		getProvinceList(value);
    };

	const handleProvinceChange = (value) => {
		form.setFieldsValue({
			...form.getFieldsValue(), 
			address_municipality_code: null, 
			address_barangay_code: null
		});
		getMunicipalityList(value);
	}

	const handleMunicipalityChange = (value) =>{
		form.setFieldsValue({
			...form.getFieldsValue(), 
			address_barangay_code: null
		});
		getBarangayList(value);
	}

	useEffect(() => {
		getRegionList({});

		if(recordData?.address){
			if(recordData.address?.region_code){
				getProvinceList(recordData.address?.region_code);
			}
			if(recordData.address?.province_code){
				getMunicipalityList(recordData.address?.province_code);
			}
			if(recordData.address?.municipality_code){
				getBarangayList(recordData.address?.municipality_code);
			}
		}

	}, [recordData]);
	
  	return (
		<div className="form-column-4">
			<FormItem 
				name="address_region_code"
				label="Region"
			>
				{mode === 'edit' ?
				<Select id="address_region_code"            		
					options={regions}
					showSearch
					allowClear={true}
					onChange={handleRegionChange}
				/>					
				: <label className="ps-3 dark:text-white">{recordData?.address_region}</label>
				}	                
			</FormItem>
			<FormItem 
				name="address_province_code"
				label="Province"
			>
				{mode === 'edit' ?
				<Select id="address_province_code"            		
					options={provinces}
					showSearch
					allowClear={true}
					onChange={handleProvinceChange}
				/>					
				: <label className="ps-3 dark:text-white">{recordData?.address_province}</label>
				}	                
			</FormItem>
			<FormItem 
				name="address_municipality_code"
				label="Municipality/City"
			>
				{mode === 'edit' ?
				<Select id="address_municipality_code"            		
					options={municipalities}
					showSearch
					allowClear={true}
					onChange={handleMunicipalityChange}
				/>
				: <label className="ps-3 dark:text-white">{recordData?.address_municipality}</label>
				}	                
			</FormItem>	
			<FormItem 
				name="address_barangay_code"
				label="Barangay"
			>
				{mode === 'edit' ?
				<Select id="address_barangay_code"            		
					options={barangays}
					showSearch
					allowClear={true}
				/>
				: <label className="ps-3 dark:text-white">{recordData?.address_barangay}</label>
				}	                
			</FormItem>            
        </div>
  );
}

const mapStateToProps = (state) => ({
	regions: state.psgcData.regions?.items,
	provinces: state.psgcData.provinces?.items,
	municipalities: state.psgcData.municipalities?.items,
	barangays: state.psgcData.barangays?.items,
});

const mapDispatchToProps = (dispatch) => ({
	getRegionList: (data) => dispatch(getRegions(data)),
	getProvinceList: (data) => dispatch(getProvinces(data)),
	getMunicipalityList: (data) => dispatch(getMunicipalities(data)),
	getBarangayList: (data) => dispatch(getBarangays(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionProvinceBarangay);