import React from "react";
import { Route } from "react-router-dom";

export const NumberFormatter = (number, decimal=2) => {	
	return number !== undefined 
    ? parseFloat(number).toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : '';
};

export const handleTableChange = (doSearch, filters) => (pagination, tableFilters, sorter) => {
  const columnFilters = Object.fromEntries(
    Object.entries(tableFilters).map(([key, value]) => [
      key, 
      value ? value.join(', ') : '',
    ])
  );

  const sorting = sorter.field ? {
    sortby: sorter.field, 
    sortorder: sorter.order === 'descend' ? 'desc' : 'asc',
  } : {};

  doSearch({
    ...filters,
    paginate: pagination.pageSize,
    page: pagination.current,
    ...sorting,
    ...columnFilters,
  });
};

export const generateCRUDRoutes = ({
	mainTitle, 
	basePath, 
	mainComponent, 
	layout, 
	icon, 
	subTitle, 
	viewComponent, 
	createComponent, 
	editComponent,
	isMainMenu=true, 
	submodules=[],
	viewSubmodules=[],
	}) => {
		
	const pathSegments = basePath.split('/');	
	const recordId = `${pathSegments[pathSegments.length - 1].replace(/-/g, '').slice(0, -1)}Id`;
	return {
		name: `${mainTitle}`,
		path: `${basePath}`,
		component: mainComponent,
	    layout,	    
	    icon,	    
	    isMainMenu,
	    children: [
		  {
		    name: `${subTitle} Details`,
		    layout: layout,
		    path: `${basePath}/:${recordId}/view`,
		    component: viewComponent,
		    isMainMenu: false,
		    children: [
				...viewSubmodules,
			],
		  },
		  {
		    name: `Add ${subTitle}`,
		    layout: layout,
		    path: `${basePath}/add`,
		    component: createComponent,
		    isMainMenu: false,
		  },
		  {
		    name: `Edit ${subTitle}`,
		    layout: layout,
		    path: `${basePath}/:${recordId}/edit`,
		    component: editComponent,
		    isMainMenu: false,
		  },
		  ...submodules,
		]
	}
};

export const getRoutes = (routes, layout) => {
	return routes
	  .filter((route) => route.path !== "sign-in")
	  .map((prop, index) => {
		if (prop.layout === layout) {
		  return (			
			<React.Fragment key={`${prop.path}-${index}`}>
			  <Route path={prop.path.startsWith('/') ? prop.path : `/${prop.path}`} element={prop.component} />
			  {Array.isArray(prop.children) && getRoutes(prop.children, layout)}
			</React.Fragment>
		  );
		} else {
		  return null;
		}
	  });
  };