import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    roleList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    roleDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
    userList: {
      requesting: false,
      items: [],      
      error: null,
      success: false,
      filters: {},
    },
  };

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getRoleList: (state, action) => {		
      state.roleList.requesting = true;
      state.roleList.error = null;
      state.roleList.items = [];
      state.roleList.success = false;
      state.roleList.filters = action.payload;
    },
    getRoleListSuccess: (state, action) => {
		  const { data, current_page, per_page, total} = action.payload;
      state.roleList.requesting = false;        
      state.roleList.items = data;
      state.roleList.success = true; 
      state.roleList.pagination.current_page = current_page;
      state.roleList.pagination.per_page = per_page;
      state.roleList.pagination.total = total;
    },
    getRoleListFail: (state, action) => {        
      state.roleList.requesting = false;
      state.roleList.error = action.payload;
    },
    getRoleDetails: (state) => {
      state.roleDetails.requesting = true;
      state.roleDetails.error = null;
      state.roleDetails.data = null;
      state.roleDetails.success = false; 
    },
    getRoleDetailsSuccess: (state, action) => {
      state.roleDetails.requesting = false;
      state.roleDetails.data = action.payload;
      state.roleDetails.success = true; 
    },
    getRoleDetailsFail: (state, action) => {        
      state.roleDetails.requesting = false;
      state.roleDetails.error = action.payload;
    },
    doRoleCreate: (state) => {
      state.roleDetails.requesting = true;
      state.roleDetails.error = null;
      state.roleDetails.data = null;
      state.roleDetails.success = false; 
    },
    doRoleUpdate: (state) => {
      state.roleDetails.requesting = true;
      state.roleDetails.error = null;
      state.roleDetails.data = null;
      state.roleDetails.success = false; 
    },
    doRoleDelete: (state) => {
      state.roleDetails.requesting = true;
      state.roleDetails.error = null;
      state.roleDetails.data = null;
      state.roleDetails.success = false; 
    },
    getAddUserList: (state, action) => {		
      state.userList.requesting = true;
      state.userList.error = null;
      state.userList.items = [];
      state.userList.success = false;
    },
    getAddUserListSuccess: (state, action) => {
      state.userList.requesting = false;        
      state.userList.items = action.payload;
      state.userList.success = true; 
    },
    getAddUserListFail: (state, action) => {        
      state.userList.requesting = false;
      state.userList.error = action.payload;
    },
  },
})

export const { 
    getRoleList, getRoleListSuccess, getRoleListFail,
    getRoleDetails, getRoleDetailsSuccess, getRoleDetailsFail,
    doRoleCreate, doRoleUpdate, doRoleDelete,
    getAddUserList, getAddUserListSuccess, getAddUserListFail,
} = roleSlice.actions

export default roleSlice.reducer