import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordList: {
      requesting: false,
      items: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      error: null,
      success: false,
      filters: {},
    },
    details: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const farmParcelSlice = createSlice({
  name: 'farmParcel',
  initialState,
  reducers: {
    getFarmParcelList: (state, action) => {		
      state.recordList.requesting = true;
      state.recordList.error = null;
      state.recordList.items = [];
      state.recordList.success = false;
      state.recordList.filters = action.payload;
    },
    getFarmParcelListSuccess: (state, action) => {
		  const { data, current_page, per_page, total} = action.payload;
      state.recordList.requesting = false;        
      state.recordList.items = data;
      state.recordList.success = true; 
      state.recordList.pagination.current_page = current_page;
      state.recordList.pagination.per_page = per_page;
      state.recordList.pagination.total = total;
    },
    getFarmParcelListFail: (state, action) => {        
      state.recordList.requesting = false;
      state.recordList.error = action.payload;
    },
    getFarmParcelDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    getFarmParcelDetailsSuccess: (state, action) => {
      state.details.requesting = false;
      state.details.data = action.payload;
      state.details.success = true; 
    },
    getFarmParcelDetailsFail: (state, action) => {        
      state.details.requesting = false;
      state.details.error = action.payload;
    },
    doFarmParcelCreate: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doFarmParcelUpdate: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doFarmParcelDelete: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    getMyFarmParcelList: (state, action) => {		
      state.recordList.requesting = true;
      state.recordList.error = null;
      state.recordList.items = [];
      state.recordList.success = false;
      state.recordList.filters = action.payload;
    },
    getMyFarmParcelDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doMyFarmParcelCreate: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doMyFarmParcelUpdate: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doMyFarmParcelDelete: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
  },
})

export const { 
    getFarmParcelList, getFarmParcelListSuccess, getFarmParcelListFail,
    getFarmParcelDetails, getFarmParcelDetailsSuccess, getFarmParcelDetailsFail,
    doFarmParcelCreate, doFarmParcelUpdate, doFarmParcelDelete,
    getMyFarmParcelList, getMyFarmParcelDetails, 
    doMyFarmParcelCreate, doMyFarmParcelUpdate, doMyFarmParcelDelete
} = farmParcelSlice.actions

export default farmParcelSlice.reducer