import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import "./index.css";
import LoadingIndicator from "components/indicator/loadingindicator";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  	<Provider store={store}>
      	<BrowserRouter>
        	<App />
        	<LoadingIndicator/>
      	</BrowserRouter>
  	</Provider>
);
