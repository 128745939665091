import React  from "react";
import { useParams } from 'react-router-dom';
import ParameterForm from "./components/ParameterForm";

const EditModule = () => {
	const { parameterId } = useParams();

	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
      		<ParameterForm recordId={parameterId} mode="edit"/>
	      </div>
	    </div>
    );
};

export default EditModule;