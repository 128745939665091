import React  from "react";
import { useParams } from 'react-router-dom';
import RoleForm from "./components/RoleForm";

const EditRole = () => {
	const { roleId } = useParams();

	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
      		<RoleForm recordId={roleId} mode="edit"/>
	      </div>
	    </div>
    );
};

export default EditRole;