import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getModuleList, doModuleDelete } from "../../../../redux/module/moduleState";
import ColumnSearchFilter from 'components/search/ColumnSearchFilter';
import SearchFilter from "components/search/SearchFilter";
import { handleTableChange } from "../../../../utils/index";

const ModuleTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
	const { getColumnSearchProps } = ColumnSearchFilter();
  	const columns = [
		{
	        title: `Name`,
	        dataIndex: 'name',
	        key: 'name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('name'),
	        render:(name) => <span>{name}</span>
	    },
	    {
	        title: `Code`,
	        dataIndex: 'code',
	        key: 'code',
	        width: '10%',
	        render:(code) => <span>{code}</span>
	    },
		{
	        title: `Route`,
	        dataIndex: 'route',
	        key: 'route',
	        width: '17%',
	        render:(route) => <span>{route}</span>
	    },
		{
	        title: `Parent Module`,
	        dataIndex: 'parent_module',
	        key: 'parent_module_name',
	        width: '20%',
	        render:(parent_module) => <span>{parent_module?.name}</span>
	    },
	    {
	        title: `Sort Order`,
	        dataIndex: 'sort_order',
	        key: 'sort_order',
	        width: '8%',
	        render:(sort_order) => <span>{sort_order}</span>
	    },
		{
	        title: `Is Menu`,
	        dataIndex: 'is_menu',
	        key: 'is_menu',
	        width: '5%',
	        render:(is_menu) => <span>{is_menu === 1 ? 'Y' : 'N'}</span>
	    },
		{
	        title: `Is Active`,
	        dataIndex: 'is_active',
	        key: 'is_active',
	        width: '5%',
	        render:(is_active) => <span>{is_active === 1 ? 'Y' : 'N'}</span>
	    },
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/console/modules/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '10%',
	        render:(id) => <CrudOption baseRoute="/console/modules" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">
	      		
	      		<SearchFilter placeholder="Search by Module Name" searchHandler={doSearch} filters={filters} pagination={pagination}/>
	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.module.moduleList?.items,
	pagination: state.module.moduleList?.pagination,
	filters: state.module.moduleList?.filters,
  	requesting: state.module.moduleList?.requesting,
  	error: state.module.moduleList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getModuleList(filters)),
  doDelete: (id) => dispatch(doModuleDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleTable);