import CardItem from "components/card/CardItem";
import React, { useMemo } from "react";
import { MdPeopleAlt } from "react-icons/md";
import UserAvatar from "assets/img/avatars/user.png";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function FarmersList(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <CardItem 
      icon={<MdPeopleAlt className="h-6 w-6 text-theme-500 dark:text-theme-400"/>}
      title={"Top 10 Farmers"}
      cardMenu={
      <button className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-theme-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
      See all
      </button>}
      extra={"h-[750px] w-full"}
      >
      
      <div className="w-full overflow-x-scroll px-4 md:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full min-w-[500px] overflow-x-scroll"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <div className="flex items-center justify-between pt-4 pb-2 text-start uppercase tracking-wide text-gray-600 sm:text-xs lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="px-4">
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Name") {
                      data = (
                        <div className="flex items-center gap-2">   
                          <img
                            className="h-10 w-10 rounded-full"
                            src={UserAvatar}
                            alt="Elon Musk"
                          />                      
                          <p className="text-sm font-medium text-theme-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else {
                      data = (
                        <p className="text-md font-medium text-theme-700 dark:text-white">
                          {cell.value}
                        </p>
                      );                    
                    }
                    return (
                      <td
                        className="py-3 text-sm"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CardItem>
  );
}

export default FarmersList;
