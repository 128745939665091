import { takeLatest } from 'redux-saga/effects';
import {
  getFarmerInfoListSuccess, getFarmerInfoListFail,
  getFarmerInfoDetailsSuccess, getFarmerInfoDetailsFail
} from './farmerInfoState';
import createCrudService from '../../services/crudservice';
import { 
	doCrudCall, SAVING_MSG, UPDATING_MSG, DELETING_MSG, LOADING_MSG,
	createRecordSuccess, updateRecordSuccess, deleteRecordSuccess
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/services/farmer-profiles');
const redirectData = {route:'/console/farmer-information', mode: 'list', recordId:0 };
const myCrudService = createCrudService('api/v1/services/my/farmer-profiles');
const myRedirectData = {route:'/panel/my-farmer-information#farmer_info', mode: 'list', recordId:0 };
const myFarmerProfileService = createCrudService('api/v1/services/my');
const modelName = 'Farmer Information';
const modelId = 'id';

function* getFarmerInfoList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getFarmerInfoListSuccess, 
		getFarmerInfoListFail, 
		null, null, LOADING_MSG);
}

function* getFarmerInfoDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doFarmerInfoCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail,
		createRecordSuccess(modelName),
		redirectData, 
		SAVING_MSG);
}

function* doFarmerInfoUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail,
		updateRecordSuccess(modelName),
		redirectData,
		UPDATING_MSG);	
}

function* doFarmerInfoDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail,
		deleteRecordSuccess(modelName),
		{...redirectData, mode: data.payload.mode},
		DELETING_MSG);	
}

function* getMyFarmerInfoDetails(data) {
	yield doCrudCall(
		() => myFarmerProfileService.doGetRequest('farmer-profile', null), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail, 
		null, null, LOADING_MSG);		
}

function* doMyFarmerInfoCreate(data){
	yield doCrudCall(
		() => myCrudService.doCreate(data.payload), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail,
		createRecordSuccess(modelName),
		myRedirectData,
		SAVING_MSG);	
}

function* doMyFarmerInfoUpdate(data){
	yield doCrudCall(
		() => myCrudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmerInfoDetailsSuccess, 
		getFarmerInfoDetailsFail,
		updateRecordSuccess(modelName),
		myRedirectData,
		UPDATING_MSG);	
}

function* farmerInfoSaga() {
  yield takeLatest('farmerInfo/getFarmerInfoList', getFarmerInfoList);
  yield takeLatest('farmerInfo/getFarmerInfoDetails', getFarmerInfoDetails);
  yield takeLatest('farmerInfo/doFarmerInfoCreate', doFarmerInfoCreate);
  yield takeLatest('farmerInfo/doFarmerInfoUpdate', doFarmerInfoUpdate);
  yield takeLatest('farmerInfo/doFarmerInfoDelete', doFarmerInfoDelete);
  yield takeLatest('farmerInfo/getMyFarmerInfoDetails', getMyFarmerInfoDetails);
  yield takeLatest('farmerInfo/doMyFarmerInfoCreate', doMyFarmerInfoCreate);
  yield takeLatest('farmerInfo/doMyFarmerInfoUpdate', doMyFarmerInfoUpdate); 
}

export default farmerInfoSaga;
