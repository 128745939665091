import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import OpenModalButton from "components/button/modalbutton";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getUserRoleList, doUserRoleDelete } from "../../../../redux/userrole/userRoleState";
import ColumnSearchFilter from 'components/search/ColumnSearchFilter';
import SearchFilter from "components/search/SearchFilter";
import { handleTableChange } from "../../../../utils/index";
import RoleUserForm from "./RoleUserForm";

const RoleUsersTable = ({ recordList, pagination, doSearch, doDelete, filters, roleId=0 }) => {
	const { getColumnSearchProps } = ColumnSearchFilter();
  	const columns = [
		{
	        title: `User`,
	        dataIndex: 'user',
	        key: 'username',
	        width: '30%',
	        sorter: true,
	        ...getColumnSearchProps('user.username'),
	        render:(user) => <span>{user?.username}</span>
	    },	    
		{
	        title: `First Name`,
	        dataIndex: 'user',
	        key: 'first_name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('first_name'),
	        render:(user) => <span>{user?.user_details?.first_name}</span> 	       
	    },
		{
	        title: `Middle Name`,
	        dataIndex: 'user',
	        key: 'middle_name',
	        width: '20%',
	        render:(user) => <span>{user?.user_details?.middle_name}</span> 	       
	    },
	    {
	        title: `Last Name`,
	        dataIndex: 'user',
	        key: 'last_name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('last_name'),
	        render:(user) => <span>{user?.user_details?.last_name}</span> 	       
	    },
	    {
	        title: <OpenModalButton extra="btn-small" 
						label={<PlusOutlined />}
						modalTitle="Add User"
						footer={[]}
						>
						<RoleUserForm roleId={roleId} recordId={null} mode="edit"/>
					</OpenModalButton>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '10%',
	        render:(id) => <CrudOption baseRoute={`/console/roles/${roleId}/user-roles`} 
								recordId={id} 
								deleteHandler={doDelete}
								allowView={false}
								allowEdit={false}
							/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page, role: roleId});
    }, [doSearch, pagination.per_page, roleId]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">
	      		
	      		<SearchFilter placeholder="Search by UserRole Name" searchHandler={doSearch} filters={filters} pagination={pagination}/>
	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.userRole.userRoleList?.items,
	pagination: state.userRole.userRoleList?.pagination,
	filters: state.userRole.userRoleList?.filters,
  	requesting: state.userRole.userRoleList?.requesting,
  	error: state.userRole.userRoleList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getUserRoleList(filters)),
  doDelete: (id) => dispatch(doUserRoleDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleUsersTable);