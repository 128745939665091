import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { Input, Select, InputNumber } from 'antd';
import FormItem from "components/fields/FormItem";
import TextAreaField from "components/fields/TextAreaField";
import { requiredField } from '../../../../utils/crudSagaHelper';

const RegisterAnimalFields = ({recordData, mode, form, ...props}) => {

	const [animalTypes, setAnimalTypes] = useState([]);
	
	const doFilterAnimalTypeOption = (category) =>{
		form.setFieldsValue({
			...form.getFieldsValue(), 
			type_id: null, 
		});
		const filteredTypes = recordData?.animalTypeOptions?.filter((data) => data.category == category);
		setAnimalTypes(filteredTypes);		
	}

	useEffect(() => {
		if(recordData){
			doFilterAnimalTypeOption(recordData.category_id);
		}
	}, [recordData]);

	const onCategoryChangeHandler = (value) => {
		doFilterAnimalTypeOption(value);
    };

  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<FormItem name="user_details_id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<div className="form-column-4">
				<FormItem extra="col-span-2"
					name="farm_id"
					label="Farm Name or Location"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="farm_id"
						allowClear={true}
						options={recordData?.userFarmParcels}
					/>	
					: <label className="ps-3 dark:text-white">{recordData?.farm?.name}</label>
					}					
				</FormItem>
				<FormItem 
					name="category_id"
					label="Category"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="category_id"
						allowClear={true}
						options={recordData?.animalCategoryOptions}
						onChange={onCategoryChangeHandler}
					/>					
					: <label className="ps-3 dark:text-white">{recordData?.category?.name}</label>
					}                
				</FormItem>
				<FormItem 
					name="type_id"
					label="Type"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="type_id"
						allowClear={true}
						options={animalTypes}
					/>					
					: <label className="ps-3 dark:text-white">{recordData?.type?.name}</label>
					}                
				</FormItem>				
				<FormItem 
					name="headcount"
					label="Headcount"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<InputNumber id="headcount"/>
					: <label className="ps-3 dark:text-white">{recordData?.headcount}</label>
					}	                
				</FormItem>
				<FormItem 
					name="condition_id"
					label="Condition"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="condition_id"
						allowClear={true}
						options={recordData?.animalConditionOptions}
					/>					
					: <label className="ps-3 dark:text-white">{recordData?.condition?.name}</label>
					}	                
				</FormItem>
				<FormItem 
					name="purpose_id"
					label="Purpose"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="purpose_id"
						allowClear={true}
						options={recordData?.animalPurposeOptions}
					/>					
					: <label className="ps-3 dark:text-white">{recordData?.purpose?.name}</label>
					}	                
				</FormItem>	
				<FormItem 
					name="breed"
					label="Breed"
				>
					{mode === 'edit' ?
					<Input id="breed"
						maxLength={100}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.breed}</label>
					}                
				</FormItem>
				
				<FormItem extra="col-span-4"
					name="remarks"
					label="Notes or Remarks"
				>
					{mode === 'edit' ?
					<TextAreaField id="remarks"
						maxLength={1000}
					/>	
					: <label className="ps-3 dark:text-white">{recordData?.remarks}</label>
					}					
				</FormItem>
			</div>
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAnimalFields);
