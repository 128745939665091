import React from 'react';
import { connect } from "react-redux";
import { Modal } from 'antd';
import Button from "components/button";
import { showModal, hideModal } from '../../redux/modal/modalState';

function OpenModalButton({doShowModal, doHideModal, activeModal, ...props}) {
	const { label, extra, modalTitle, children, onShowHandler, okHandler, footer, ...rest } = props;
	const showModal = () => {
		if(onShowHandler){
			onShowHandler();
		}
		doShowModal(modalTitle);
	};

	const handleOk = () => {		
		if(okHandler){
			okHandler();
		}
		doHideModal(modalTitle);
	};

	const handleCancel = () => {
		doHideModal(modalTitle);
	};
	
    return (
		<>
			<Button className={`${extra}`} type="primary" onClick={showModal} {...rest}>
        		{label}
      		</Button>
			<Modal title={modalTitle} 
				centered
				open={activeModal === modalTitle} 
				onOk={handleOk} 
				onCancel={handleCancel}
				footer={footer}
				>
				{children}
			</Modal>           
        </>
    );
}
const mapStateToProps = (state) => ({
	activeModal: state.modal.activeModal,  
});

const mapDispatchToProps = (dispatch) => ({
  doShowModal: (title) => dispatch(showModal(title)),
  doHideModal: (title) => dispatch(hideModal(title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenModalButton);