import React, { useState  }  from "react";
import CardItem from "components/card/CardItem";
import { ReactComponent as Logo } from 'assets/svg/intsamis_logo.svg';
import { connect } from "react-redux";
import Button from "components/button";
import InputField from "components/fields/InputField";
import { doConfirmRegistration, doCancelRegistration } from "../../redux/auth/authState";
import PanelBanner from "components/banners/PanelBanner";

const Register = ({ scannedQR, qrUser, confirmRegistration,  cancelRegistration}) => {
  
  const [inputs, setInputs] = useState({});
  const [askPassword, setAskPassword] = useState(false);

  const handleCancelRegistration = () =>{
    cancelRegistration();
  }

  const handleRegisterBack = () => {
    setAskPassword(false);
  }

  const handleConfirmRegistration = (e) => {
    setAskPassword(true);    
  };

  const handleSubmitRegistration = () => {    
    console.log(scannedQR);
    console.log(qrUser);
    confirmRegistration({username: qrUser.data, type:'kiosk', ...scannedQR, ...inputs});
  }

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  return (
    <div className="m-4 flex flex-col h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      <PanelBanner/>
      {askPassword ? 
      <CardItem 
        extra="w-[500px] mt-8 border-1"
        icon={<Logo/>}
        htmlTitle={<h4 className="ml-4 card-title text-3xl dark:text-theme-200">Account Security</h4>}>
      
        <div className="m-[40px] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px] mx-auto">
          <InputField
            extra="mb-3"
            label="Enter Password*"
            placeholder="Enter a strong password"
            id="password"
            type="password"
            value={inputs.password}
            onChange={handleChange}
          />
          <div className="text-sm mb-4 px-2">Minimum of 8 characters with at least one uppercase, one lowercase, and a number</div>
          <InputField
            extra="mb-3"
            label="Confirm Password*"
            placeholder="Re-enter password"
            id="password_confirmation"
            type="password"
            value={inputs.password_confirmation}
            onChange={handleChange}
          />
        </div>

        <div className="flex gap-20 justify-center">
          <Button type="button" className="min-w-[140px] text-xl" onClick={handleRegisterBack}>Back</Button>
          <Button type="button" className=" min-w-[140px] text-xl" onClick={handleSubmitRegistration}>Submit</Button>
        </div>
  
      </CardItem>
      : <CardItem 
        extra="w-[600px] mt-8 border-1 bg-gray-100"
        icon={<Logo/>}
        htmlTitle={<h4 className="ml-4 card-title text-3xl dark:text-theme-200">Confirm Registration</h4>}>
      
        <div className="py-[50px] text-2xl dark:text-white text-center">You are not yet registered in the system. Do you want to register?</div>

        <div className="flex gap-20 justify-center">
          <Button type="button" className="min-w-[140px] text-xl" onClick={handleCancelRegistration}>No</Button>
          <Button type="button" className=" min-w-[140px] text-xl" onClick={handleConfirmRegistration}>Register</Button>
        </div>
    
      </CardItem>
      }

    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  scannedQR: state.auth.scannedQR,
  qrUser: state.auth.qrUser,
});

const mapDispatchToProps = (dispatch) => ({
  confirmRegistration: (qrdata) => dispatch(doConfirmRegistration(qrdata)),
  cancelRegistration: () => dispatch(doCancelRegistration()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);