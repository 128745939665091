import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    details: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const farmProfileSlice = createSlice({
  name: 'farmProfile',
  initialState,
  reducers: {
    getFarmProfileList: (state, action) => {		
        state.recordList.requesting = true;
        state.recordList.error = null;
        state.recordList.items = [];
        state.recordList.success = false;
        state.recordList.filters = action.payload;
    },
    getFarmProfileListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.recordList.requesting = false;        
        state.recordList.items = data;
        state.recordList.success = true; 
        state.recordList.pagination.current_page = current_page;
        state.recordList.pagination.per_page = per_page;
        state.recordList.pagination.total = total;
    },
    getFarmProfileListFail: (state, action) => {        
        state.recordList.requesting = false;
        state.recordList.error = action.payload;
    },
    getFarmProfileDetails: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getFarmProfileDetailsSuccess: (state, action) => {
        state.details.requesting = false;
        state.details.data = action.payload;
        state.details.success = true; 
    },
    getFarmProfileDetailsFail: (state, action) => {        
        state.details.requesting = false;
        state.details.error = action.payload;
    },
    doFarmProfileCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doFarmProfileUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doFarmProfileDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getMyFarmProfileDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },  
    doMyFarmProfileCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doMyFarmProfileUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
  },
})

export const { 
    getFarmProfileList, getFarmProfileListSuccess, getFarmProfileListFail,
    getFarmProfileDetails, getFarmProfileDetailsSuccess, getFarmProfileDetailsFail,
    doFarmProfileCreate, doFarmProfileUpdate, doFarmProfileDelete,
    getMyFarmProfileDetails, doMyFarmProfileCreate, doMyFarmProfileUpdate
} = farmProfileSlice.actions

export default farmProfileSlice.reducer