import React from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import routes from "routes.js";

const findRouteByPath = (routes, path, ancestors = []) => {
  for (let route of routes) {
    if ((route.layout + "/" + route.path) === path) {
      return { route, ancestors };
    }
    if (route.children) {
      const result = findRouteByPath(route.children, path, [...ancestors, route]);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const generateBreadcrumbs = (path) => {
  const pathSegments = path.split('/').filter(segment => segment);
  let breadcrumbs = [];
  let accumulatedPath = '';
  let dynamicPaths = [];

  pathSegments.forEach((segment, index) => {
	if (isNaN(segment) === false) {
		let previous = pathSegments[index-1];
		if(previous){
			const previousSplit = previous.split('-');
			const dynamicParam = previousSplit[previousSplit.length - 1];
			const variableSegment = `:${dynamicParam.substring(0, dynamicParam.length - 1)}Id`;
			
			accumulatedPath += `/${variableSegment}`;			
			dynamicPaths.push({variable: variableSegment, value: segment});
		}else{
			accumulatedPath += `/${segment}`;
		}		
	}else{
		accumulatedPath += `/${segment}`;	
	}
    
    const { route, ancestors } = findRouteByPath(routes, accumulatedPath) || {};
    if (route) {
      breadcrumbs = [...ancestors, route];
    }
  });
  
  // map breadcrumbs dynamic path with the actual value 
  breadcrumbs = breadcrumbs.map(route =>{  	
  	let actualPath = route.path;
  	dynamicPaths.forEach(dynamicPath => {
		actualPath = actualPath.replace(dynamicPath.variable, dynamicPath.value);		
	});
	return { ...route, path: actualPath };
  });
  
  return breadcrumbs;
};

const Breadcrumbs = () => {
	const location = useLocation();	
  	const breadcrumbs = generateBreadcrumbs(location.pathname);
  	const currentRoute = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length-1] : null;

  	return (    
	    <div className="w-full max-w-[500px] ml-[6px]">
		    <div className="h-6 pt-1 flex">
		    	{breadcrumbs.map((breadcrumb, index) => (					
					currentRoute?.path !== breadcrumb.path ? 
					(<div className="text-sm text-theme-700 hover:text-theme-700 dark:text-white" key={`${breadcrumb.path}-${index}`}>
					{index > 0 && <span className="mx-1">/</span>}
		            {breadcrumb.path !== '#' ? <Link key={index} to={`${breadcrumb.layout}/${breadcrumb.path}`}>{breadcrumb.name}</Link> : breadcrumb.name}		            
		            </div>)
					: index === 0 ? 'Main' : ''
		        ))}          
	        </div>
	        <p className="shrink text-[33px] capitalize text-theme-700 dark:text-white">
	        	<Link to="#"
	        		className="font-bold capitalize hover:text-theme-700 dark:hover:text-white"
	        	>{currentRoute?.name}
	        	</Link>	          
	        </p>
	  	</div>
  	);
};

export default Breadcrumbs;