import React, { useState, useEffect } from "react";
import { Input, Space  } from 'antd';
import FormItem from "components/fields/FormItem";
import SwitchField from "components/fields/SwitchField";
import { requiredField } from '../../utils/crudSagaHelper';

const SwitchWithSpecify = (props) => {
  	const { id, type, children, switchLabel, switchValue,
  		specifyFieldName, specifyDisplayValue, mode, onChangeHandler, direction, 
		specifyPlaceholder, specifyStyle, childrenClass, condition=true, specifyMaxLength=50 } = props;

	const [showSpecify, setShowSpecify] = useState(!condition ? 'text' : 'hidden');
	const handleOnChange = (checked) => {	
        setShowSpecify(checked === condition ? 'text' : 'hidden');
        if(onChangeHandler){
			onChangeHandler();
		}
    };

	useEffect(() => {
		const defaultShow = switchValue == 1 || switchValue == true;
	 	setShowSpecify(defaultShow === condition ? 'text' : 'hidden');
	}, [switchValue]);
    
  	return (
		  <Space size="middle" block={true} direction={direction ?? 'horizontal'} wrap={true}>         
            <FormItem
            	name={id}
                noStyle                
            >
            	<SwitchField id={id} 
					type={type} 
					label={switchLabel ?? null }
					onChange={handleOnChange}
					/>
        	</FormItem>
            <FormItem
            	name={specifyFieldName}
                label="Specify"
                noStyle
				rules={ showSpecify === 'text' ? [requiredField()] : []}
            >
            	{mode === 'edit' ?
            	<Input id={specifyFieldName}
            		placeholder={specifyPlaceholder ?? 'If yes, specify'}
            		type={showSpecify} 
					className={direction === 'vertical' ? 'mt-3':''}
					style={specifyStyle}
					maxLength={specifyMaxLength}					
            	/>
                : <label className="ps-3 dark:text-white">{specifyDisplayValue}</label>
                }
        	</FormItem>
			<div className={`${childrenClass ?? ''} ${showSpecify}`}>
				{children}
			</div>			
        </Space>
  );
}

export default SwitchWithSpecify;
