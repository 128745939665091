import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { connect } from 'react-redux';

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import ConsoleLayout from "layouts/console";
import PanelLayout from "layouts/panel";
import ErrorLayout from "layouts/error";
import { useLocation } from "react-router-dom";
import CrudHandler from "components/crudhandler";
import { ConfigProvider } from 'antd';
import { theme } from 'theme';

const App = ({auth}) => {

	const location = useLocation();
  	const targetLocation = location.pathname;

  return (
	<ConfigProvider theme={theme}>
		  <CrudHandler />
	      <Routes>
				{auth.isLoggedIn ? (
					<>
						<Route path="/auth/*" element={<Navigate to="/panel/services" />}/>
						<Route path="/auth/base/*" element={<Navigate to="/console/dashboard" />}/>
						<Route path="/auth/pad/*" element={<Navigate to="/console/dashboard" />}/>						
						<Route path="/" element={<Navigate to={targetLocation || "/panel"} replace />} />

						{ auth.user ? 
							(auth.user.type_id === 1 || auth.user.type_id === 2) ? 
								<>
								<Route path="console/*" element={<ConsoleLayout />} />
								<Route path="admin/*" element={<AdminLayout />} />		
								</>
								:
								<>
								<Route path="console/*" element={<Navigate to="/error/404" />} />
								<Route path="admin/*" element={<Navigate to="/error/404" />} />		
								</>
							:
							<></>
						}

						<Route path="rtl/*" element={<RtlLayout />} />
						<Route path="panel/*" element={<PanelLayout />} />
						<Route path="error/*" element={<ErrorLayout />} />
					</>
				) : (
					<>
						<Route path="auth/*" element={<AuthLayout />} />
						<Route path="/auth/base" element={<Navigate to="auth/base/sign-in" replace />} />
						<Route path="/auth/pad" element={<Navigate to="auth/pad/sign-in" replace />} />
						<Route path="/auth" element={<Navigate to="/auth/sign-in" replace />} />
						<Route path="/*" element={<Navigate to="/auth/home" replace />} />
					</>
				)}	
			</Routes>
	</ConfigProvider>
  );
};

const mapStateToProps = (state) => ({
	auth: {...state.auth},
});
  
const mapDispatchToProps = (dispatch) => ({

});
  
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
