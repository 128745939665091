import { takeLatest } from 'redux-saga/effects';
import {
  getAccessRightListSuccess, getAccessRightListFail,
  getAccessRightDetailsSuccess, getAccessRightDetailsFail
} from './accessRightState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/access-rights');
const redirectData = {route:'/console/access-rights', mode: 'list', recordId:0 };
const modelName = 'AccessRights';
const modelId = 'id';

function* getAccessRightList(data) {	
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getAccessRightListSuccess, 
		getAccessRightListFail);
}

function* getAccessRightDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getAccessRightDetailsSuccess, 
		getAccessRightDetailsFail);		
}

function* doAccessRightCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getAccessRightDetailsSuccess, 
		getAccessRightDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doAccessRightUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getAccessRightDetailsSuccess, 
		getAccessRightDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doAccessRightDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getAccessRightDetailsSuccess, 
		getAccessRightDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* accessRightSaga() {
  yield takeLatest('accessRight/getAccessRightList', getAccessRightList);
  yield takeLatest('accessRight/getAccessRightDetails', getAccessRightDetails);
  yield takeLatest('accessRight/doAccessRightCreate', doAccessRightCreate);
  yield takeLatest('accessRight/doAccessRightUpdate', doAccessRightUpdate);
  yield takeLatest('accessRight/doAccessRightDelete', doAccessRightDelete);  
}

export default accessRightSaga;
