import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import { SolutionOutlined } from '@ant-design/icons';
import UserRoles from "./index";
import ViewUserRole from "./view";
import EditUserRole from "./edit";

const userRolesRoutes = [	
	generateCRUDRoutes({
		mainTitle: "User Role",
		basePath: "user-roles",
		mainComponent: <UserRoles />,
	    layout: "/console",	   
	    icon: <SolutionOutlined className="h-6 w-6" />,
	    subTitle:"User Role",
	    viewComponent: <ViewUserRole />,
	    createComponent: <EditUserRole />,
	    editComponent: <EditUserRole />,
		isMainMenu: false,
	})
]

export default userRolesRoutes;