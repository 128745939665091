/* eslint-disable */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';

// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;
  const [openMenus, setOpenMenus] = useState({});
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  
  const toggleMenu = (routeName) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [routeName]: !prevState[routeName],
    }));
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/console" ||
        route.layout === "/rtl"
      ) {
		const subMenus = route.children ? route.children.filter(submenu => submenu.isMainMenu) : [];
        return (
			<div key={`${route.path}-${index}`}>				
				{ subMenus.length > 0 ?
				<div>
					<div
		              className="relative flex hover:cursor-pointer"
		              onClick={() => toggleMenu(route.name)}
		            >
		            {createLinkItem(route)}	
		            <div className="absolute right-2 top-px h-9 w-5 rounded-lg">
	                {openMenus[route.name] ? <CaretDownOutlined/> : <CaretRightOutlined/> }       
	                </div>
		           </div>
		          	{openMenus[route.name] && (
						<li>
			              <ul className="pl-4">
			                {createLinks(subMenus)}
			              </ul>
		              </li>
		            )}
	            </div>
	          : <Link key={`${route.path}-${index}`} to={route.layout + "/" + route.path}>	          
	            {createLinkItem(route)}
	          </Link>
			}
          </div>
        );
      }
      return null;
    });
  };
  
  const createLinkItem = (route) => {
	  return (<div className="relative mb-2 flex hover:cursor-pointer" key={route.id}>
		      <li className="my-[3px] flex cursor-pointer items-center px-8">
		        <span
		          className={`${
		            activeRoute(route.path) === true
		              ? "menu-icon active"
		              : "menu-icon"
		          }`}
		        >
		          {route.icon ? route.icon : <DashIcon />}{" "}
		        </span>
		        <p
		          className={`leading-1 ml-2 flex ${
		            activeRoute(route.path) === true
		              ? "menu-label active"
		              : "menu-label"
		          }`}
		        >
		          {route.name}
		        </p>
		      </li>
		      {activeRoute(route.path) ? (
		        <div className="absolute right-1 top-px h-9 w-1 rounded-lg bg-theme-600 dark:bg-theme-300" />
		      ) : null}
		    </div>
	    );
  }
  
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
