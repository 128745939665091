import { Input  } from 'antd';

const { TextArea } = Input;

function TextAreaField (props) {
  const { id, ...rest } = props;
  return (
    <TextArea id={id}
      {...rest}/>
  );
};

export default TextAreaField;