import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    accessRightList: {
      requesting: false,
      items: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      error: null,
      success: false,
      filters: {},
    },
    accessRightDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const accessRightSlice = createSlice({
  name: 'accessRight',
  initialState,
  reducers: {
    getAccessRightList: (state, action) => {		
        state.accessRightList.requesting = true;
        state.accessRightList.error = null;
        state.accessRightList.items = [];
        state.accessRightList.success = false;
        state.accessRightList.filters = action.payload;
    },
    getAccessRightListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.accessRightList.requesting = false;        
        state.accessRightList.items = data;
        state.accessRightList.success = true; 
        state.accessRightList.pagination.current_page = current_page;
        state.accessRightList.pagination.per_page = per_page;
        state.accessRightList.pagination.total = total;
    },
    getAccessRightListFail: (state, action) => {        
        state.accessRightList.requesting = false;
        state.accessRightList.error = action.payload;
    },
    getAccessRightDetails: (state) => {
        state.accessRightDetails.requesting = true;
        state.accessRightDetails.error = null;
        state.accessRightDetails.data = null;
        state.accessRightDetails.success = false; 
    },
    getAccessRightDetailsSuccess: (state, action) => {
        state.accessRightDetails.requesting = false;
        state.accessRightDetails.data = action.payload;
        state.accessRightDetails.success = true; 
    },
    getAccessRightDetailsFail: (state, action) => {        
        state.accessRightDetails.requesting = false;
        state.accessRightDetails.error = action.payload;
    },
    doAccessRightCreate: (state) => {
        state.accessRightDetails.requesting = true;
        state.accessRightDetails.error = null;
        state.accessRightDetails.data = null;
        state.accessRightDetails.success = false; 
    },
    doAccessRightUpdate: (state) => {
        state.accessRightDetails.requesting = true;
        state.accessRightDetails.error = null;
        state.accessRightDetails.data = null;
        state.accessRightDetails.success = false; 
    },
    doAccessRightDelete: (state) => {
        state.accessRightDetails.requesting = true;
        state.accessRightDetails.error = null;
        state.accessRightDetails.data = null;
        state.accessRightDetails.success = false; 
    },
  },
})

export const { 
    getAccessRightList, getAccessRightListSuccess, getAccessRightListFail,
    getAccessRightDetails, getAccessRightDetailsSuccess, getAccessRightDetailsFail,
    doAccessRightCreate, doAccessRightUpdate, doAccessRightDelete
} = accessRightSlice.actions

export default accessRightSlice.reducer