import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes.js";
import { API_NAME } from "../../constants";

export default function Console(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const handleResize = () => window.innerWidth < 1200 ? setOpen(false) : setOpen(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  const getActiveRoute = React.useCallback(() => {
    routes.forEach((route) => {
      if (`${route.layout}${route.path}` === location.pathname) {
        document.title = `${API_NAME} - ${route.name}`;
      } else if (route.children) {
        route.children.forEach((child) => {
          if (`${route.layout}${child.path}` === location.pathname) {
            document.title = `${API_NAME} - ${child.name}`;
          }
        });
      }
    });
  }, [location.pathname]);

  React.useEffect(() => {
    getActiveRoute();
  }, [location.pathname, getActiveRoute]);
  
  const getActiveNavbar = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return false;
  };
  const getRoutes = (routes) => {
  return routes
    .filter((route) => route.path !== "sign-in")
    .map((prop, index) => {
      if (prop.layout === "/console") {
        return (			
          <React.Fragment key={`${prop.path}-${index}`}>
            <Route path={prop.path.startsWith('/') ? prop.path : `/${prop.path}`} element={prop.component} />
            {Array.isArray(prop.children) && getRoutes(prop.children)}
          </React.Fragment>
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-intsamis-900">
        <main className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}>
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"DA Kiosk"}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="/console/dashboard" replace />}/>
              </Routes>
            </div>
            <div className="p-3">
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
