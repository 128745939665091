import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    userList: {
      requesting: false,
      items: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      error: null,
      success: false,
      filters: {},
    },
    userDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserList: (state, action) => {		
        state.userList.requesting = true;
        state.userList.error = null;
        state.userList.items = [];
        state.userList.success = false;
        state.userList.filters = action.payload;
    },
    getUserListSuccess: (state, action) => {
		  const { data, current_page, per_page, total} = action.payload;
        state.userList.requesting = false;        
        state.userList.items = data;
        state.userList.success = true; 
        state.userList.pagination.current_page = current_page;
        state.userList.pagination.per_page = per_page;
        state.userList.pagination.total = total;
    },
    getUserListFail: (state, action) => {        
        state.userList.requesting = false;
        state.userList.error = action.payload;
    },
    getUserDetails: (state) => {
        state.userDetails.requesting = true;
        state.userDetails.error = null;
        state.userDetails.data = null;
        state.userDetails.success = false; 
    },
    getUserDetailsSuccess: (state, action) => {
        state.userDetails.requesting = false;
        state.userDetails.data = action.payload;
        state.userDetails.success = true; 
    },
    getUserDetailsFail: (state, action) => {        
        state.userDetails.requesting = false;
        state.userDetails.error = action.payload;
    },
    doUserCreate: (state) => {
        state.userDetails.requesting = true;
        state.userDetails.error = null;
        state.userDetails.data = null;
        state.userDetails.success = false; 
    },
    doUserUpdate: (state) => {
        state.userDetails.requesting = true;
        state.userDetails.error = null;
        state.userDetails.data = null;
        state.userDetails.success = false; 
    },
    doUserDelete: (state) => {
        state.userDetails.requesting = true;
        state.userDetails.error = null;
        state.userDetails.data = null;
        state.userDetails.success = false; 
    },
  },
})

export const { 
    getUserList, getUserListSuccess, getUserListFail,
    getUserDetails, getUserDetailsSuccess, getUserDetailsFail,
    doUserCreate, doUserUpdate, doUserDelete
} = userSlice.actions

export default userSlice.reducer