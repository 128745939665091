import React, { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

function SelectSearchField ({ fetchOptions, debounceTimeout = 800, options, fetching, ...props }) {
  const [searchTerm, setSearchTerm] = useState('');
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setSearchTerm(value);
      fetchOptions(value);
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

export default SelectSearchField;