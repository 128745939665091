import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import PersonalInfoFields from "../../../console/farmer-information/components/PersonalInfoFields";
import { 
	getMyUserDetailsDetails, doMyUserDetailsUpdate 
} from "../../../../redux/userdetails/userDetailsState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";
import dayjs from 'dayjs';
import { DATE_FORMAT } from "../../../../variables/constants";

const MyPersonalInfoForm = ({getRecord, doUpdate, recordData, requesting, success, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {
		getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { date_of_birth, ...restData } = recordData;
			const initialValues = {
				...restData,
				date_of_birth: date_of_birth ? dayjs(date_of_birth, DATE_FORMAT) : null,
				section: 'Personal Information',				
			};
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		try {  
			formData.redirect = false;
			doUpdate(formData);
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<PersonalInfoFields recordData={recordData} mode={mode}/>

				<CrudButtons baseRoute="/panel/my-farmer-information" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/services"
					cancelUrl="/panel/services"
					cancelLabel="Back"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.userDetails.details?.data,
	requesting: state.userDetails.details?.requesting,
	success: state.userDetails.details?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyUserDetailsDetails(id)),
	doUpdate: (data) => dispatch(doMyUserDetailsUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPersonalInfoForm);