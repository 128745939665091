import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Input, Select, InputNumber } from 'antd';
import FormItem from "components/fields/FormItem";
import SwitchField from "components/fields/SwitchField";
import SelectWithOthers from "components/fields/SelectWithOthers";
import TextAreaField from "components/fields/TextAreaField";
import { requiredField } from '../../../../utils/crudSagaHelper';
import { 
	TENANT_OWNERSHIP_TYPE, LEASE_OWNERSHIP_TYPE, OTHER_OWNERSHIP_TYPE, OTHER_OWNERSHIP_DOC 
	} from '../../../../variables/constants';

const FarmParcelFields = ({recordData, mode, form, ...props}) => {
  
  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<FormItem name="user_details_id" hidden>
				<Input type="hidden"/>
			</FormItem>
			
			<div className="form-column-4">				
				<FormItem extra="col-span-2"
					name="location_barangay"
					label="Farm Location"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="location_barangay"
						maxLength={50}
						placeholder="Barangay"
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.location_barangay}</label>
					}                
				</FormItem>
				<FormItem extra="col-span-2"
					name="location_municipality"
					label="City/Municipality"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="location_municipality"
						maxLength={50}	
						placeholder="City/Municipality"	            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.location_municipality}</label>
					}                
				</FormItem>
				<FormItem extra="col-span-2"
					name="name"
					label="Farm Name"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="name"
						maxLength={100}	
						placeholder="e.g. Little Farm, Backyard"
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.name}</label>
					}  
				</FormItem>	
				<FormItem 
					name="total_farm_area"
					label="Total Farm Area (in hectares)"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<InputNumber id="total_farm_area" min="0" addonAfter="ha" style={{ width: '120px' }} precision="2"/>
					: <label className="ps-3 dark:text-white">{recordData?.total_farm_area}</label>
					}
				</FormItem>
				<FormItem
					name="farm_type_id"
					label="Farm Type"
				>
					{mode === 'edit' ?
					<Select id="farm_type_id"
						allowClear={true}
						options={recordData?.farmTypeOptions}
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.farm_type?.name}</label>
					} 
				</FormItem>
				<FormItem
					name="ownership_document_id"
					label="Ownership Document"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?					
					<SelectWithOthers 
						mode={mode}
						selectFieldName="ownership_document_id" 
						selectOptions={recordData?.ownershipDocumentOptions} 
						selectDisplayValue={recordData?.ownership_document_id}
						othersFieldName="ownership_document_others"
						othersDisplayValue={recordData?.ownership_document_others}
						optionOthersId={[OTHER_OWNERSHIP_DOC]}
						direction="vertical"
						othersStyle={{maxLength: 100}}	
						othersPlaceholder="e.g. Barangay Certification"					
					/>
					: <label className="ps-3 dark:text-white">{recordData?.ownership_document?.name}</label>
					}             
				</FormItem>
				<FormItem 
					name="ownership_document_no"
					label="Ownership Document No"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="ownership_document_no"
						maxLength={100}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.ownership_document_no}</label>
					}  					
				</FormItem>					
				<FormItem
					name="ownership_type_id"
					label="Ownership Type"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?					
					<SelectWithOthers 
						mode={mode}
						selectFieldName="ownership_type_id" 
						selectOptions={recordData?.ownershipTypeOptions} 
						selectDisplayValue={recordData?.ownership_type_id}
						othersFieldName="ownership_type_others"
						othersDisplayValue={recordData?.ownership_type_others}
						optionOthersId={[TENANT_OWNERSHIP_TYPE, LEASE_OWNERSHIP_TYPE, OTHER_OWNERSHIP_TYPE]}
						direction="vertical"
						othersStyle={{maxLength: 100}}
						othersPlaceholders={{
							[TENANT_OWNERSHIP_TYPE]: 'Name of Land Owner',
							[LEASE_OWNERSHIP_TYPE]: 'Name of Land Owner',
						}}
					/>
					: <label className="ps-3 dark:text-white">{recordData?.ownership_type?.name}</label>
					}             
				</FormItem>
			</div>
			<div className="form-column-4">
				<FormItem 
					name="within_ancestral_domain"
					label="Within Ancestral Domain"
				>
					{mode === 'edit' ?
					<SwitchField id="within_ancestral_domain" type="text"/>					
					: <label className="ps-3 dark:text-white">{recordData?.within_ancestral_domain == 1 ? 'Yes' : 'No'}</label>
					}	                
				</FormItem>
				<FormItem 
					name="agrarian_reform_beneficiary"
					label="Agrarian Reform Beneficiary"
				>
					{mode === 'edit' ?
					<SwitchField id="agrarian_reform_beneficiary" type="text"/>					
					: <label className="ps-3 dark:text-white">{recordData?.agrarian_reform_beneficiary == 1 ? 'Yes' : 'No'}</label>
					}	                
				</FormItem>	
												
				<FormItem  extra="col-span-4"
					name="remarks"
					label="Remarks"
				>
					{mode === 'edit' ?
					<TextAreaField id="remarks"
						maxLength={1000}
					/>	
					: <label className="ps-3 dark:text-white">{recordData?.remarks}</label>
					}	
				</FormItem>						
			</div>
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmParcelFields);
