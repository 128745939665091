import { takeLatest } from 'redux-saga/effects';
import {
  getParameterListSuccess, getParameterListFail,
  getParameterDetailsSuccess, getParameterDetailsFail
} from './parameterState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/parameters');
const redirectData = {route:'/console/parameters', mode: 'list', recordId:0 };
const modelName = 'Parameters';
const modelId = 'id';

function* getParameterList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getParameterListSuccess, 
		getParameterListFail);
}

function* getParameterDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getParameterDetailsSuccess, 
		getParameterDetailsFail);		
}

function* doParameterCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getParameterDetailsSuccess, 
		getParameterDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doParameterUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getParameterDetailsSuccess, 
		getParameterDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doParameterDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getParameterDetailsSuccess, 
		getParameterDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* parameterSaga() {
  yield takeLatest('parameter/getParameterList', getParameterList);
  yield takeLatest('parameter/getParameterDetails', getParameterDetails);
  yield takeLatest('parameter/doParameterCreate', doParameterCreate);
  yield takeLatest('parameter/doParameterUpdate', doParameterUpdate);
  yield takeLatest('parameter/doParameterDelete', doParameterDelete);  
}

export default parameterSaga;
