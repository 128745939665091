import React from "react";
import { connect } from "react-redux";
import { logoutRequest } from "../../../redux/auth/authState";
import Button from "components/button";

const PanelLogout = ({logout, ...props}) => {

  const handleLogout = (e) => {
	  e.preventDefault();
	  logout();
  };

  return (
    <div className="flex h-full w-full bg-lightPrimary dark:!bg-intsamis-900 justify-center px-2 pt-4">
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelLogout);
