import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getRoleList, doRoleDelete } from "../../../../redux/role/roleState";
import ColumnSearchFilter from 'components/search/ColumnSearchFilter';
import SearchFilter from "components/search/SearchFilter";
import { handleTableChange } from "../../../../utils/index";

const RoleTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
	const { getColumnSearchProps } = ColumnSearchFilter();
  	const columns = [
		{
	        title: `Name`,
	        dataIndex: 'name',
	        key: 'name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('rolename'),
	        render:(name) => <span>{name}</span>
	    },	    
		{
	        title: `Level`,
	        dataIndex: 'level',
	        key: 'level',
	        width: '10%',
	        render:(level) => <span>{level}</span>
	    },
		{
	        title: `Description`,
	        dataIndex: 'description',
	        key: 'description',
	        width: '40%',
	        render:(description) => <span>{description}</span>
	    },	    	
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/console/roles/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '10%',
	        render:(id) => <CrudOption baseRoute="/console/roles" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">
	      		
	      		<SearchFilter placeholder="Search by Role Name" searchHandler={doSearch} filters={filters} pagination={pagination}/>
	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.role.roleList?.items,
	pagination: state.role.roleList?.pagination,
	filters: state.role.roleList?.filters,
  	requesting: state.role.roleList?.requesting,
  	error: state.role.roleList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getRoleList(filters)),
  doDelete: (id) => dispatch(doRoleDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleTable);