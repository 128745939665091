import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordId: null,
    route: '',
    mode: 'list',    
  };

export const crudSlice = createSlice({
  name: 'crud',
  initialState,
  reducers: {
	setCrudRoute: (state, action) => {
		state.recordId = action.payload?.recordId;
		state.mode = action.payload?.mode;
    state.route = action.payload?.route;
	},    
  },
})

export const { 
	setCrudRoute    
} = crudSlice.actions

export default crudSlice.reducer