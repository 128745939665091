import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordList: {
      requesting: false,
      items: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      error: null,
      success: false,
      filters: {},
    },
    details: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const farmAnimalSlice = createSlice({
  name: 'farmAnimal',
  initialState,
  reducers: {
    getFarmAnimalList: (state, action) => {		
        state.recordList.requesting = true;
        state.recordList.error = null;
        state.recordList.items = [];
        state.recordList.success = false;
        state.recordList.filters = action.payload;
    },
    getFarmAnimalListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.recordList.requesting = false;        
        state.recordList.items = data;
        state.recordList.success = true; 
        state.recordList.pagination.current_page = current_page;
        state.recordList.pagination.per_page = per_page;
        state.recordList.pagination.total = total;
    },
    getFarmAnimalListFail: (state, action) => {        
        state.recordList.requesting = false;
        state.recordList.error = action.payload;
    },
    getFarmAnimalDetails: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getFarmAnimalDetailsSuccess: (state, action) => {
        state.details.requesting = false;
        state.details.data = action.payload;
        state.details.success = true; 
    },
    getFarmAnimalDetailsFail: (state, action) => {        
        state.details.requesting = false;
        state.details.error = action.payload;
    },
    doFarmAnimalCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    doFarmAnimalUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    doFarmAnimalDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getMyFarmAnimalList: (state, action) => {		
      state.recordList.requesting = true;
      state.recordList.error = null;
      state.recordList.items = [];
      state.recordList.success = false;
      state.recordList.filters = action.payload;
    },
    getMyFarmAnimalDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.data = null;
      state.details.success = false; 
    },
    doMyFarmAnimalCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    doMyFarmAnimalUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    doMyFarmAnimalDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
  },
})

export const { 
    getFarmAnimalList, getFarmAnimalListSuccess, getFarmAnimalListFail,
    getFarmAnimalDetails, getFarmAnimalDetailsSuccess, getFarmAnimalDetailsFail,
    doFarmAnimalCreate, doFarmAnimalUpdate, doFarmAnimalDelete,
    getMyFarmAnimalList, getMyFarmAnimalDetails,
    doMyFarmAnimalCreate, doMyFarmAnimalUpdate, doMyFarmAnimalDelete,
} = farmAnimalSlice.actions

export default farmAnimalSlice.reducer