import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import Button from "components/button";
import { connect } from "react-redux";
import APIService from '../../services/apiservice';
import { API_URL } from '../../constants';
import { showLoading, hideLoading } from '../../redux/layout/layoutState';
import { message } from 'antd';

function DownloadButton({downloadPath, showImporting, hideImporting, filters, title='Export Data', ...props}) {
	
	const handleDownload = async () => {
		try {
			showImporting('Downloading...');
			const { paginate , ...restFilters } = filters || {};
			const {filePath, fileName, info_message} = await APIService.doExportRequest(`api/v1/export/${downloadPath}`, restFilters);
			
			if(info_message){
				message.success(info_message);
			}else{
				const response = await fetch(`${API_URL}${filePath}`);
	        
		        if (!response.ok) {
		            throw new Error(`Failed to fetch file: ${response.statusText}`);
		        }
		        
		        const blob = await response.blob();
		        
				var downloadLink = document.createElement('a');
				    downloadLink.href = window.URL.createObjectURL(blob);
				    downloadLink.download = fileName;
				    document.body.appendChild(downloadLink);
				    downloadLink.click();
				    document.body.removeChild(downloadLink);
			}
			

		} catch (error) {
	        console.error('Export failed:', error);
	    } finally {
	        hideImporting();
	    }	
	}
	
    return (
		<div className="">
        	<Button className="btn-secondary me-3" onClick={handleDownload}>
      			<DownloadOutlined className='me-1'/>{title}
    		</Button>                    
        </div>
    );
}

const mapStateToProps = () => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  showImporting: (message) => dispatch(showLoading(message)),
  hideImporting: () => dispatch(hideLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);



