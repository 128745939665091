import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    recordList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    details: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    getUserDetailsList: (state, action) => {		
        state.recordList.requesting = true;
        state.recordList.error = null;
        state.recordList.items = [];
        state.recordList.success = false;
        state.recordList.filters = action.payload;
    },
    getUserDetailsListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.recordList.requesting = false;        
        state.recordList.items = data;
        state.recordList.success = true; 
        state.recordList.pagination.current_page = current_page;
        state.recordList.pagination.per_page = per_page;
        state.recordList.pagination.total = total;
    },
    getUserDetailsListFail: (state, action) => {        
        state.recordList.requesting = false;
        state.recordList.error = action.payload;
    },
    getUserDetailsDetails: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },
    getUserDetailsDetailsSuccess: (state, action) => {
        state.details.requesting = false;
        state.details.data = action.payload;
        state.details.success = true; 
    },
    getUserDetailsDetailsFail: (state, action) => {        
        state.details.requesting = false;
        state.details.error = action.payload;
    },
    doUserDetailsCreate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doUserDetailsUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doUserDetailsDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },  
    getMyUserDetailsDetails: (state) => {
      state.details.requesting = true;
      state.details.error = null;
      state.details.success = false; 
    },       
    doMyUserDetailsUpdate: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.success = false; 
    },
    doMyUserDetailsDelete: (state) => {
        state.details.requesting = true;
        state.details.error = null;
        state.details.data = null;
        state.details.success = false; 
    },     
  },
})

export const { 
    getUserDetailsList, getUserDetailsListSuccess, getUserDetailsListFail,
    getUserDetailsDetails, getUserDetailsDetailsSuccess, getUserDetailsDetailsFail,
    doUserDetailsCreate, doUserDetailsUpdate, doUserDetailsDelete,
    getMyUserDetailsDetails, doMyUserDetailsUpdate, doMyUserDetailsDelete
} = userDetailsSlice.actions

export default userDetailsSlice.reducer