import React  from "react";
import { useParams } from 'react-router-dom';
import MyFarmParcelForm from "./components/MyFarmParcelForm";

const EditMyFarmParcel = () => {
	const { myfarmparcelId } = useParams();

	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
			<MyFarmParcelForm recordId={myfarmparcelId} mode="edit"/>
	      </div>
	    </div>
    );
};

export default EditMyFarmParcel;