import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getUserList, doUserDelete } from "../../../../redux/user/userState";
import ColumnSearchFilter from 'components/search/ColumnSearchFilter';
import SearchFilter from "components/search/SearchFilter";
import { handleTableChange } from "../../../../utils/index";

const UserTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
	const { getColumnSearchProps } = ColumnSearchFilter();
  	const columns = [
		{
	        title: `Username`,
	        dataIndex: 'username',
	        key: 'username',
	        width: '12%',
	        sorter: true,
	        ...getColumnSearchProps('username'),
	        render:(username) => <span>{username}</span> 	       
	    },
	    {
	        title: `First Name`,
	        dataIndex: 'user_details',
	        key: 'first_name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('first_name'),
	        render:(user_details) => <span>{user_details?.first_name}</span> 	       
	    },
		{
	        title: `Middle Name`,
	        dataIndex: 'user_details',
	        key: 'middle_name',
	        width: '20%',
	        render:(user_details) => <span>{user_details?.middle_name}</span> 	       
	    },
	    {
	        title: `Last Name`,
	        dataIndex: 'user_details',
	        key: 'last_name',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('last_name'),
	        render:(user_details) => <span>{user_details?.last_name}</span> 	       
	    },
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/console/users/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '10%',
	        render:(id) => <CrudOption baseRoute="/console/users" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">
	      		
	      		<SearchFilter placeholder="Search by Username or Name" searchHandler={doSearch} filters={filters} pagination={pagination}/>
	      	
	      		<Table
		        	dataSource={recordList} 
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}    
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.user.userList?.items,
	pagination: state.user.userList?.pagination,
	filters: state.user.userList?.filters,
  	requesting: state.user.userList?.requesting,
  	error: state.user.userList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getUserList(filters)),
  doDelete: (id) => dispatch(doUserDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);