import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import FarmParcelFields from "../../../console/farmer-information/components/FarmParcelFields";
import { 
	getMyFarmParcelDetails, doMyFarmParcelCreate, doMyFarmParcelUpdate 
} from "../../../../redux/farmparcel/farmParcelState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";

const MyFarmParcelForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {		
		getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
			const initialValues = {
				...restData
			};
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		
		try {  			
			formData.within_ancestral_domain = formData.within_ancestral_domain === false ? 0 : (formData.within_ancestral_domain === true ? 1 : 0);
			formData.agrarian_reform_beneficiary = formData.agrarian_reform_beneficiary === false ? 0 : (formData.agrarian_reform_beneficiary === true ? 1 : 0);
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<FarmParcelFields recordData={recordData} mode={mode} form={form}/>

				<CrudButtons baseRoute="/panel/my-farm-parcels" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/my-farmer-information#farm_parcels"
					cancelUrl="/panel/my-farmer-information#farm_parcels"
					cancelLabel="Back"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.farmParcel.details?.data,
	requesting: state.farmParcel.details?.requesting,
	success: state.farmParcel.details?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyFarmParcelDetails(id)),
	doCreate: (data) => dispatch(doMyFarmParcelCreate(data)),
	doUpdate: (data) => dispatch(doMyFarmParcelUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmParcelForm);