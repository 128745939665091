import { combineReducers } from 'redux';
import layoutReducer from './layout/layoutState';
import modalReducer from './modal/modalState';
import authReducer from './auth/authState';
import crudReducer from './crud/crudState';
import chatReducer from './chat/chatState';
import moduleReducer from './module/moduleState';
import userReducer from './user/userState';
import accessRightReducer from './accessright/accessRightState';
import parameterReducer from './parameter/parameterState';
import roleReducer from './role/roleState';
import userRoleReducer from './userrole/userRoleState';
import userDetailsReducer from './userdetails/userDetailsState';
import farmerInfoReducer from './farmerinfo/farmerInfoState';
import farmProfileReducer from './farmprofile/farmProfileState';
import farmParcelReducer from './farmparcel/farmParcelState';
import farmAnimalReducer from './farmanimal/farmAnimalState';
import psgcDataReducer from './psgcdata/psgcDataState';

const rootReducer = combineReducers({
  layout: layoutReducer,
  modal: modalReducer,
  auth: authReducer,
  crud: crudReducer,
  chat: chatReducer,
  module: moduleReducer,
  user: userReducer, 
  accessRight: accessRightReducer, 
  parameter: parameterReducer,
  role: roleReducer,
  userRole: userRoleReducer,
  farmerInfo: farmerInfoReducer,
  farmProfile: farmProfileReducer,
  userDetails: userDetailsReducer,
  farmParcel: farmParcelReducer,
  farmAnimal: farmAnimalReducer,
  psgcData: psgcDataReducer
});

export default rootReducer;


