import React, { useEffect } from "react";
import { Table } from 'antd';
import Card from "components/card";
import Button from "components/button";
import CrudOption from "components/dropdown/crudoption";
import { PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { getAccessRightList, doAccessRightDelete } from "../../../../redux/accessright/accessRightState";
import ColumnSearchFilter from 'components/search/ColumnSearchFilter';
import SearchFilter from "components/search/SearchFilter";
import { handleTableChange } from "../../../../utils/index";

const AccessRightTable = ({ recordList, pagination, doSearch, doDelete, filters }) => {
	const { getColumnSearchProps } = ColumnSearchFilter();
  	const columns = [
		{
	        title: `Role`,
	        dataIndex: 'role',
	        key: 'role',
	        width: '20%',
	        sorter: true,
	        ...getColumnSearchProps('role.name'),
	        render:(role) => <span>{role?.name}</span>
	    },
	    {
	        title: `Module`,
	        dataIndex: 'module',
	        key: 'module',
	        width: '20%',
			...getColumnSearchProps('module.name'),
	        render:(module) => <span>{module?.name}</span>
	    },
		{
	        title: `Can View`,
	        dataIndex: 'can_view',
	        key: 'can_view',
	        width: '10%',
	        render:(can_view) => <span>{can_view === 1 ? 'Y' : 'N'}</span>
	    },
		{
	        title: `Can Edit`,
	        dataIndex: 'can_edit',
	        key: 'can_edit',
	        width: '10%',
	        render:(can_edit) => <span>{can_edit === 1 ? 'Y' : 'N'}</span>
	    },
	    {
	        title: `Can Delete`,
	        dataIndex: 'can_delete',
	        key: 'can_delete',
	        width: '10%',
	        render:(can_delete) => <span>{can_delete === 1 ? 'Y' : 'N'}</span>
	    },
		{
	        title: `Can Extract`,
	        dataIndex: 'can_extract',
	        key: 'can_extract',
	        width: '10%',
	        render:(can_extract) => <span>{can_extract === 1 ? 'Y' : 'N'}</span>
	    },		
	    {
	        title: <Button extra="btn-small">
			      		<a href={`/console/access-rights/add`}>
					        <PlusOutlined />{' '}Add
					  	</a>
			    	</Button>,
	        dataIndex: 'id',
	        key: 'id',
	        width: '10%',
	        render:(id) => <CrudOption baseRoute="/console/access-rights" recordId={id} deleteHandler={doDelete}/>
	    },
    ];
	
	useEffect(() => {
	    doSearch({paginate: pagination.per_page});
    }, [doSearch, pagination.per_page]);

  	return (
	    <Card extra={"w-full h-full p-4"}>
	      	<div className="h-full overflow-x-scroll xl:overflow-x-hidden">
	      		
	      		<SearchFilter placeholder="Search by Access Right Group Name" searchHandler={doSearch} filters={filters} pagination={pagination}/>
	      	
	      		<Table
		        	dataSource={recordList}
		        	columns={columns} 
		            rowKey={(record) => record.id}
		        	pagination={{...pagination, showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} records`,}}
		            onChange={handleTableChange(doSearch, filters)}  
	        	/>
	      	</div>
	    </Card>
  );
};

const mapStateToProps = (state) => ({
	recordList: state.accessRight.accessRightList?.items,
	pagination: state.accessRight.accessRightList?.pagination,
	filters: state.accessRight.accessRightList?.filters,
  	requesting: state.accessRight.accessRightList?.requesting,
  	error: state.accessRight.accessRightList?.error,
  
});

const mapDispatchToProps = (dispatch) => ({
  doSearch: (filters) => dispatch(getAccessRightList(filters)),
  doDelete: (id) => dispatch(doAccessRightDelete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRightTable);