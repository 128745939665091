import React, { useEffect } from 'react';
import Card from 'components/card';
import { Form, Input, Select } from 'antd';
import FormItem from 'components/fields/FormItem';
import { connect } from 'react-redux';
import { 
	getUserRoleDetails, doUserRoleCreate, doUserRoleUpdate 
} from '../../../../redux/userrole/userRoleState';
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from 'components/button/crudbuttons';
import { requiredField } from '../../../../utils/crudSagaHelper';
import { getAddUserList } from '../../../../redux/role/roleState';
import SelectSearchField from 'components/fields/SelectSearchField';


const RoleUserForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, addUserList, requestingUserList, doAddUserSearch, ...props}) => {
	
  	const { recordId, mode, roleId } = props;  	
  	const [form] = useForm();
  	
  	useEffect(() => {
        getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
	        const initialValues = {
	            ...restData,
				role_id: +roleId,
	        };
			
	        form.setFieldsValue(initialValues);
        }
    }, [recordData, form]);
    
  	const onSubmit = (formData) => {	
    	try {  
			doCreate(formData); 
    	} catch (error) {
      		console.error('Save failed:', error);
    	}
  	};

	const onSearchHandler = (value) =>{
		doAddUserSearch({excludeRoleId: roleId, username:value, asOptions: 1});
	}
  
  	return (
    	<Card extra={"w-full h-full p-4"}>
		  	<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
	        	<FormItem
			        name="id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>
				<FormItem
			        name="role_id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>				
				<FormItem 
					name="user_id"
					label="User"
					extra="col-span-2"
					rules={[requiredField()]}
				>
					<SelectSearchField
						showSearch
						allowClear={true}
						options={addUserList}
						fetchOptions={onSearchHandler}
						fetching={requestingUserList}/>
				</FormItem>								
	 			<CrudButtons baseRoute={`/console/roles/${roleId}/user-roles`} 
					mode={mode} 
					recordId={recordId}
					submitLabel="Add"
					isModal={true}/> 				 			
	 		</Form>	 	
	 	</Card>
  	);
};

const mapStateToProps = (state) => ({
  	recordData: state.userRole.userRoleDetails?.data,
  	requesting: state.userRole.userRoleDetails?.requesting,
  	success: state.userRole.userRoleDetails?.succcess,
	addUserList: state.role.userList?.items,
	requestingUserList: state.role.userList?.requesting,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getUserRoleDetails(id)),
  	doCreate: (data) => dispatch(doUserRoleCreate(data)),
  	doUpdate: (data) => dispatch(doUserRoleUpdate(data)),
	doAddUserSearch: (filters) => dispatch(getAddUserList(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleUserForm);
