import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import MyFarmParcels from "./index";
import ViewMyFarmParcel from "./view";
import EditMyFarmParcel from "./edit";

const myFarmParcelRoutes = [	
	generateCRUDRoutes({
		mainTitle: "My Farm Parcels",
		basePath: "my-farm-parcels",
		mainComponent: <MyFarmParcels />,
	    layout: "/panel",	   
	    subTitle:"Farm Parcel",
	    viewComponent: <ViewMyFarmParcel />,
	    createComponent: <EditMyFarmParcel />,
	    editComponent: <EditMyFarmParcel />,
	})
]

export default myFarmParcelRoutes;