import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Form, Input, DatePicker, Space, Select } from 'antd';
import dayjs from 'dayjs';
import FormItem from "components/fields/FormItem";
import { connect } from "react-redux";
import { 
	getUserDetails, doUserCreate, doUserUpdate 
} from "../../../../redux/user/userState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";
import { requiredField } from '../../../../utils/crudSagaHelper';

const dateFormat = 'YYYY-MM-DD';

const UserForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, errors, ...props}) => {
	
  	const { recordId, mode } = props;  	
  	const [form] = useForm();
  	
  	useEffect(() => {
        getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			
			const { user_details, type, ...restData } = recordData;
	        const initialValues = {
	            ...restData,
				...user_details,
				...type,
				date_of_birth: user_details?.date_of_birth ? dayjs(user_details?.date_of_birth, dateFormat) : null,
	        };

	        form.setFieldsValue(initialValues);
        }
    }, [recordData, form]);
    
  	const onSubmit = (formData) => {	
    	try {  
			formData.date_of_birth = formData.date_of_birth?.format('YYYY-MM-DD');
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
    	} catch (error) {
      		console.error('Save failed:', error);
    	}
  	};
  
  	return (
    	<Card extra={"w-full h-full p-4"}>
		  	<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
	        	<FormItem
			        name="id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>
        		<div className="grid grid-cols-4">         
		            <FormItem 
		            	name="username"
		                label="Username"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Input id="username"
		            		maxLength={100}	
							readOnly={true}	  
							disabled={recordData?.type_id == 3}          		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.username}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
						name="type_id"
						label="Type"
						rules={[requiredField()]}
					>
						{mode === 'edit' ?
						<Select id="parent_id"
							showSearch
							allowClear={true}		           
							options={recordData?.roleOptions}
						/>													
						: <label className="ps-3 dark:text-white">{recordData?.type?.name}</label>
		            	}
					</FormItem>
	            	<FormItem extra="col-span-2"
		            	name="full_name"
		                label="Full Name"
		                required 
		                dependencies={['first_name', 'last_name']}
		                rules={[
				        ({ getFieldValue }) => ({
				            validator(_, value) {
				                const firstName = getFieldValue('first_name');
				                const lastName = getFieldValue('last_name');
				
				                if (!firstName || !lastName) {
				                    return Promise.reject(new Error('First Name and Last Name are required'));
				                }
				                return Promise.resolve();
				            },
				        }),
				    	]}
		            >
		            	{mode === 'edit' ?
			            <Space.Compact>
							<FormItem 
				            	name="first_name"				            	
				                noStyle 
				            >
				            	<Input id="first_name"
				            		placeholder="First Name"
				            		maxLength={100}
				            		style={{
								        width: 150,
								      }}
				            	/>
				        	</FormItem>
							<FormItem 
				            	name="middle_name"				            	
				                noStyle 
				            >
				            	<Input id="middle_name"
				            		placeholder="Middle Name"
				            		maxLength={100}
				            		style={{
								        width: 150,
								      }}
				            	/>
				        	</FormItem>
			            	<FormItem 
				            	name="last_name"
				                noStyle				                
				            >
				            	<Input id="last_name"
				            		placeholder="Last Name"
				            		maxLength={100}
				            		style={{
								        width: 150,
								      }}
				            	/>
				        	</FormItem>
			            	
				        </Space.Compact>
		            	: <label className="ps-3 dark:text-white">{recordData?.user_details?.first_name} {recordData?.user_details?.middle_name} {recordData?.user_details?.last_name}</label>
		            	}	                
	            	</FormItem>
	 			</div>
				{mode === 'edit' &&
				<>
					<div className="my-5">
						<label className="form-section-label">CHANGE PASSWORD</label>
					</div>				
					<div className="grid grid-cols-4">				
						<FormItem 
							name="password"
							label="New Password"
							rules={[
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.resolve();
										}
										const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W_]{8,}$/;
										if (!regex.test(value)) {
											return Promise.reject(new Error("Password must be of 8 characters with at least one uppercase, one lowercase, and a number"));
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input id="password"
								type="password"
								maxLength={100}
							/>						
						</FormItem>
						<FormItem 
							name="password_confirmation"
							label="Confirm Password"
							rules={[
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value) {
											return Promise.resolve();
										}									
										const password = getFieldValue('password');					
										if (value !== password) {
											return Promise.reject(new Error(`Confirm password doesn't match`));
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input id="password_confirmation"
								type="password"
								maxLength={100}
							/>
						</FormItem>	
					</div>
				</>
				}
	 			<CrudButtons baseRoute="/console/users" mode={mode} recordId={recordId} /> 				 			
	 		</Form>	 	
	 	</Card>
  	);
};

const mapStateToProps = (state) => ({
  	recordData: state.user.userDetails?.data,
  	requesting: state.user.userDetails?.requesting,
  	success: state.user.userDetails?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getUserDetails(id)),
  	doCreate: (data) => dispatch(doUserCreate(data)),
  	doUpdate: (data) => dispatch(doUserUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
