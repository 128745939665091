import { Image } from 'antd';
import da_livestock from "../../assets/img/da_livestocks.jpg";

const PanelBanner = (props) => {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
      className={`overflow-hidden max-h-[96px] max-w-[400px] my-5 !z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-intsamis-800 dark:text-white dark:shadow-none ${extra}`}
      {...rest}
    >
      <Image width="100%" src={da_livestock}/>
    </div>
  );
}

export default PanelBanner;
