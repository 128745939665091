import React from 'react';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Button from "components/button";
import { connect } from "react-redux";
import APIService from '../../services/apiservice';
import { showLoading, hideLoading } from '../../redux/layout/layoutState';
import { message } from 'antd';

function UploadButton({uploadPath, showImporting, hideImporting, title='Import Data',...props}) {
	
	const handleUpload = async (file) => {
		showImporting('Uploading...');
		await APIService.doUploadRequest(`api/v1/import/${uploadPath}`, file);
		hideImporting();
		message.success(`${title} complete`);
			setTimeout(() => {
	        window.location.reload();
	    }, 3000);
		return false;
	}
	
    return (
		<div>
	        <Upload
	            name='file'
	            maxCount={1}
	            beforeUpload={handleUpload}
	            {...props}
	        >
	        	<Button className="btn-secondary me-3">
	      			<UploadOutlined className='me-1' />{title}
	    		</Button>                    
	        </Upload>	        
        </div>
    );
}

const mapStateToProps = () => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  showImporting: (message) => dispatch(showLoading(message)),
  hideImporting: () => dispatch(hideLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadButton);