import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import { ProductOutlined } from '@ant-design/icons';
import Modules from "./index";
import ViewModule from "./view";
import EditModule from "./edit";

const moduleRoutes = [	
	generateCRUDRoutes({
		mainTitle: "Modules",
		basePath: "modules",
		mainComponent: <Modules />,
	    layout: "/console",	   
	    icon: <ProductOutlined className="h-6 w-6" />,
	    subTitle:"Module",
	    viewComponent: <ViewModule />,
	    createComponent: <EditModule />,
	    editComponent: <EditModule />,
	})
]

export default moduleRoutes;