import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    regions: {
      requesting: false,
      items: [],
      error: null,
      success: false,
      filters: {},
    },
    provinces: {
      requesting: false,
      items: [],
      error: null,
      success: false,
      filters: {},
    },
    municipalities: {
      requesting: false,
      items: [],
      error: null,
      success: false,
      filters: {},
    },
    barangays: {
      requesting: false,
      items: [],
      error: null,
      success: false,
      filters: {},
    },
  };

export const psgcDataSlice = createSlice({
  name: 'psgcData',
  initialState,
  reducers: {
    getRegions: (state, action) => {		      
        state.regions.requesting = true;
        state.regions.error = null;
        state.regions.items = [];
        state.regions.success = false;
        state.regions.filters = action.payload;
    },
    getRegionsSuccess: (state, action) => {
      const results = action.payload;
      state.regions.requesting = false;
      state.regions.items = results.map((item) => ({
        label: item.name, 
        value: item.code,
        ...item
      })).sort((a, b) => a.label.localeCompare(b.label));
      state.regions.success = true; 
    },
    getRegionsFail: (state, action) => {        
        state.regions.requesting = false;
        state.regions.error = action.payload;
    },  
    
    getProvinces: (state, action) => {		
      state.provinces.requesting = true;
      state.provinces.error = null;
      state.provinces.items = [];
      state.provinces.success = false;
      state.provinces.filters = action.payload;
    },
    getProvincesSuccess: (state, action) => {
      const results = action.payload;
      state.provinces.requesting = false;        
      state.provinces.items = results.map((item) => ({
        label: item.name, 
        value: item.code,
        ...item
      })).sort((a, b) => a.label.localeCompare(b.label));
      state.provinces.success = true; 
    },
    getProvincesFail: (state, action) => {        
        state.provinces.requesting = false;
        state.provinces.error = action.payload;
    },

    getMunicipalities: (state, action) => {		
      state.municipalities.requesting = true;
      state.municipalities.error = null;
      state.municipalities.items = [];
      state.municipalities.success = false;
      state.municipalities.filters = action.payload;
    },
    getMunicipalitiesSuccess: (state, action) => {
      const results = action.payload;
      state.municipalities.requesting = false;        
      state.municipalities.items = results.map((item) => ({
        label: item.name, 
        value: item.code,
        ...item
      })).sort((a, b) => a.label.localeCompare(b.label));
      state.municipalities.success = true; 
    },
    getMunicipalitiesFail: (state, action) => {        
        state.municipalities.requesting = false;
        state.municipalities.error = action.payload;
    },

    getBarangays: (state, action) => {		
      state.barangays.requesting = true;
      state.barangays.error = null;
      state.barangays.items = [];
      state.barangays.success = false;
      state.barangays.filters = action.payload;
    },
    getBarangaysSuccess: (state, action) => {
      const results = action.payload;
      state.barangays.requesting = false;        
      state.barangays.items = results.map((item) => ({
        label: item.name, 
        value: item.code,
        ...item
      })).sort((a, b) => a.label.localeCompare(b.label));
      state.barangays.success = true; 
    },
    getBarangaysFail: (state, action) => {        
      state.barangays.requesting = false;
      state.barangays.error = action.payload;
    },
  },
})

export const { 
  getRegions, getRegionsSuccess, getRegionsFail,
  getProvinces, getProvincesSuccess, getProvincesFail,
  getMunicipalities, getMunicipalitiesSuccess, getMunicipalitiesFail,
  getBarangays, getBarangaysSuccess, getBarangaysFail,
} = psgcDataSlice.actions

export default psgcDataSlice.reducer