import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const LoadingIndicator = () => {
  	const isLoading = useSelector(state => state.layout.isLoading);
  	const loadingText = useSelector(state => state.layout.loadingText);
	const [ displayLoading, setDisplayLoading ] = useState(isLoading);
	
	useEffect(() => {
	    setDisplayLoading(isLoading);
	  }, [isLoading]);

  	return !displayLoading ? null : (
	  	<div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
    		<div className="p-5 bg-white rounded-md">
		  		<Spin size="large">
		        	<div className="p-4 font-bold text-xl">{loadingText}</div>
		      	</Spin>      		
      	 	</div>
    	</div>  
  	);
};

export default LoadingIndicator;