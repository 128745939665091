import React, { useEffect } from "react";
import Card from "components/card";
import { Form, Input } from 'antd';
import FormItem from "components/fields/FormItem";
import SwitchField from "components/fields/SwitchField";
import { connect } from "react-redux";
import { 
	getParameterDetails, doParameterCreate, doParameterUpdate 
} from "../../../../redux/parameter/parameterState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";
import { requiredField } from '../../../../utils/crudSagaHelper';

const ParameterForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, ...props}) => {
	
  	const { recordId, mode } = props;  	
  	const [form] = useForm();
  	
  	useEffect(() => {
        getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
	        const initialValues = {
	            ...restData,	            
	        };
			
	        form.setFieldsValue(initialValues);
        }
    }, [recordData, form]);
    
  	const onSubmit = (formData) => {	
    	try {  
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
    	} catch (error) {
      		console.error('Save failed:', error);
    	}
  	};
  
  	return (
    	<Card extra={"w-full h-full p-4"}>
		  	<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
	        	<FormItem
			        name="id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>
        		<div className="grid grid-cols-4">         
		            <FormItem 
		            	name="name"
		                label="Name"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Input id="name"
		            		maxLength={50}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.name}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="code"
		                label="Code"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Input id="code"
		            		maxLength={20}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.code}</label>
		            	}	                
	            	</FormItem>	            	            		            	 
					<FormItem 
		            	name="route"
		                label="Route"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Input id="route"
		            		maxLength={100}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.route}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="tooltip"
		                label="Tooltip"
		            >
		            	{mode === 'edit' ?
		            	<Input id="tooltip"
		            		maxLength={200}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.tooltip}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="icon_class"
		                label="Icon Class"
		            >
		            	{mode === 'edit' ?
		            	<Input id="icon_class"
		            		maxLength={200}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.icon_class}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="sort_order"
		                label="Sort Order"
		            >
		            	{mode === 'edit' ?
		            	<Input id="sort_order"
		            		maxLength={200}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.sort_order}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="parent_id"
		                label="Parent Parameter"
		            >
		            	{mode === 'edit' ?
		            	<Input id="parent_id"
		            		maxLength={200}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.parent_id}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="is_menu"
		                label="Is Menu"
						valuePropName="checked"
		            >
		            	{mode === 'edit' ?
						<SwitchField id="is_menu"/>
		            	: <label className="ps-3 dark:text-white">{recordData?.is_menu === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="is_active"
		                label="Is Active"
		            >
		            	{mode === 'edit' ?
						<SwitchField id="is_active"/>		            	: <label className="ps-3 dark:text-white">{recordData?.is_active === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
	 			</div>
	 			<CrudButtons baseRoute="/console/parameters" mode={mode} recordId={recordId} /> 				 			
	 		</Form>	 	
	 	</Card>
  	);
};

const mapStateToProps = (state) => ({
  	recordData: state.parameter.parameterDetails?.data,
  	requesting: state.parameter.parameterDetails?.requesting,
  	success: state.parameter.parameterDetails?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getParameterDetails(id)),
  	doCreate: (data) => dispatch(doParameterCreate(data)),
  	doUpdate: (data) => dispatch(doParameterUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParameterForm);
