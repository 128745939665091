import { Switch, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function SwitchField (props) {
  const { id, type, label, ...rest } = props;
  return (
    <Space>
        <Switch id={id}
        checkedChildren={type === 'text' ? 'Yes': <CheckOutlined />}
        unCheckedChildren={type === 'text' ? 'No': <CloseOutlined />}
        {...rest}/>
        {label && 
          <label>{label}</label>
        }
    </Space>
    
  );
};

export default SwitchField;