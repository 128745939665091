import React, { useState, useEffect } from "react";
import { Select, Input, Space  } from 'antd';
import FormItem from "components/fields/FormItem";

const SelectWithOthers = (props) => {
  	const { selectFieldName, selectOptions, selectDisplayValue,
  		othersFieldName, othersDisplayValue, mode, optionOthersId, onChangeHandler, othersPlaceholder, othersPlaceholders, othersStyle, direction } = props;

	const [showOthers, setShowOthers] = useState(optionOthersId.includes(selectDisplayValue) ? 'text' : 'hidden');
	const [placeholder, setPlaceholder] = useState(othersPlaceholder ?? 'Please specify');
	const handleSelectChange = (value) => {
		console.log(othersPlaceholders);
		if(othersPlaceholders && optionOthersId.includes(value)){
			setPlaceholder(othersPlaceholders[value] ?? 'Please specify');
		}
        setShowOthers(optionOthersId.includes(value) ? 'text' : 'hidden');
        if(onChangeHandler){
			onChangeHandler();
		}
    };

	useEffect(() => {
		setShowOthers(optionOthersId.includes(selectDisplayValue) ? 'text' : 'hidden');
	}, [selectDisplayValue, optionOthersId]);
	
  	return (
		  <Space.Compact block={true} direction={direction ?? 'horizontal'} wrap="true">
            <FormItem
            	name={selectFieldName}
                noStyle                
            >
            	{mode === 'edit' ?
            	<Select id={selectFieldName}            		
				    options={selectOptions}
				    showSearch
            		allowClear={true}
            		onChange={handleSelectChange}
            	/>
                : <label className="ps-3 dark:text-white">{selectDisplayValue}</label>
                }
        	</FormItem>
            <FormItem
            	name={othersFieldName}
                label="Others"
                noStyle
            >
            	{mode === 'edit' ?
            	<Input id={othersFieldName}
            		placeholder={placeholder}
            		type={showOthers} 
					className={direction === 'vertical' ? 'mt-3':''}
					style={othersStyle} 
            	/>
                : <label className="ps-3 dark:text-white">{othersDisplayValue}</label>
                }
        	</FormItem>
        </Space.Compact>
  );
}

export default SelectWithOthers;
