import classNames from 'classnames';

function Button(props) {
  const { variant, className, extra, children, ...rest } = props;
  
  const buttonClassName = classNames(
    'btn btn-primary',
    className,
    extra 
  );
  
  return (
    <button  
    	className={buttonClassName}
    	{...rest}
    	>
      {children}
    </button>
  );
}

export default Button;
