import React from "react";
import ModuleTable from "./components/ModuleTable";

import { connect } from "react-redux";
import DownloadButton from "components/button/download";
import UploadButton from "components/button/upload";

const Modules = ({filters}) => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">

	      	<ModuleTable/>
	      	
	      	<div className="flex flex-row-reverse text-right gap-4">	      		
	      		<DownloadButton downloadPath="module-list" title="Download" filters={filters}/>
	      		<UploadButton uploadPath="module-list" title="Upload"/>
	      	</div>
	      	
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
  filters: state.module.moduleList?.filters,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Modules);