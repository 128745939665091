import AuthHeader, { TOKEN, REFRESH_TOKEN } from './authheader';
import cookie from 'js-cookie';
import { message } from 'antd';
import { API_URL } from '../constants';

const USER_INFO = 'userInfo';

class APIService {
  
	async doRequest(endpoint, method, data, headers=null, options=null)
	{
		try {
			if(method === 'GET' && data){
				const queryParams = new URLSearchParams();
	            for (const key in data) {
	                if (data.hasOwnProperty(key)) {
	                    queryParams.append(key, data[key]);
	                }
	            }
	            endpoint += '?' + queryParams.toString();
				data = null;
			}
		    const response = await this.fetchWithTimeout(`${API_URL}/${endpoint}`, {
		      method: method,
		      body: data ? JSON.stringify(data) : null,
		      headers: AuthHeader(headers),
		      ...options || {},
		    }, 500 * 1000);
		
			return this.handleResponse(response);
		  } catch (error) {
		    console.error('Fetch error:', error);
		  }
	}
	
	async doUploadRequest(endpoint, file, headers=null, options=null)
	{		
		try {
			
			const formData = new FormData();
			formData.append('file', file);
			
			const authHeaders = AuthHeader(headers);
		    const { 'Content-Type': _, ...filteredHeaders } = authHeaders;
      
		    const response = await fetch(`${API_URL}/${endpoint}`, {
		      method: 'POST',
		      body: formData,
		      headers: filteredHeaders ,
		      ...options || {},
		    });
		
			return this.handleResponse(response);
		  } catch (error) {
		    console.error('Fetch error:', error);		    
		  }
	}
	
	async doExportRequest(endpoint, filters, headers=null, options=null)
	{
		return this.doRequest(endpoint, 'GET', filters, headers, options);
	}

	async fetchImage(endpoint) {
		try {
			const authHeaders = AuthHeader(null);
		    const { 'Content-Type': _, ...filteredHeaders } = authHeaders;

			const response = await fetch(`${API_URL}/${endpoint}`, {
				method: 'GET',
				body: null,
				headers: filteredHeaders,
			  });

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const blob = await response.blob();
			const imageUrl = URL.createObjectURL(blob);
			return imageUrl;
		} catch (error) {
			console.error('Error fetching image:', error);
		}
	}
	
	async handleResponse(response) 
	{
	    if (!response.ok) {
	      const errorData = await response.json();
	      console.error('Error details:', errorData);
	      if(errorData.errors){	    
		    const errorObject = {};
		    for (const [field, messages] of Object.entries(errorData.errors)) {
		        errorObject[field] = messages.join(' ');
		    }
		    console.log(errorObject);
	      	return {errorMessage: errorObject}; 
	      }else{
			  message.error(`Error: ${errorData.message}`);
		      throw new Error(`Error: ${errorData.message}`);
		  }
	    }
	
	    const responseData = await response.json();
	    //console.log('Data received:', responseData);
	    return responseData;
  	}
  	
  	async fetchWithTimeout(url, options = {}, timeout = 30000)
  	{
	  const controller = new AbortController();
	  const signal = controller.signal;
	  
	  const timeoutId = setTimeout(() => controller.abort(), timeout);
	
	  try {
	    const response = await fetch(url, { ...options, signal });
	    clearTimeout(timeoutId);
	    return response;
	  } catch (error) {
	    if (error.name === 'AbortError') {
	      throw new Error('Request timed out');
	    }
	    throw error;
	  }
	};
	
	setToken(token){
		cookie.set(TOKEN, token);
	}
	
	getToken(){
		return cookie.get(TOKEN);
	}
	
	deleteToken(){
		cookie.remove(TOKEN);
	}
	
	setRefreshToken(token){
		cookie.set(REFRESH_TOKEN, token);
	}
	
	getRefreshToken(){
		return cookie.get(REFRESH_TOKEN);
	}
	
	getUserInfo(){
	    const userInfo = cookie.get(USER_INFO);
	    if(userInfo){
	      return JSON.parse(userInfo);
	    }
	    return null;
  	}
  	
  	setUserInfo(userInfo){
		cookie.set(USER_INFO, userInfo);
	}
	
	deleteUserInfo(){
		cookie.remove(USER_INFO);
	}
}

const apiService = new APIService();

export default apiService;