import React from "react";
import { Input, Space } from 'antd';

const { Search } = Input;

function SearchFilter(props) {
  const { placeholder, searchHandler, filters, pagination } = props;
  
  const onSearchHandler = (value) => {		
		searchHandler({...filters, page:1, paginate: pagination.per_page, keyword: value});		
	}
    
  return (
  	<Space.Compact className="mb-3" size="large">
  		<Search placeholder={placeholder ?? "Keyword"} onSearch={onSearchHandler} style={{ width: 300 }}/>
	</Space.Compact>
  );
}

export default SearchFilter;
