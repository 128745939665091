import React from "react";
import RoleTable from "./components/RoleTable";

import { connect } from "react-redux";

const Roles = () => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">

	      	<RoleTable/>
	      	
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);