import React, { useState  }  from "react";
import CardItem from "components/card/CardItem";
import { ReactComponent as Logo } from 'assets/svg/intsamis_logo.svg';
import { connect } from "react-redux";
import { Scanner } from '@yudiel/react-qr-scanner';
import { Form } from 'antd';
import FormItem from "components/fields/FormItem";
import TextAreaField from "components/fields/TextAreaField";
import { useForm } from 'antd/lib/form/Form';
import Button from "components/button";
import { doCheckQRData } from "../../redux/auth/authState";
import PanelBanner from "components/banners/PanelBanner";


const HomeScreen = ({checkQRData}) => {
  const [form] = useForm();
  const [qrCodeResult, setQrCodeResult] = useState("");
  const handleScan = (result) => {
    if (result) {
      setQrCodeResult(result[0].rawValue);
      checkQRData(JSON.parse(result[0].rawValue));
    }
  };

  const onSubmit = (formData) => {	
    try {  
      checkQRData(JSON.parse(formData.qr_data));
    } catch (error) {
        console.error('Save failed:', error);
    }
  };

  return (
    <div className="m-4 flex flex-col h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">      
      <PanelBanner/>
      <CardItem 
      extra="w-[400px] border-1"
      icon={<Logo/>}
      htmlTitle={<h4 className="ml-4 card-title text-3xl dark:text-theme-200">Scan QR Code</h4>}>
        <Form form={form} className="bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
          <div className="flex flex-col items-center mt-5">
            <div className="w-[250px] h-[250px]">
              <Scanner onScan={handleScan} />
            </div>
            <p className="text-center text-lg mb-3 hidden">
              {qrCodeResult ? `Scanned Result: ${qrCodeResult}` : "Position your ID with the QR code on the camera for scanning."}
            </p>
          </div>

          <div className="hidden">
            <FormItem 
                name="qr_data"
                  label="QR Data"
              >
                <TextAreaField id="qr_data"
                  rows={4}
                />
              </FormItem>
          </div>      
          <Button className="hidden" type="submit">Submit</Button>
        </Form>
      </CardItem>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({  
  checkQRData: (qrdata) => dispatch(doCheckQRData(qrdata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);