export const topFarmers = [
  {
    name: "Juan Dela Cruz",
    animals: 20,
    points: 300
  },
  {
    name: "Joselito Garcia",
    animals: 20,
    points: 300
  },
  {
    name: "Carlos Magsaysay",
    animals: 20,
    points: 300
  },
  {
    name: "Emilio Bautista",
    animals: 20,
    points: 300
  },
  {
    name: "Pedro Ibarra",
    animals: 20,
    points: 300
  },
  {
    name: "Leonora Acosta",
    animals: 20,
    points: 300
  },  
  {
    name: "Paulo Aquino",
    animals: 20,
    points: 300
  },  
  {
    name: "Luzviminda Cruz",
    animals: 20,
    points: 300
  },  
  {
    name: "Juan Carlo Hernandez",
    animals: 20,
    points: 300
  },  
  {
    name: "Pen Ferrer",
    animals: 20,
    points: 300
  },  
];

export const topFarmerColumn = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Animals",
    accessor: "animals",
  },
  {
    Header: "Points",
    accessor: "points",
  },
];