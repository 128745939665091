import { takeLatest } from 'redux-saga/effects';
import {
  getUserRoleListSuccess, getUserRoleListFail,
  getUserRoleDetailsSuccess, getUserRoleDetailsFail
} from './userRoleState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/user-roles');
const redirectData = {route:'/console/user-roles', mode: 'list', recordId:0 };
const modelName = 'User Roles';
const modelId = 'id';

function* getUserRoleList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getUserRoleListSuccess, 
		getUserRoleListFail);
}

function* getUserRoleDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getUserRoleDetailsSuccess, 
		getUserRoleDetailsFail);		
}

function* doUserRoleCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getUserRoleDetailsSuccess, 
		getUserRoleDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doUserRoleUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getUserRoleDetailsSuccess, 
		getUserRoleDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doUserRoleDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getUserRoleDetailsSuccess, 
		getUserRoleDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* userRoleSaga() {
  yield takeLatest('userRole/getUserRoleList', getUserRoleList);
  yield takeLatest('userRole/getUserRoleDetails', getUserRoleDetails);
  yield takeLatest('userRole/doUserRoleCreate', doUserRoleCreate);
  yield takeLatest('userRole/doUserRoleUpdate', doUserRoleUpdate);
  yield takeLatest('userRole/doUserRoleDelete', doUserRoleDelete);  
}

export default userRoleSaga;
