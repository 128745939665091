import React, { useState } from 'react';
import { Dropdown, Space, Modal, theme  } from 'antd';
import Button from "components/button";
import {
  DownOutlined, EyeOutlined, EditOutlined, DeleteOutlined
} from '@ant-design/icons';

const { useToken } = theme;

const CrudOption = (props) => {
  	const { baseRoute, recordId, queryParams, deleteHandler, additionalMenu, 
  		allowView=true, allowEdit=true, allowDelete=true 
  		} = props;
  		
  	const { token } = useToken();
  	const [open, setOpen] = useState(false);
  	const menuOptions = [
  	{
	    key: 'view',
	    label: (
			<a href={`${baseRoute}/${recordId}/view${queryParams ? '?'+queryParams : ''}`}>
				View
			</a>
	    ),
	    icon: <EyeOutlined />,
	    available: allowView ? 1 : 0,
	  },
	  {
	    key: 'edit',
	    label: (
			<a href={`${baseRoute}/${recordId}/edit${queryParams ? '?'+queryParams : ''}`}>
	      		Edit
	      	</a>
	    ),
	    icon: <EditOutlined />,
	    available: allowEdit ? 1 : 0,
	  },
	  {
	    key: 'delete',
	    label: (
	      	<div onClick={(e) => {e.preventDefault();setOpen(true)}}>
	      		Delete
	      	</div>
	    ),
	    icon: <DeleteOutlined />,
	    available: allowDelete ? 1 : 0,
	  },	  
	];
	
	const allMenuOptions = additionalMenu ? menuOptions.concat(additionalMenu) : menuOptions;
	const items = allMenuOptions.filter(item => item.available === 1);	
	
	const contentStyle = {
	    backgroundColor: token.colorBgElevated,
	    borderRadius: token.borderRadiusLG,
	    boxShadow: token.boxShadowSecondary,
  	};
	const menuStyle = {
	    boxShadow: 'none',
  	};
	
  	return (
		  <div>
	    	<Dropdown
	    		menu={{items}}
			  	dropdownRender={(menu) => (
			        <div style={contentStyle}>
			          {React.cloneElement(menu, {
			            style: menuStyle,
			          })}			          
			        </div>
			      )}       		
	    	>
			    <div className="cursor-pointer">
			      <Space>
			        Action
			        <DownOutlined />
			      </Space>
			    </div>
		  	</Dropdown>
		  	<Modal
                open={open}
                closeIcon={false}
                centered
                title="Confirm Delete"
                okText="Confirm"
                cancelText="Cancel"
                footer={(_) => (
                    <>
                        <Button className="me-4" 
                            onClick={(e) => {setOpen(false)}}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={(e) => {setOpen(false); deleteHandler({mode: 'reload', recordId})}}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            >
                Are you sure you want to delete this record?
            </Modal>
	  	</div>
  );
}

export default CrudOption;
