import { message } from 'antd';
import { API_URL } from '../constants';

class PSGCAPIService {
  
	async doRequest(endpoint, method, data, headers=null, options=null)
	{
		try {
			if(method === 'GET' && data){
				const queryParams = new URLSearchParams();
	            for (const key in data) {
	                if (data.hasOwnProperty(key)) {
	                    queryParams.append(key, data[key]);
	                }
	            }
	            endpoint += '?' + queryParams.toString();
				data = null;
			}
		    const response = await this.fetchWithTimeout(`${endpoint}`, {
		      method: method,
		      body: data ? JSON.stringify(data) : null,
		      headers: {'Content-Type': 'application/json'},
		      ...options || {},
		    }, 500 * 1000);
		
			return this.handleResponse(response);
		  } catch (error) {
		    console.error('Fetch error:', error);
		  }
	}
	
	async handleResponse(response) 
	{
	    if (!response.ok) {
	      const errorData = await response.json();
	      console.error('Error details:', errorData);
	      if(errorData.errors){	    
		    const errorObject = {};
		    for (const [field, messages] of Object.entries(errorData.errors)) {
		        errorObject[field] = messages.join(' ');
		    }
		    console.log(errorObject);
	      	return {errorMessage: errorObject}; 
	      }else{
			  message.error(`Error: ${errorData.message}`);
		      throw new Error(`Error: ${errorData.message}`);
		  }
	    }
	
	    const responseData = await response.json();
		console.log(responseData);
	    return responseData;
  	}
  	
  	async fetchWithTimeout(url, options = {}, timeout = 30000)
  	{
	  const controller = new AbortController();
	  const signal = controller.signal;
	  
	  const timeoutId = setTimeout(() => controller.abort(), timeout);
	
	  try {
	    const response = await fetch(url, { ...options, signal });
	    clearTimeout(timeoutId);
	    return response;
	  } catch (error) {
	    if (error.name === 'AbortError') {
	      throw new Error('Request timed out');
	    }
	    throw error;
	  }
	};
}

const psgcApiService = new PSGCAPIService();

export default psgcApiService;