import { createSlice  } from '@reduxjs/toolkit'

const initialState = {
    parameterList: {
      requesting: false,
      items: [],
      pagination: {
		  current_page: 1,
		  per_page: 10,
		  total: 0,
	  },
      error: null,
      success: false,
      filters: {},
    },
    parameterDetails: {
      requesting: false,
      error: null,
      success: false,
      data: null
    },
  };

export const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    getParameterList: (state, action) => {		
        state.parameterList.requesting = true;
        state.parameterList.error = null;
        state.parameterList.items = [];
        state.parameterList.success = false;
        state.parameterList.filters = action.payload;
    },
    getParameterListSuccess: (state, action) => {
		const { data, current_page, per_page, total} = action.payload;
        state.parameterList.requesting = false;        
        state.parameterList.items = data;
        state.parameterList.success = true; 
        state.parameterList.pagination.current_page = current_page;
        state.parameterList.pagination.per_page = per_page;
        state.parameterList.pagination.total = total;
    },
    getParameterListFail: (state, action) => {        
        state.parameterList.requesting = false;
        state.parameterList.error = action.payload;
    },
    getParameterDetails: (state) => {
        state.parameterDetails.requesting = true;
        state.parameterDetails.error = null;
        state.parameterDetails.data = null;
        state.parameterDetails.success = false; 
    },
    getParameterDetailsSuccess: (state, action) => {
        state.parameterDetails.requesting = false;
        state.parameterDetails.data = action.payload;
        state.parameterDetails.success = true; 
    },
    getParameterDetailsFail: (state, action) => {        
        state.parameterDetails.requesting = false;
        state.parameterDetails.error = action.payload;
    },
    doParameterCreate: (state) => {
        state.parameterDetails.requesting = true;
        state.parameterDetails.error = null;
        state.parameterDetails.data = null;
        state.parameterDetails.success = false; 
    },
    doParameterUpdate: (state) => {
        state.parameterDetails.requesting = true;
        state.parameterDetails.error = null;
        state.parameterDetails.data = null;
        state.parameterDetails.success = false; 
    },
    doParameterDelete: (state) => {
        state.parameterDetails.requesting = true;
        state.parameterDetails.error = null;
        state.parameterDetails.data = null;
        state.parameterDetails.success = false; 
    },
  },
})

export const { 
    getParameterList, getParameterListSuccess, getParameterListFail,
    getParameterDetails, getParameterDetailsSuccess, getParameterDetailsFail,
    doParameterCreate, doParameterUpdate, doParameterDelete
} = parameterSlice.actions

export default parameterSlice.reducer