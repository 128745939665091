import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import React from "react";
import PanelBar from "views/panel/components/PanelBar";
import PanelLogout from "views/panel/components/PanelLogout";
import PanelBanner from "components/banners/PanelBanner";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CrudButtons from "components/button/crudbuttons";

export default function Error(props) {
  const { ...rest } = props;

  document.documentElement.dir = "ltr";
  return (
    <div className="relative flex h-full w-full">    
      <FixedPlugin />  
      <div className="flex h-full w-full bg-lightPrimary dark:!bg-intsamis-900 justify-center">
        <main className={`panel-main-wrapper`}>
          <div className="h-full">
            <div className="flex flex-col md:flex-row h-auto md:h-[100px] w-full max-w-[700px] items-center justify-between mx-auto mt-[15px]">
              <PanelBanner/>
              <PanelBar {...rest}/>
            </div>
            <div className="pt-2 mx-auto mb-auto h-full min-h-[60vh] p-2 md:pr-2">
              <div className="mt-16 mb-16 mt-3 flex flex-column items-center gap-5 max-w-[700px] mx-auto">
                <ExclamationCircleOutlined style={{ fontSize: '72px', color: '#f00'}}/>
                <div>
                  <h2 className="text-[32px] mb-3">Error 404 </h2>
                  <p className="text-[18px]">The page you're looking for doesn't exist.</p>
                </div>
              </div> 
              <CrudButtons className="!text-center" 
                mode='view' 
                backUrl="/panel/services"
                backLabel="Home"
                withEdit={false}/>             
            </div>
            <PanelLogout/>
            <div className="p-3"></div>
          </div>
        </main>
      </div>
    </div>
  );
}
