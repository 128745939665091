import React, { useEffect } from "react";
import Card from "components/card";
import { Form, Input, Select } from 'antd';
import FormItem from "components/fields/FormItem";
import TextAreaField from "components/fields/TextAreaField";
import { connect } from "react-redux";
import { 
	getRoleDetails, doRoleCreate, doRoleUpdate 
} from "../../../../redux/role/roleState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";
import { requiredField } from '../../../../utils/crudSagaHelper';

const RoleForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, ...props}) => {
	
  	const { recordId, mode } = props;  	
  	const [form] = useForm();
  	
  	useEffect(() => {
        getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
	        const initialValues = {
	            ...restData,	            
	        };
			
	        form.setFieldsValue(initialValues);
        }
    }, [recordData, form]);
    
  	const onSubmit = (formData) => {	
    	try {  
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
    	} catch (error) {
      		console.error('Save failed:', error);
    	}
  	};
  
  	return (
    	<Card extra={"w-full h-full p-4"}>
		  	<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
	        	<FormItem
			        name="id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>
        		<div className="grid grid-cols-4">         
		            <FormItem 
		            	name="name"
		                label="Name"
						extra="col-span-2"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Input id="name"
		            		maxLength={30}		            		
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.name}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="level"
		                label="Level"
		                rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Select id="level"
							showSearch
							allowClear={true}		           
							options={recordData?.roleLevelList}
						/>
		            	: <label className="ps-3 dark:text-white">{recordData?.level}</label>
		            	}	                
	            	</FormItem>	 
				</div>
				<div className="grid grid-cols-1">
					<FormItem 
		            	name="description"
		                label="Description"
		            >
		            	{mode === 'edit' ?
		            	<TextAreaField id="description"
							rows={4}
		            		maxLength={300}
		            	/>
		            	: <label className="ps-3 dark:text-white">{recordData?.description}</label>
		            	}	                
	            	</FormItem>
	 			</div>
	 			<CrudButtons baseRoute="/console/roles" mode={mode} recordId={recordId} /> 				 			
	 		</Form>	 	
	 	</Card>
  	);
};

const mapStateToProps = (state) => ({
  	recordData: state.role.roleDetails?.data,
  	requesting: state.role.roleDetails?.requesting,
  	success: state.role.roleDetails?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getRoleDetails(id)),
  	doCreate: (data) => dispatch(doRoleCreate(data)),
  	doUpdate: (data) => dispatch(doRoleUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
