import React from "react";

// Admin Imports
import Dashboard from "views/console/default";
import Profile from "views/admin/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";
import AdminSignIn from "views/auth/AdminSignIn";
import HomeScreen from "views/auth/HomeScreen";
import Register from "views/auth/Register";


// Icon Imports
import { HomeOutlined, SettingOutlined, LockOutlined } from '@ant-design/icons';

import panelRoutes from "views/panel/routes";
import usersRoutes from "views/console/users/routes";
import moduleRoutes from "views/console/modules/routes";
import accessRightsRoutes from "views/console/access-rights/routes";
import parametersRoutes from "views/console/parameters/routes";
import rolesRoutes from "views/console/roles/routes";

const routes = [
  {
    name: "Home",
    layout: "/auth",
    path: "home",
    component: <HomeScreen />,
    isMainMenu:false,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "register",
    component: <Register />,
    isMainMenu:false,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    component: <SignIn />,
    isMainMenu:false,
  },
  {
    name: "Admin Sign In",
    layout: "/auth",
    path: "base/sign-in",
    component: <AdminSignIn />,
    isMainMenu:false,
  },
  {
    name: "Power Admin Sign In",
    layout: "/auth",
    path: "pad/sign-in",
    component: <AdminSignIn />,
    isMainMenu:false,
  },
  {
    name: "Dashboard",
    layout: "/console",
    path: "dashboard",    
    component: <Dashboard />,
    isMainMenu:true,
    icon: <HomeOutlined className="h-6 w-6" />,
  },
  ...usersRoutes,
  {
    name: "Settings",
    layout: "/console",
    path: "#",    
    component: null,
    isMainMenu:true,
    icon: <SettingOutlined className="h-6 w-6"/>,
    children: [
      ...parametersRoutes,  
    ]
  },
  {
    name: "Access Management",
    layout: "/console",
    path: "#",    
    component: null,
    isMainMenu:true,
    icon: <LockOutlined className="h-6 w-6"/>,
    children: [
      ...moduleRoutes,
      ...accessRightsRoutes,    
      ...rolesRoutes,
    ]
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    component: <Profile />,
    isMainMenu:false,
  },  
  ...panelRoutes,  
];
export default routes;
