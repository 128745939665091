import React from "react";
import UserTable from "./components/UserTable";

import { connect } from "react-redux";
import DownloadButton from "components/button/download";
import UploadButton from "components/button/upload";

const Users = ({filters}) => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">

	      	<UserTable/>
	      	
	      	<div className="flex flex-row-reverse text-right gap-4">	      		
	      		<DownloadButton downloadPath="user-list" title="Download" filters={filters}/>
	      		<UploadButton uploadPath="user-list" title="Upload"/>
	      	</div>
	      	
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
  filters: state.user.userList?.filters,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);