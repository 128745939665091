import React from "react";

import { connect } from "react-redux";

const MyFarmParcels = () => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
	      	TEst
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmParcels);