import React  from "react";
import { useParams } from 'react-router-dom';
import UserRoleForm from './components/UserRoleForm';

const ViewUserRole = () => {
	const { userroleId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<UserRoleForm recordId={userroleId} mode="view"/>
	      </div>
	    </div>
    );
};

export default ViewUserRole;