import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import APIService from '../../../services/apiservice';
import { showLoading, hideLoading } from '../../../redux/layout/layoutState';
import { API_URL } from '../../../constants';

const PanelBar = ({user, ...props}) => {

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(`${API_URL}/my/avatar/${user?.id}`);

  const onAvatarClickHandler = () => {
		setOpenModal(true);
	};

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    if(isJpgOrPng && isLt2M){
      showLoading('Uploading...');
      await APIService.doUploadRequest(`api/v1/services/upload/my/avatar`, file);
      hideLoading();
      message.success(`Upload profile photo complete`);
        setTimeout(() => {
            window.location.reload();
        }, 3000);
      return false;
    }
    return false;
  };

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {      
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, url => {
        setLoading(false);
        setImageUrl(`${API_URL}/my/avatar/${user?.id}`);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {		
    setImageUrl(`${API_URL}/my/avatar/${user?.id}`);
  }, [user]);

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-center rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-end gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-intsamis-800 dark:shadow-none md:w-[260px] md:flex-grow-0 md:gap-1 xl:gap-2">        
        <div className="flex gap-3">
          <div className="flex items-center gap-2">
            <p className="text-sm font-bold text-theme-700 dark:text-white">
              Hello, {user?.user_details?.first_name ?? user?.username}
            </p>{" "}
          </div>  
          <img
            className="h-10 w-10 rounded-full cursor-pointer"
            src={imageUrl}
            alt={user?.user_details?.first_name ?? user?.username}
            onClick={onAvatarClickHandler}
          />                      
        </div>
      </div>
      <Modal title="Upload Profile Photo" 
				centered
				open={openModal} 
        onCancel={(e) => {setOpenModal(false)}}
        okText="Save"
        cancelText="Cancel"
        width={250}
        footer={(_) => (
              <>                                 
              </>
          )}
				>
          <div className="text-center">
            <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </div>
          
			</Modal>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelBar);
