import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";
import userRolesRoutes from "../user-roles/routes";

import { SolutionOutlined } from '@ant-design/icons';
import Roles from "./index";
import ViewRole from "./view";
import EditRole from "./edit";

const rolesRoutes = [	
	generateCRUDRoutes({
		mainTitle: "Roles",
		basePath: "roles",
		mainComponent: <Roles />,
	    layout: "/console",	   
	    icon: <SolutionOutlined className="h-6 w-6" />,
	    subTitle:"Role",
	    viewComponent: <ViewRole />,
	    createComponent: <EditRole />,
	    editComponent: <EditRole />,
		viewSubmodules: userRolesRoutes,
	})
]

export default rolesRoutes;