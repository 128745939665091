import React from "react";
import { Form } from 'antd';

function FormItem(props) {
  const { label, extra, children, ...rest } = props;

  return (
    <Form.Item className={`font-medium mb-3 pr-3 ${extra ?? ''}`}
        label={label !== '' ? <label className="form-item-label">{label}</label> : null}	
        {...rest}	               
    >
    	{children}
	</Form.Item>
  );
}

export default FormItem;
