import React  from "react";
import { useParams } from 'react-router-dom';
import RoleForm from './components/RoleForm';
import RoleUsersTable from './components/RoleUsersTable';

const ViewRole = () => {
	const { roleId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<RoleForm recordId={roleId} mode="view"/>
	      	</div>

			<div className="mt-5 grid h-full grid-cols-1 gap-4">
				<h4 className="font-bold text-[24px] capitalize text-theme-700 dark:text-white">Users</h4>
				<RoleUsersTable roleId={roleId}/>
			</div>
	    </div>
    );
};

export default ViewRole;