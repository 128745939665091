import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Input, InputNumber } from 'antd';
import FormItem from "components/fields/FormItem";
import SwitchField from "components/fields/SwitchField";
import SwitchWithSpecify from "components/fields/SwitchWithSpecify";
import { requiredField } from '../../../../utils/crudSagaHelper';

const FarmerProfileFields = ({recordData, mode, form, ...props}) => {
  
	const validateHouseholdMembers = () => {
		const total = form.getFieldValue("living_household_members") || 0;
		const male = form.getFieldValue("male_household_members") || 0;
		const female = form.getFieldValue("female_household_members") || 0;
		if (male + female !== total) {
		  return Promise.reject(new Error("Incorrect total of household members."));
		}
		return Promise.resolve();
	};
	
	const handleHouseholdMembersChange = () => {
		form.validateFields(["living_household_members", "male_household_members", "female_household_members"]);
	};

  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<FormItem name="user_details_id" hidden>
				<Input type="hidden"/>
			</FormItem>
			
			<FormItem 
				name="is_household_head"
				label="Household Head"
			>
				<SwitchWithSpecify 
					mode={mode}
					id="is_household_head"
					type="text"
					switchValue={recordData?.is_household_head} 					
					specifyFieldName="household_head_name"
					specifyDisplayValue={recordData?.household_head_name}
					specifyPlaceholder="If no, name of household head"
					specifyStyle={{width: '250px'}}
					condition={false}>
					
					<FormItem 
						name="household_relationship"
						label="Relationship"
						noStyle
						rules={ recordData?.is_household_head === 0 ? [requiredField()] : []}
					>
						{mode === 'edit' ?
						<Input id="household_relationship"
							maxLength={100}	
							placeholder="Relationship"	            		
						/>
						: <label className="ps-3 dark:text-white">{recordData?.household_relationship}</label>
						}	                
					</FormItem>
				</SwitchWithSpecify>
			</FormItem>					
			<div className="form-column-3">
				<FormItem 
					name="living_household_members"
					label="No. of Living Household Members"
					rules={[requiredField(), { validator: validateHouseholdMembers }]}
				>
					{mode === 'edit' ?
					<InputNumber id="living_household_members" onChange={handleHouseholdMembersChange}/>
					: <label className="ps-3 dark:text-white">{recordData?.living_household_members}</label>
					}	                
				</FormItem>
				<FormItem 
					name="male_household_members"
					label="No. of Male"
					rules={[requiredField(), { validator: validateHouseholdMembers }]}
				>
					{mode === 'edit' ?
					<InputNumber id="male_household_members" onChange={handleHouseholdMembersChange}/>
					: <label className="ps-3 dark:text-white">{recordData?.male_household_members}</label>
					}	                
				</FormItem>
				<FormItem 
					name="female_household_members"
					label="No. of Female"
					rules={[requiredField(), { validator: validateHouseholdMembers }]}
				>
					{mode === 'edit' ?
					<InputNumber id="female_household_members" onChange={handleHouseholdMembersChange}/>
					: <label className="ps-3 dark:text-white">{recordData?.female_household_members}</label>
					}	                
				</FormItem>
			</div> 
			<div className="form-column-4">
				<FormItem 
					name="is_pwd"
					label="Person with Disability (PWD)"
				>
					{mode === 'edit' ?
					<SwitchField id="is_pwd" type="text"/>					
					: <label className="ps-3 dark:text-white">{recordData?.is_pwd}</label>
					}	                
				</FormItem>
				<FormItem 
					name="is_4ps_beneficiary"
					label="4P's Beneficiary?"
				>
					{mode === 'edit' ?
					<SwitchField id="is_4ps_beneficiary" type="text"/>					
					: <label className="ps-3 dark:text-white">{recordData?.is_4ps_beneficiary}</label>
					}	                
				</FormItem>	
				<FormItem extra="col-span-2"
					name="is_indigenous_group"
					label="Member of Indigenous Group"
				>
					<SwitchWithSpecify 
						mode={mode}
						id="is_indigenous_group"
						switchValue={recordData?.is_indigenous_group} 
						type="text"
						specifyFieldName="indigenous_group_name"
						specifyDisplayValue={recordData?.indigenous_group_name}/>					
				</FormItem>					
				<FormItem extra="col-span-3"
					name="with_government_id"
					label="With Government ID?"
				>
					<SwitchWithSpecify 
						mode={mode}
						id="with_government_id"
						switchValue={recordData?.with_government_id} 
						type="text"
						specifyFieldName="government_id_type"
						specifyDisplayValue={recordData?.government_id_type}
						specifyPlaceholder="If yes, specify ID Type">

						<FormItem 
							name="government_id_number"
							label="ID Number"
							noStyle
							rules={ recordData?.with_government_id === 1 ? [requiredField()] : []}
						>
							{mode === 'edit' ?
							<Input id="government_id_number"
								maxLength={200}
								placeholder="ID Number"
							/>						
							: <label className="ps-3 dark:text-white">{recordData?.government_id_number}</label>
							}	                
						</FormItem>  
					</SwitchWithSpecify>              
				</FormItem>				
				<FormItem  extra="col-span-2"
					name="has_assoc_coop"
					label="Member of any Farmers Association/Cooperative?"
				>
					<SwitchWithSpecify 
						mode={mode}
						id="has_assoc_coop" 
						switchValue={recordData?.has_assoc_coop}
						type="text"
						specifyFieldName="assoc_coop_name"
						specifyDisplayValue={recordData?.assoc_coop_name}/>
				</FormItem>							
			</div>
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmerProfileFields);
