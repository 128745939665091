import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import FarmerProfileFields from "../../../console/farmer-information/components/FarmerProfileFields";
import { 
	getMyFarmerInfoDetails, doMyFarmerInfoCreate, doMyFarmerInfoUpdate 
} from "../../../../redux/farmerinfo/farmerInfoState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";

const MyFarmerInfoForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {		
		getRecord(recordId);
	}, [recordId]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
			const initialValues = {
				...restData,
				section: 'farmer_info'
			};
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		try {  
			formData.is_household_head = formData.is_household_head === false ? 0 : formData.is_household_head;
			formData.is_pwd = formData.is_pwd === false ? 0 : formData.is_pwd;
			formData.is_4ps_beneficiary = formData.is_4ps_beneficiary === false ? 0 : formData.is_4ps_beneficiary;
			formData.is_indigenous_group = formData.is_indigenous_group === false ? 0 : formData.is_indigenous_group;
			formData.with_government_id = formData.with_government_id === false ? 0 : formData.with_government_id;
			formData.has_assoc_coop = formData.has_assoc_coop === false ? 0 : formData.has_assoc_coop;
			formData.redirect = false;
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<FarmerProfileFields recordData={recordData} mode={mode} form={form}/>

				<CrudButtons baseRoute="/panel/my-farmer-information" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/services"
					cancelUrl="/panel/services"
					cancelLabel="Back"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.farmerInfo.details?.data,
	requesting: state.farmerInfo.details?.requesting,
	success: state.farmerInfo.details?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyFarmerInfoDetails(id)),
	doCreate: (data) => dispatch(doMyFarmerInfoCreate(data)),
	doUpdate: (data) => dispatch(doMyFarmerInfoUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmerInfoForm);