import { takeLatest } from 'redux-saga/effects';
import {
  getRoleListSuccess, getRoleListFail,
  getRoleDetailsSuccess, getRoleDetailsFail,
  getAddUserListSuccess, getAddUserListFail,
} from './roleState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/roles');
const redirectData = {route:'/console/roles', mode: 'list', recordId:0 };
const modelName = 'Roles';
const modelId = 'id';

const crudUserService = createCrudService('api/v1/admin/users');

function* getRoleList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getRoleListSuccess, 
		getRoleListFail);
}

function* getRoleDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getRoleDetailsSuccess, 
		getRoleDetailsFail);		
}

function* doRoleCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getRoleDetailsSuccess, 
		getRoleDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doRoleUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getRoleDetailsSuccess, 
		getRoleDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doRoleDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getRoleDetailsSuccess, 
		getRoleDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* getAddUserList(data) {
	yield doCrudCall(
		() => crudUserService.getList(data.payload), 
		getAddUserListSuccess, 
		getAddUserListFail);
}

function* roleSaga() {
  yield takeLatest('role/getRoleList', getRoleList);
  yield takeLatest('role/getRoleDetails', getRoleDetails);
  yield takeLatest('role/doRoleCreate', doRoleCreate);
  yield takeLatest('role/doRoleUpdate', doRoleUpdate);
  yield takeLatest('role/doRoleDelete', doRoleDelete);  
  yield takeLatest('role/getAddUserList', getAddUserList);
}

export default roleSaga;
