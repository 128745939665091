import React from "react";
import { Input } from 'antd';
import FormItem from "components/fields/FormItem";
import RegionProvinceBarangay from "components/fields/RegionProvinceBarangay";
import { connect } from "react-redux";

const ContactDetailsFields = ({recordData, mode, form, ...props}) => {
  
  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<FormItem name="section" hidden>
				<Input type="hidden"/>
			</FormItem>
			
			<div className="form-column-4">
				<FormItem 
					name="contact_mobile_number"
					label="Mobile Number"
				>
					{mode === 'edit' ?
					<Input id="contact_mobile_number"
						maxLength={15}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.contact_mobile_number}</label>
					}	                
				</FormItem>				
				<FormItem 
					name="contact_phone_number"
					label="Landline Number"
				>
					{mode === 'edit' ?
					<Input id="contact_phone_number"
						maxLength={15}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.contact_phone_number}</label>
					}	                
				</FormItem>
				<FormItem extra="col-span-2"
					name="contact_email"
					label="Email"
				>
					{mode === 'edit' ?
					<Input id="contact_email"
						maxLength={50}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.contact_email}</label>
					}	                
				</FormItem>
			</div>
			<label className="form-section-label">ADDRESS</label>
			<div className="form-column-4">				
				<FormItem extra="col-span-2"
					name="address_address_line1"
					label="House/Lot/Bldg. No./Purok"
				>
					{mode === 'edit' ?
					<Input id="address_address_line1"
						maxLength={100}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.address_address_line1}</label>
					}	                
				</FormItem>
				<FormItem 
					name="address_address_line2"
					label="Street/Sitio/Subdv."
				>
					{mode === 'edit' ?
					<Input id="address_address_line2"
						maxLength={100}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.address_address_line2}</label>
					}	                
				</FormItem>
				<div className="col-span-4">
					<RegionProvinceBarangay recordData={recordData} mode={mode} form={form}/>
				</div>
			</div>			
			<label className="form-section-label">PERSON TO NOTIFY IN CASE OF EMERGENCY</label>			
			<div className="form-column-4">
				<FormItem 
					name="emergency_first_name"
					label="First Name"
				>
					{mode === 'edit' ?
					<Input id="emergency_first_name"
						maxLength={50}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.emergency_first_name}</label>
					}	                
				</FormItem>				
				<FormItem 
					name="emergency_last_name"
					label="Last Name"
				>
					{mode === 'edit' ?
					<Input id="emergency_last_name"
						maxLength={50}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.emergency_last_name}</label>
					}	                
				</FormItem>
				<FormItem 
					name="emergency_mobile_number"
					label="Contact Number"
				>
					{mode === 'edit' ?
					<Input id="emergency_mobile_number"
						maxLength={15}		            		
					/>						
					: <label className="ps-3 dark:text-white">{recordData?.emergency_mobile_number}</label>
					}	                
				</FormItem>
			</div>
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsFields);
