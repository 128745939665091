import React from "react";
import { connect } from "react-redux";
import MyRegisteredAnimalsTable from "./components/MyRegisteredAnimalsTable";

const MyRegisteredAnimals = ({filters}) => {
	
	return (
	    <div>
	      <div className="mt-5 grid h-full grid-cols-1 gap-4">
	      	<MyRegisteredAnimalsTable/>
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
	filters: state.farmAnimal.recordList?.filters,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRegisteredAnimals);
