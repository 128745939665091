import React from "react";
import { generateCRUDRoutes } from "../../../utils/index";

import MyRegisteredAnimals from "./index";
import ViewMyRegisteredAnimal from "./view";
import EditMyRegisteredAnimal from "./edit";

const myRegisteredAnimalRoutes = [	
	generateCRUDRoutes({
		mainTitle: "My Registered Animals",
		basePath: "my-registered-animals",
		mainComponent: <MyRegisteredAnimals />,
	    layout: "/panel",	   
	    subTitle:"Registered Animal",
	    viewComponent: <ViewMyRegisteredAnimal />,
	    createComponent: <EditMyRegisteredAnimal />,
	    editComponent: <EditMyRegisteredAnimal />,
	})
]

export default myRegisteredAnimalRoutes;