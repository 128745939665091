import APIService from './apiservice';
import PSGCAPIService from './psgcapiservice';

const createCrudService = (route) => {
	const getList = async (query) => {
    	return APIService.doRequest(route, 'GET', query, null);
  	};

  	const getDetails = async (id) => {  		
    	return APIService.doRequest(`${route}/${id ?? 'create'}`, 'GET', null, null);
  	};

	const getDetailsByParentId = async (id, endpoint) => {  		
    	return APIService.doRequest(`${route}/${id}/${endpoint}`, 'GET', null, null);
  	};

  	const doCreate = async (data) => {
    	return APIService.doRequest(route, 'POST', data, null);
  	};

  	const doUpdate = async (id, data) => {
    	return APIService.doRequest(`${route}/${id}`, 'PUT', data, null);
  	};

  	const doDelete = async (id) => {
    	return APIService.doRequest(`${route}/${id}`, 'DELETE', null, null);
  	};
  	
  	const doImport = async (path, file) => {
	  	return APIService.doUploadRequest(`${route}/${path}`, file);
  	}
  	
  	const doPostRequest = async (endpoint, data) => {
	  	return APIService.doRequest(`${route}/${endpoint}`, 'POST', data, null);
  	}
  	
  	const doGetRequest = async (endpoint, data) => {
	  	return APIService.doRequest(`${route}/${endpoint}`, 'GET', data, null);
  	}

	const doPSGCGetRequest = async (endpoint, data) => {
		return PSGCAPIService.doRequest(`${route}/${endpoint}`, 'GET', data, null);
	}

  	return {
	    getList,
	    getDetails,
		getDetailsByParentId,
	    doCreate,
	    doUpdate,
	    doDelete,
	    doImport,
	    doPostRequest,
	    doGetRequest,
		doPSGCGetRequest
  	};
};

export default createCrudService;