import React from "react";
import { connect } from "react-redux";
import { MdChat, MdDashboard } from "react-icons/md";
import ServiceMenu from "./components/ServiceMenu";

const Services = () => {

  return (
    <div className="mt-8 mb-16 grid grid-cols-1 gap-5 max-w-[700px] mx-auto">
      <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Farmer Information"}
          to="/panel/my-farmer-information"
      />
      <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Register Animals"}
          to="/panel/my-registered-animals"
      />
      {/* <ServiceMenu
          icon={<MdDashboard className="h-7 w-7" />}
          title={"Service 3"}
          to="/panel/service-3"
      /> */}
      <ServiceMenu
          icon={<MdChat className="h-7 w-7" />}
          title={"Chat Support"}
          to="/panel/chat-support"
      />      
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);