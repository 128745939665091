import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import FarmProfileFields from "../../../console/farmer-information/components/FarmProfileFields";
import { 
	getMyFarmProfileDetails, doMyFarmProfileCreate, doMyFarmProfileUpdate 
} from "../../../../redux/farmprofile/farmProfileState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";

const MyFarmProfileForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, myFarmerProfile, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {
		getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
			const initialValues = {
				...restData,	            
			};
			
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		try {  
			//formData.farmer_id = myFarmerProfile.id;
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<FarmProfileFields recordData={recordData} mode={mode}/>

				<CrudButtons baseRoute="/panel/my-farmer-information" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/services"
					cancelUrl="/panel/services"
					cancelLabel="Back"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.farmProfile.details?.data,
	requesting: state.farmProfile.details?.requesting,
	success: state.farmProfile.details?.succcess,
	myFarmerProfile: state.farmProfile.details?.data,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyFarmProfileDetails(id)),
	doCreate: (data) => dispatch(doMyFarmProfileCreate(data)),
	doUpdate: (data) => dispatch(doMyFarmProfileUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmProfileForm);