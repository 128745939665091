import { takeLatest, put, call } from 'redux-saga/effects';
import { loginSuccess, loginFail, 
    logoutSuccess, logoutFail, 
    getAuthUserSuccess, getAuthUserFail,
    getAuthUserModuleSuccess, getAuthUserModuleFail,
	doCheckQRDataSuccess, doCheckQRDataFail,
	doConfirmRegistrationSuccess, doConfirmRegistrationFail
 } from './authState';
import authservice from '../../services/authservice';
import { showLoading, hideLoading } from '../layout/layoutState';
import { setCrudRoute } from '../../redux/crud/crudState';

function* loginUser(action) {
  	try {
    
    	yield put(showLoading('Authenticating...'));
    	const { username, password, type } = action.payload;
    	const authToken = yield call(authservice.login, username, password); 

    	if(authToken){	
      		yield put(loginSuccess(authToken));
			yield put({ type: 'auth/getAuthUser' });			
			yield put({ type: 'auth/getAuthUserModule' });

			console.log(type)

			if(type === 'kiosk'){
				yield put(setCrudRoute({route:'/panel/services', mode: 'list', recordId:0 }));
			}
    	}

  	} catch (error) {
    	console.log(error.message);
    	yield put(loginFail(error.message));
  	}finally{
		yield put(hideLoading());
  	}
}

function* logoutUser(action) {
	yield put(showLoading('Logging out...'));
    try {      
      	const authToken = yield call(authservice.logout); 
      	yield put(logoutSuccess(authToken));
		yield put(setCrudRoute({route:'/auth/home', mode: 'list', recordId:0 }));
  		
    } catch (error) {
      	yield put(logoutFail(error.message));
    }finally{
		yield put(hideLoading());
  	}
  }

function* getAuthUser(action) {
	yield put(showLoading());
    try {
        const user = yield call(authservice.getCurrentUser); 
        yield put(getAuthUserSuccess(user));

    } catch (error) {
        yield put(getAuthUserFail(error.message));
    }finally{
		yield put(hideLoading());
  	}
}

function* getAuthUserModule(action) {
	yield put(showLoading());
    try {
        const user = yield call(authservice.getCurrentUserModules); 
        yield put(getAuthUserModuleSuccess(user));

    } catch (error) {
        yield put(getAuthUserModuleFail(error.message));
    }finally{
		yield put(hideLoading());
  	}
}

function* doCheckQRData(action) {
		
	try {

		yield put(showLoading('Checking QR data...'));
		const userData = yield call(authservice.checkQRData, action.payload); 

		if(userData){	
			yield put(doCheckQRDataSuccess(userData));
			
			if(userData.status === 'REGISTERED'){
				yield put(setCrudRoute({route:'/auth/sign-in?type=kiosk', mode: 'list', recordId:0 }));
			}else if(userData.status === 'NORECORD'){
				yield put(setCrudRoute({route:'/auth/register', mode: 'list', recordId:0 }));
			}
		}

	} catch (error) {
		yield put(doCheckQRDataFail(error.message));
	}finally{
		yield put(hideLoading());
	}
}

function* doConfirmRegistration(action) {
	try {

		yield put(showLoading('Registering ...'));
		const userData = yield call(authservice.registerUser, action.payload); 

		if(userData){	
			yield put(doConfirmRegistrationSuccess(userData));
			
			const { username, password } = action.payload;
            if (username && password) {
                yield call(loginUser, { payload: { username, password, type:'kiosk' } });				
            }
		}

	} catch (error) {
		yield put(doConfirmRegistrationFail(error.message));
	}finally{
		yield put(hideLoading());
	}
}

function* doCancelRegistration() {
	
	yield put(setCrudRoute({route:'/auth/home', mode: 'list', recordId:0 }));
	
}
function* getUserAvatar(){
	yield put(showLoading());
    try {
        const avatarImg = yield call(authservice.getUserAvatar); 
        yield put(getAuthUserModuleSuccess(avatarImg));

    } catch (error) {
        yield put(getAuthUserModuleFail(error.message));
    }finally{
		yield put(hideLoading());
  	}
}

function* authSaga() {
  	yield takeLatest('auth/loginRequest', loginUser);
  	yield takeLatest('auth/logoutRequest', logoutUser);
  	yield takeLatest('auth/getAuthUser', getAuthUser);
  	yield takeLatest('auth/getAuthUserModule', getAuthUserModule);
	yield takeLatest('auth/doCheckQRData', doCheckQRData);
	yield takeLatest('auth/doConfirmRegistration', doConfirmRegistration);
	yield takeLatest('auth/doCancelRegistration', doCancelRegistration);
	yield takeLatest('auth/getUserAvatar', getUserAvatar);
	
}

export default authSaga;
