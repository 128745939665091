import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import { setCrudRoute } from '../redux/crud/crudState';
import { showLoading, hideLoading } from '../redux/layout/layoutState';

export const LOADING_MSG = 'Loading...';
export const SAVING_MSG = 'Saving record';
export const UPDATING_MSG = 'Updating record';
export const DELETING_MSG = 'Deleting record';

export function* doCrudCall(apiCall, successAction, failAction, successMessage='', redirectData=null, loadingMessage='', additionalAction=null) 
{
	try {
		if(loadingMessage !== ''){
			yield put(showLoading(loadingMessage));
		}
    	const response = yield call(apiCall);
    	
    	if(!response.errorMessage){
			yield put(successAction(response.data));
	    	if (successMessage) {
	      		message.success(successMessage);
	    	}
			if(additionalAction){
				yield put(additionalAction(response.data));
			}
	    	yield put(setCrudRoute(redirectData));
	    	
		}else{
			if (response.errorMessage !== null && typeof response.errorMessage !== 'object') {
			    message.error(response.errorMessage);			    
			}			
			yield put(failAction(response.errorMessage));		
		}
    	
		yield put(hideLoading());
    	
  	} catch (error) {
		message.error(`Failed to perform operation: ${error}`);
    	console.error(`Failed to perform operation:`, error);
    	yield put(failAction(error.toString()));
    	yield put(hideLoading());
  	}
}

export function* doPSGCCall(apiCall, successAction, failAction, successMessage='', redirectData=null, loadingMessage='', additionalAction=null) 
{
	try {
		if(loadingMessage !== ''){
			yield put(showLoading(loadingMessage));
		}
    	const response = yield call(apiCall);
    	
    	if(!response.errorMessage){
			yield put(successAction(response));
	    	if (successMessage) {
	      		message.success(successMessage);
	    	}
			if(additionalAction){
				yield put(additionalAction(response));
			}
	    	yield put(setCrudRoute(redirectData));
	    	
		}else{
			if (response.errorMessage !== null && typeof response.errorMessage !== 'object') {
			    message.error(response.errorMessage);			    
			}			
			yield put(failAction(response.errorMessage));		
		}
    	
		yield put(hideLoading());
    	
  	} catch (error) {
		message.error(`Failed to perform operation: ${error}`);
    	console.error(`Failed to perform operation:`, error);
    	yield put(failAction(error.toString()));
    	yield put(hideLoading());
  	}
}

export function createRecordSuccess(modelName) 
{
	return `${modelName} record has been created.`;
}

export function updateRecordSuccess(modelName) 
{
	return `${modelName} record has been updated.`;
}

export function deleteRecordSuccess(modelName) 
{
	return `${modelName} record has been deleted.`;
}

export function requiredField() 
{
    return {
        required: true,
        message: 'This field is required',
      }
}

export function emailField() 
{
    return {
        type: 'email',
        message: 'Invalid Email',
      }
}

export function validatePassword(_, value) {
	
	const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
	if (!regex.test(value)) {
		console.log('test 2');
	  return Promise.reject("Password must be of 8 characters with at least one uppercase, one lowercase, and a number");
	}

	return Promise.resolve();
};