import React, { useEffect } from "react";
import Card from "components/card";
import { Form, Input, Select, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import FormItem from "components/fields/FormItem";
import { connect } from "react-redux";
import { 
	getAccessRightDetails, doAccessRightCreate, doAccessRightUpdate 
} from "../../../../redux/accessright/accessRightState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";
import { requiredField } from '../../../../utils/crudSagaHelper';

const AccessRightForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, ...props}) => {
	
  	const { recordId, mode } = props;  	
  	const [form] = useForm();
  	
  	useEffect(() => {
        getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
	        const initialValues = {
	            ...restData,	            
	        };
			
	        form.setFieldsValue(initialValues);
        }
    }, [recordData, form]);
    
  	const onSubmit = (formData) => {	
    	try {  
			formData.can_view = formData.can_view === false ? 0 : formData.can_view;
			formData.can_edit = formData.can_edit === false ? 0 : formData.can_edit;
			formData.can_delete = formData.can_delete === false ? 0 : formData.can_delete;
			formData.can_extract = formData.can_extract === false ? 0 : formData.can_extract;
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
    	} catch (error) {
      		console.error('Save failed:', error);
    	}
  	};
  
  	return (
    	<Card extra={"w-full h-full p-4"}>
		  	<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
		  		layout="vertical"
		  		onFinish={onSubmit}
	        >   
	        	<FormItem
			        name="id"
			        hidden
			    >
			        <Input type="hidden"/>
			    </FormItem>
        		<div className="grid grid-cols-4">         		            
					<FormItem 
		            	name="role_id"
		                label="Role"
						rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Select id="role_id"
						showSearch
						allowClear={true}		           
						options={recordData?.roleList}
					/>
		            	: <label className="ps-3 dark:text-white">{recordData?.role?.name}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="module_id"
		                label="Module"
						rules={[requiredField()]}
		            >
		            	{mode === 'edit' ?
		            	<Select id="module_id"
						showSearch
						allowClear={true}		           
						options={recordData?.moduleList}
					/>
		            	: <label className="ps-3 dark:text-white">{recordData?.module?.name}</label>
		            	}	                
	            	</FormItem>
				</div>
				<div className="grid grid-cols-4"> 
					<FormItem 
		            	name="can_view"
		                label="Can View"
						valuePropName="checked"
		            >
		            	{mode === 'edit' ?
						<Switch id="can_view"
							
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}/>
		            	: <label className="ps-3 dark:text-white">{recordData?.can_view === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="can_edit"
		                label="Can Edit"
						valuePropName="checked"
		            >
		            	{mode === 'edit' ?
						<Switch id="can_edit"
							
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}/>
		            	: <label className="ps-3 dark:text-white">{recordData?.can_edit === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="can_delete"
		                label="Can Delete"
						valuePropName="checked"
		            >
		            	{mode === 'edit' ?
						<Switch id="can_delete"
							
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}/>
		            	: <label className="ps-3 dark:text-white">{recordData?.can_delete === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
					<FormItem 
		            	name="can_extract"
		                label="Can Extract"
						valuePropName="checked"
		            >
		            	{mode === 'edit' ?
						<Switch id="can_extract"
							
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}/>
		            	: <label className="ps-3 dark:text-white">{recordData?.can_extract === 1 ? 'Yes' : 'No'}</label>
		            	}	                
	            	</FormItem>
	 			</div>
	 			<CrudButtons baseRoute="/console/access-rights" mode={mode} recordId={recordId} /> 				 			
	 		</Form>	 	
	 	</Card>
  	);
};

const mapStateToProps = (state) => ({
  	recordData: state.accessRight.accessRightDetails?.data,
  	requesting: state.accessRight.accessRightDetails?.requesting,
  	success: state.accessRight.accessRightDetails?.succcess,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getAccessRightDetails(id)),
  	doCreate: (data) => dispatch(doAccessRightCreate(data)),
  	doUpdate: (data) => dispatch(doAccessRightUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessRightForm);
