import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import chatSaga from './chat/saga';
import userSaga from './user/saga';
import moduleSaga from './module/saga';
import accessRightSaga from './accessright/saga';
import parameterSaga from './parameter/saga';
import roleSaga from './role/saga';
import userRoleSaga from './userrole/saga';
import userDetailsSaga from './userdetails/saga';
import farmerInfoSaga from './farmerinfo/saga';
import farmProfileSaga from './farmprofile/saga';
import farmParcelSaga from './farmparcel/saga';
import farmAnimalSaga from './farmanimal/saga';
import psgcDataSaga from './psgcdata/saga';

function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    userSaga(),
    moduleSaga(),
    accessRightSaga(),
    parameterSaga(),
    roleSaga(),
    userRoleSaga(),
    farmerInfoSaga(),
    farmProfileSaga(),
    userDetailsSaga(),
    farmParcelSaga(),
    farmAnimalSaga(),
    psgcDataSaga(),
    // Other sagas can be included here
  ]);
}

export default rootSaga;
