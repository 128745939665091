import React, { useEffect }  from "react";
import { connect } from "react-redux";
import Card from "components/card";
import { Form } from 'antd';
import RegisterAnimalFields from "../../../console/registered-animals/components/RegisterAnimalFields";
import { 
	getMyFarmAnimalDetails, doMyFarmAnimalCreate, doMyFarmAnimalUpdate 
} from "../../../../redux/farmanimal/farmAnimalState";
import { useForm } from 'antd/lib/form/Form';
import CrudButtons from "components/button/crudbuttons";

const MyRegisteredAnimalsForm = ({getRecord, doCreate, doUpdate, recordData, requesting, success, userDetails, ...props}) => {
	const { recordId, mode } = props;  	
	const [form] = useForm();
	
	useEffect(() => {		
		getRecord(recordId);
	}, [recordId, getRecord]);
	
	useEffect(() => {
		if(recordData){
			const { ...restData } = recordData;
			const initialValues = {
				...restData
			};
			form.setFieldsValue(initialValues);
		}
	}, [recordData, form]);
	
	const onSubmit = (formData) => {	
		try {  			
			recordData.id > 0 ? doUpdate(formData): doCreate(formData); 
		} catch (error) {
			console.error('Save failed:', error);
		}
	};

	return (
		<Card extra={"w-full h-full p-4"}>
			<Form form={form} className="p-4 bg-white rounded-md dark:bg-intsamis-900"
				layout="vertical"
				onFinish={onSubmit}
			>   
				<RegisterAnimalFields recordData={recordData} mode={mode} form={form}/>

				<CrudButtons baseRoute="/panel/my-registered-animals" 
					mode={mode} 
					recordId={recordId} 
					backUrl="/panel/services"
					cancelUrl="/panel/my-registered-animals"/>
			</Form>	 	
		</Card>
    );
};

const mapStateToProps = (state) => ({
	recordData: state.farmAnimal.details?.data,
	requesting: state.farmAnimal.details?.requesting,
	success: state.farmAnimal.details?.succcess,
	userDetails: state.farmAnimal.details?.data,
});

const mapDispatchToProps = (dispatch) => ({
	getRecord: (id) => dispatch(getMyFarmAnimalDetails(id)),
	doCreate: (data) => dispatch(doMyFarmAnimalCreate(data)),
	doUpdate: (data) => dispatch(doMyFarmAnimalUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRegisteredAnimalsForm);