import React, { useState } from "react";
import { connect } from "react-redux";
import { Input, InputNumber, Select, Space, Tabs } from 'antd';
import FormItem from "components/fields/FormItem";
import SwitchWithSpecify from "components/fields/SwitchWithSpecify";
import SwitchField from "components/fields/SwitchField";
import { requiredField } from '../../../../utils/crudSagaHelper';

const { TabPane } = Tabs;

const FarmProfileFields = ({recordData, mode, ...props}) => {
	
	const [activeTab, setActiveTab] = useState(recordData?.farm_profile?.main_livelihood);

	const livelihoodChangeHanlder = (value) => {
		setActiveTab(value + '');
	}

	const switchWithSpecify = [28, 29,30, 35, 41, 46];

  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>

			<FormItem name="farmer_id" hidden>
				<Input type="hidden"/>
			</FormItem>
			
			<div className="form-column-3"> 
				<FormItem 
					name="main_livelihood"
					label="Main Livelihood"
					rules={[requiredField()]}
				>
					<Select					
						options={recordData?.mainLivelihoodOptions} 
						onChange={livelihoodChangeHanlder}
					/>
				</FormItem>  
			</div>
			
			<div className="form-column-3"> 
				<Tabs activeKey={activeTab} onChange={setActiveTab} className="col-span-2 hidden-tabs">
					<TabPane key="22">
						<label className="form-item-label block mb-2">Type of Farming Activity</label>
						<Space direction="vertical" wrap={true}> 
						{recordData?.typeOfFarmingActivityOptions?.map((item, index) =>
							<FormItem
								name={`tfaa_${item.value}`}
								extra={`label-hidden mb-0`}
							>
								{switchWithSpecify.includes(item.value) ? 
								<SwitchWithSpecify 
									mode={mode}
									id={`tfaa_${item.value}`}
									switchValue={recordData?.[`tfaa_${item.value}`]}
									switchFieldType="icon"
									specifyFieldName={`tfaa_${item.value}_specific`}
									specifyDisplayValue={recordData?.[`tfaa_${item.value}_specific`]}
									specifyPlaceholder="Please specify"
									switchLabel={`${item.label}`}
								/>
								: 
								<SwitchField id={`tfaa_${item.value}`}
									type="icon"
									label={`${item.label}`}
								/>}
							</FormItem> 
						)}								
						</Space>	                
					</TabPane>
					<TabPane key="23">
						<label className="form-item-label block mb-2">Kind of Work</label>
						<div class="grid grid-cols-2 gap-2">
						{recordData?.kindOfWorkOptions?.map((item, index) =>
							<div className={switchWithSpecify.includes(item.value) ? 'col-span-2' : ''}>
								<FormItem
									name={`kow_${item.value}`}
									extra={`label-hidden mb-0`}
								>
									{switchWithSpecify.includes(item.value) ? 
									<SwitchWithSpecify 
										mode={mode}
										id={`kow_${item.value}`}
										switchFieldType="icon"
										specifyFieldName={`kow_${item.value}_specific`}
										specifyDisplayValue={recordData?.[`kow_${item.value}_specific`]}
										specifyPlaceholder="Please specify"
										switchLabel={`${item.label}`}
									/>
									: 
									<SwitchField id={`kow_${item.value}`}
										type="icon"
										label={`${item.label}`}
									/>}
								</FormItem> 
							</div>
						)} 								
						</div>								                
					</TabPane>

					<TabPane key="24">
						<div className="pr-[50px] pb-[20px]">
							<p>The landing Conduit shall coordinate with the Bureau of Fisheries and Aquatic Resources (BFAR) in the issuance of a 
								certification that the fisherfolk borrower under PUNLA/PLEA is registered under the Municipal Registration (FishR).
							</p>
						</div>
						<label className="form-item-label block mb-2">Type of Fishing Activity</label>
						<div class="grid grid-cols-2 gap-2">
						{recordData?.typeOfFishingActivityOptions?.map((item, index) =>
							<div className={switchWithSpecify.includes(item.value) ? 'col-span-2' : ''}>
								<FormItem
									name={`tfia_${item.value}`}
									extra={`label-hidden mb-0`}
								>
									{switchWithSpecify.includes(item.value) ? 
									<SwitchWithSpecify 
										mode={mode}
										id={`tfia_${item.value}`}
										switchFieldType="icon"
										specifyFieldName={`tfia_${item.value}_specific`}
										specifyDisplayValue={recordData?.[`tfia_${item.value}_specific`]}
										specifyPlaceholder="Please specify"
										switchLabel={`${item.label}`}
									/>
									: 
									<SwitchField id={`tfia_${item.value}`}
										type="icon"
										label={`${item.label}`}
									/>}
								</FormItem> 
							</div>
						)} 								
						</div>						                
					</TabPane>

					<TabPane key="25">
						<div className="pr-[50px] pb-[20px]">
							<p>For the purpose of trainings, financial assistance, and other programs catered to the 
								youth with involvement to any agriculture activity.
							</p>
						</div>
						<label className="form-item-label block mb-2">Type of Involvement</label>
						<Space direction="vertical" wrap={true}> 								
						{recordData?.typeOfInvolvementOptions?.map((item, index) =>
							<FormItem
								name={`ti_${item.value}`}
								extra={`label-hidden mb-0`}
							>
								{switchWithSpecify.includes(item.value) ? 
								<SwitchWithSpecify 
									mode={mode}
									id="tfia_others" 
									switchFieldType="icon"
									specifyFieldName={`ti_${item.value}_specific`}
									specifyDisplayValue={recordData?.[`ti_${item.value}_specific`]}
									specifyPlaceholder="Please specify"
									switchLabel={`${item.label}`}
								/>
								: 
								<SwitchField id={`ti_${item.value}`}
									type="icon"
									label={`${item.label}`}
								/>}
							</FormItem> 
						)}
						</Space>               
					</TabPane>

				</Tabs>
				<div className="grid grid-cols-2">
					<div className="col-span-2">
						<label className="font-bold dark:text-white block">Gross Anual Income Last Year:</label>
						<FormItem
							name="ga_income_farming"
							label="Farming"
						>
							{mode === 'edit' ?
							<InputNumber id="ga_income_farming"/>
							: <label className="ps-3 dark:text-white">{recordData?.ga_income_non_farming}</label>
							}	
						</FormItem> 
						<FormItem
							name="ga_income_non_farming"
							label="Non-Farming"
						>
							{mode === 'edit' ?
							<InputNumber id="ga_income_non_farming"/>
							: <label className="ps-3 dark:text-white">{recordData?.ga_income_non_farming}</label>
							}	
						</FormItem>  
					</div>
				</div>
				
			</div>			
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmProfileFields);
