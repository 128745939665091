import { takeLatest } from 'redux-saga/effects';
import {
	getRegionsSuccess, getRegionsFail,
	getProvincesSuccess, getProvincesFail,
	getMunicipalitiesSuccess, getMunicipalitiesFail,
	getBarangaysSuccess, getBarangaysFail
} from './psgcDataState';
import createCrudService from '../../services/crudservice';
import { 
	doPSGCCall, LOADING_MSG
} from '../../utils/crudSagaHelper';

const crudService = createCrudService('https://psgc.gitlab.io/api');

function* getRegions(data) {	
	yield doPSGCCall(
		() => crudService.doPSGCGetRequest('regions.json', null), 
		getRegionsSuccess, 
		getRegionsFail,
		null, null, LOADING_MSG);
}

function* getProvinces(data) {	
	yield doPSGCCall(
		() => crudService.doPSGCGetRequest(`/regions/${data.payload}/provinces.json`, null), 
		getProvincesSuccess, 
		getProvincesFail,
		null, null, LOADING_MSG);
}

function* getMunicipalities(data) {	
	yield doPSGCCall(
		() => crudService.doPSGCGetRequest(`/provinces/${data.payload}/cities-municipalities.json`, null), 
		getMunicipalitiesSuccess, 
		getMunicipalitiesFail,
		null, null, LOADING_MSG);
}

function* getBarangays(data) {	
	yield doPSGCCall(
		() => crudService.doPSGCGetRequest(`/cities-municipalities/${data.payload}/barangays.json`, null), 
		getBarangaysSuccess, 
		getBarangaysFail,
		null, null, LOADING_MSG);
}

function* psgcDataSaga() {
  yield takeLatest('psgcData/getRegions', getRegions);
  yield takeLatest('psgcData/getProvinces', getProvinces);
  yield takeLatest('psgcData/getMunicipalities', getMunicipalities);
  yield takeLatest('psgcData/getBarangays', getBarangays);
}

export default psgcDataSaga;
