import React, { useEffect, useState }  from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";
import MyPersonalInfoForm from "./components/MyPersonalInfoForm";
import MyContactDetailsForm from "./components/MyContactDetailsForm";
import MyFarmerInfoForm from "./components/MyFarmerInfoForm";
import MyFarmProfileForm from "./components/MyFarmProfileForm";
import MyFarmParcelsTable from "../my-farm-parcels/components/MyFarmParcelsTable";

const { TabPane } = Tabs;

const MyFarmerInfo = ({user}) => {	
	
	const [activeKey, setActiveKey] = useState("personal_info");

	const handleTabChange = (key) => {
        const currentURLWithoutFragment = window.location.href.split('#')[0] + '#' + key;
        window.history.replaceState({}, document.title, currentURLWithoutFragment);
        setActiveKey(key);
    }

	useEffect(() => {
		const tabKey = window.location.hash;
        if(tabKey && tabKey.length > 1){
            setActiveKey(tabKey.substring(1));
        }
	});

	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
			<Tabs type="card" size="large" activeKey={activeKey} onChange={handleTabChange}>
				<TabPane tab="Personal Information" key="personal_info">
					<MyPersonalInfoForm recordId={user?.user_details?.id} mode="edit"/>
				</TabPane>

				<TabPane tab="Contact Details" key="contact_details">
					<MyContactDetailsForm recordId={user?.user_details?.id} mode="edit"/>
				</TabPane>

				<TabPane tab="Farmer Information" key="farmer_info">
					<MyFarmerInfoForm recordId={user?.id} 
						mode="edit"/>
				</TabPane>

				<TabPane tab="Farm Profile" key="farm_profile">
					<MyFarmProfileForm recordId={user?.id} mode="edit"/>
				</TabPane>

				<TabPane tab="Farm Parcels" key="farm_parcels">
					<MyFarmParcelsTable/>
				</TabPane>
			</Tabs>
	      		
	      </div>
	    </div>
    );
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MyFarmerInfo);