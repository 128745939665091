import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import PanelBar from "views/panel/components/PanelBar";
import PanelLogout from "views/panel/components/PanelLogout";
import PanelBanner from "components/banners/PanelBanner";
import PanelBreadcrumbs from "components/breadcrumbs/panelbreadcrumbs";
import { getRoutes } from "../../utils/index";

export default function Panel(props) {
  const { ...rest } = props;
  
  document.documentElement.dir = "ltr";
  return (
    <div className="relative flex h-full w-full">    
      <FixedPlugin />  
      <div className="flex h-full w-full bg-lightPrimary dark:!bg-intsamis-900 justify-center">
        <main className={`panel-main-wrapper`}>
          <div className="h-full">
            <div className="flex flex-col md:flex-row h-auto md:h-[100px] w-full max-w-[700px] items-center justify-between mx-auto mt-[15px]">
              <PanelBanner/>
              <PanelBar {...rest}/>              
            </div>            
            <div className="pt-2 mx-auto mb-auto h-full min-h-[60vh] p-2 md:pr-2">
              <PanelBreadcrumbs />
              <Routes>
                {getRoutes(routes, '/panel')}

                <Route
                  path="/"
                  element={<Navigate to="/panel/services" replace />}
                />
              </Routes>
            </div>
            <PanelLogout/>
            <div className="p-3"></div>
          </div>
        </main>
      </div>
    </div>
  );
}
