import React  from "react";
import { useParams } from 'react-router-dom';
import UserForm from "./components/UserForm";

const ViewUser = () => {
	const { userId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<UserForm recordId={userId} mode="view"/>
	      </div>
	    </div>
    );
};

export default ViewUser;