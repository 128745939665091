import { takeLatest } from 'redux-saga/effects';
import {
  getFarmParcelListSuccess, getFarmParcelListFail,
  getFarmParcelDetailsSuccess, getFarmParcelDetailsFail
} from './farmParcelState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/services/farm-parcels');
const redirectData = {route:'/console/farm-parcels', mode: 'list', recordId:0 };
const myCrudService = createCrudService('api/v1/services/my/farm-parcels');
const myRedirectData = {route:'/panel/my-farmer-information#farm_parcels', mode: 'list', recordId:0 };
const modelName = 'Farm Parcels';
const modelId = 'id';

function* getFarmParcelList(data) {	
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getFarmParcelListSuccess, 
		getFarmParcelListFail);
}

function* getFarmParcelDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail);		
}

function* doFarmParcelCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doFarmParcelUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doFarmParcelDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* getMyFarmParcelList(data){
	yield doCrudCall(
		() => myCrudService.getList(data.payload), 
		getFarmParcelListSuccess, 
		getFarmParcelListFail);
}

function* getMyFarmParcelDetails(data) {
	yield doCrudCall(
		() => myCrudService.getDetails(data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail);		
}

function* doMyFarmParcelCreate(data){
	yield doCrudCall(
		() => myCrudService.doCreate(data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been created.`,
		myRedirectData);
}

function* doMyFarmParcelUpdate(data){
	yield doCrudCall(
		() => myCrudService.doUpdate(data.payload[modelId], data.payload), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been updated.`,
		myRedirectData);	
}

function* doMyFarmParcelDelete(data){
	yield doCrudCall(
		() => myCrudService.doDelete(data.payload.recordId), 
		getFarmParcelDetailsSuccess, 
		getFarmParcelDetailsFail,
		`${modelName} record has been deleted.`,
		{...myRedirectData, mode: data.payload.mode});	
}

function* farmParcelSaga() {
  yield takeLatest('farmParcel/getFarmParcelList', getFarmParcelList);
  yield takeLatest('farmParcel/getFarmParcelDetails', getFarmParcelDetails);
  yield takeLatest('farmParcel/doFarmParcelCreate', doFarmParcelCreate);
  yield takeLatest('farmParcel/doFarmParcelUpdate', doFarmParcelUpdate);
  yield takeLatest('farmParcel/doFarmParcelDelete', doFarmParcelDelete);  
  yield takeLatest('farmParcel/getMyFarmParcelList', getMyFarmParcelList);  
  yield takeLatest('farmParcel/getMyFarmParcelDetails', getMyFarmParcelDetails);  
  yield takeLatest('farmParcel/doMyFarmParcelCreate', doMyFarmParcelCreate);  
  yield takeLatest('farmParcel/doMyFarmParcelUpdate', doMyFarmParcelUpdate);  
  yield takeLatest('farmParcel/doMyFarmParcelDelete', doMyFarmParcelDelete);    
}

export default farmParcelSaga;
