import React, { Children } from "react";
import Services from "views/panel/Services";
import ChatSupport from "views/panel/chat-support";
import MyFarmerInfo from "views/panel/my-farmer-info";
import myFarmParcelRoutes from "views/panel/my-farm-parcels/routes";
import myRegisteredAnimalRoutes from "views/panel/my-registered-animals/routes";
import EditMyRegisteredAnimal from "views/panel/my-registered-animals/edit";
import EditMyFarmParcel from "views/panel/my-farm-parcels/edit";

const panelRoutes = [	
	{
		name: "Services",
		layout: "/panel",
		path: "services",
		component: <Services />,
		isMainMenu:false,
	  },
	  {
		name: "Chat Support",
		layout: "/panel",
		path: "chat-support",
		component: <ChatSupport />,
		isMainMenu:false,
	  },
	  {
		name: "My Information",
		layout: "/panel",
		path: "my-farmer-information",
		component: <MyFarmerInfo />,
		isMainMenu:false,
		children: [
			...myFarmParcelRoutes,
		]
	  },	  	  
	  ...myRegisteredAnimalRoutes,		  
]

export default panelRoutes;