import React  from "react";
import { useParams } from 'react-router-dom';
import AccessRightForm from "./components/AccessRightForm";

const ViewModule = () => {
	const { accessrightId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<AccessRightForm recordId={accessrightId} mode="view"/>
	      </div>
	    </div>
    );
};

export default ViewModule;