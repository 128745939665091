import React  from "react";
import { useParams } from 'react-router-dom';
import MyRegisteredAnimalsForm from "./components/MyRegisteredAnimalsForm";

const ViewMyRegisteredAnimal = () => {
	const { registeredAnimalId } = useParams();
	return (
	    <div>
	    	<div className="mt-5 grid h-full grid-cols-1 gap-4">
	      		<MyRegisteredAnimalsForm recordId={registeredAnimalId} mode="view"/>
	      </div>
	    </div>
    );
};

export default ViewMyRegisteredAnimal;