import React from "react";
import { connect } from "react-redux";
import { Input, Select, DatePicker } from 'antd';
import FormItem from "components/fields/FormItem";
import SelectWithOthers from "components/fields/SelectWithOthers";
import { requiredField } from '../../../../utils/crudSagaHelper';
import { OTHER_RELIGION, CIVIL_STAT_MARRIED, DATE_FORMAT } from '../../../../variables/constants';

const PersonalInfoFields = ({recordData, mode, ...props}) => {
  
  	return (
    	<>
			<FormItem name="id" hidden>
				<Input type="hidden"/>
			</FormItem>
			<FormItem name="user_id" hidden>
				<Input type="hidden"/>
			</FormItem>			
			<FormItem name="section" hidden>
				<Input type="hidden"/>
			</FormItem>

			<div className="form-column-4"> 
				<FormItem 
					name="last_name"
					label="SurName"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="last_name"
						maxLength={50}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.last_name}</label>
					}	                
				</FormItem>        
				<FormItem 
					name="first_name"
					label="First Name"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="first_name"
						maxLength={50}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.first_name}</label>
					}	                
				</FormItem>
				<FormItem 
					name="middle_name"
					label="Middle Name"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="middle_name"
						maxLength={50}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.middle_name}</label>
					}	                
				</FormItem>
				<FormItem 
					name="suffix"
					label="Extension Name"					
				>
					{mode === 'edit' ?
					<Input id="suffix"
						maxLength={20}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.suffix}</label>
					}	                
				</FormItem>										            	            		            	 
				<FormItem 
					name="gender"
					label="Sex"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Select id="gender"
						allowClear={true}		           
						options={recordData?.genderOptions}
					/>
					: <label className="ps-3 dark:text-white">{recordData?.gender}</label>
					}	                
				</FormItem>
				<FormItem 
					name="date_of_birth"
					label="Date of Birth"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<DatePicker id="date_of_birth"
						format={DATE_FORMAT}
					/>
					: <label className="ps-3 dark:text-white">{recordData?.date_of_birth}</label>
					}	                
				</FormItem>
				<FormItem 
					name="place_of_birth"
					label="Place of Birth"
					rules={[requiredField()]}
				>
					{mode === 'edit' ?
					<Input id="place_of_birth"
						maxLength={100}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.place_of_birth}</label>
					}	                
				</FormItem>
				<FormItem 
					name="mothers_maiden_name"
					label="Mother's Maiden Name"
				>
					{mode === 'edit' ?
					<Input id="mothers_maiden_name"
						maxLength={100}		            		
					/>
					: <label className="ps-3 dark:text-white">{recordData?.mothers_maiden_name}</label>
					}	                
				</FormItem>
				<FormItem 
					name="highest_formal_education"
					label="Highest Formal Education"
				>
					{mode === 'edit' ?
					<Select id="highest_formal_education"
						allowClear={true}		           
						options={recordData?.highestFormalEducationOptions}
					/>					
					: <label className="ps-3 dark:text-white">{recordData?.highest_formal_education}</label>
					}	                
				</FormItem>
				<FormItem extra="mb-3"
					name="religion"
					label="Religion"
				>
					{mode === 'edit' ?					
					<SelectWithOthers 
						mode={mode}
						selectFieldName="religion" 
						selectOptions={recordData?.religionOptions} 
						selectDisplayValue={recordData?.religion}
						othersFieldName="other_religion_name"
						othersDisplayValue={recordData?.other_religion_name}
						optionOthersId={[OTHER_RELIGION]}
						direction="vertical"
						othersStyle={{maxLength: 100}}
					/>
					: <label className="ps-3 dark:text-white">{recordData?.religion}</label>
					}	                
				</FormItem>	
				<FormItem extra="mb-3"
					name="civil_status"
					label="Civil Status"
				>
					{mode === 'edit' ?
					<SelectWithOthers 
		            		mode={mode}
		            		selectFieldName="civil_status" 
		            		selectOptions={recordData?.civilStatusOptions} 
		            		selectDisplayValue={recordData?.civil_status}
  							othersFieldName="spouse"
  							othersDisplayValue={recordData?.spouse}
  							optionOthersId={[CIVIL_STAT_MARRIED]}
							direction="vertical"
							othersPlaceholder="Name of Spouse"
							othersStyle={{maxLength: 100}}
		            	/>
					: <label className="ps-3 dark:text-white">{recordData?.civil_status}</label>
					}	                
				</FormItem>							
				
				
			</div>			
	 	</>
  	);
};

const mapStateToProps = (state) => ({
  	
});

const mapDispatchToProps = (dispatch) => ({
	
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoFields);
