import { takeLatest } from 'redux-saga/effects';
import {
  getModuleListSuccess, getModuleListFail,
  getModuleDetailsSuccess, getModuleDetailsFail
} from './moduleState';
import createCrudService from '../../services/crudservice';
import { doCrudCall } from '../../utils/crudSagaHelper';

const crudService = createCrudService('api/v1/admin/modules');
const redirectData = {route:'/console/modules', mode: 'list', recordId:0 };
const modelName = 'Modules';
const modelId = 'id';

function* getModuleList(data) {
	yield doCrudCall(
		() => crudService.getList(data.payload), 
		getModuleListSuccess, 
		getModuleListFail);
}

function* getModuleDetails(data) {
	yield doCrudCall(
		() => crudService.getDetails(data.payload), 
		getModuleDetailsSuccess, 
		getModuleDetailsFail);		
}

function* doModuleCreate(data){
	yield doCrudCall(
		() => crudService.doCreate(data.payload), 
		getModuleDetailsSuccess, 
		getModuleDetailsFail,
		`${modelName} record has been created.`,
		redirectData);
}

function* doModuleUpdate(data){
	yield doCrudCall(
		() => crudService.doUpdate(data.payload[modelId], data.payload), 
		getModuleDetailsSuccess, 
		getModuleDetailsFail,
		`${modelName} record has been updated.`,
		redirectData);	
}

function* doModuleDelete(data){
	yield doCrudCall(
		() => crudService.doDelete(data.payload.recordId), 
		getModuleDetailsSuccess, 
		getModuleDetailsFail,
		`${modelName} record has been deleted.`,
		{...redirectData, mode: data.payload.mode});	
}

function* moduleSaga() {
  yield takeLatest('module/getModuleList', getModuleList);
  yield takeLatest('module/getModuleDetails', getModuleDetails);
  yield takeLatest('module/doModuleCreate', doModuleCreate);
  yield takeLatest('module/doModuleUpdate', doModuleUpdate);
  yield takeLatest('module/doModuleDelete', doModuleDelete);  
}

export default moduleSaga;
